import { EMAIL_REGEX, ONLY_ALPHABETS_REGEX, USERNAME_REGEX } from 'utils/values';

export const validateName = (rawName) => {
  const errors = [];
  const name = (typeof rawName === 'string') ? rawName.trim() : rawName;
  if (!name) {
    errors.push('Name is required');
  } else if (!ONLY_ALPHABETS_REGEX.test(name)) {
    errors.push('Name should only contain alphabets');
  } else if (name.length < 3) {
    errors.push('Name should only contain at least 3 characters');
  }
  return errors;
};

export const validateEmail = (rawEmail) => {
  const email = (typeof rawEmail === 'string') ? rawEmail.trim() : rawEmail;
  const errors = [];
  if (!email) {
    errors.push('Email is required');
  } else if (!EMAIL_REGEX.test(email)) {
    errors.push('Invalid email');
  }
  return errors;
};

export const validateUsername = (rawUsername) => {
  const username = (typeof rawUsername === 'string') ? rawUsername.trim() : rawUsername;
  const errors = [];
  if (!username) {
    errors.push('Username is required');
  } else if (!USERNAME_REGEX.test(username)) {
    errors.push('Invalid username, only letters, numbers, and @/./+/-/_ characters allowed');
  } else if (username.length < 2 || username.length > 30) {
    errors.push('Username must be between 2 and 30 characters long.');
  }
  return errors;
};

export const validateEmployeeID = (rawEmployeeId) => {
  const employeeId = (typeof rawEmployeeId === 'string') ? rawEmployeeId.trim() : rawEmployeeId;
  const errors = [];
  if (employeeId && !/^[A-Za-z0-9-_]+$/.test(employeeId)) {
    errors.push('Only alphanumeric and -_ allowed');
  }
  return errors;
};

export const validateLanguage = (rawLanguage) => {
  const language = (typeof rawLanguage === 'string') ? rawLanguage.trim() : rawLanguage;
  const errors = [];
  if (!language) {
    errors.push('Language is required');
  }
  return errors;
};
