import React from 'react';
import { ROUTES } from 'utils/values';
import { Link } from 'react-router-dom';
import CheckBox from 'common/checkbox';
import GroupCard from 'dashboard/components/groupcard/groupcard';
import {
  MainContainer,
  InfoBlock,
  DataBlock,
  ButtonsHolder,
  CardsList,
  CardContainer,
  Pagination,
  ResultsCounter,
} from './styled';

const Groups = () => (
  <>
    <MainContainer>
      <h1>coming soon ...</h1>
      <div hidden>
        <ButtonsHolder>
          <button type="submit" className="group">
            <span className="icon-Enroll icon" />
            Enroll Group
          </button>
          <Link to={ROUTES.NEW_GROUP}>
            <button type="submit">
              <span className="icon-Add icon" />
              New Group
            </button>
          </Link>
        </ButtonsHolder>
        <InfoBlock className="v2">
          <div className="search-block">
            <i className="icon-Search-2-Outline-Color" aria-hidden="true" />
            <input type="search" placeholder="Search Groups" />
          </div>
          <div className="actions-block">
            <button><span className="icon-Filter icon" /> Filters</button>
          </div>
        </InfoBlock>
        <DataBlock>
          <div className="select-info">
            <span className="info">3 selected</span>
          </div>
          <div className="results-info">
            <span className="info">Results per page</span>
            <select>
              <option>5</option>
              <option>10</option>
              <option>15</option>
              <option>20</option>
            </select>
          </div>
        </DataBlock>
        <CardsList>
          <CardContainer>
            <CheckBox checkId="check01" />
            <GroupCard
              groupName="Group name"
              membersCounter="54"
              creationDate="27th Jan 2021"
            />
          </CardContainer>
          <CardContainer>
            <CheckBox checkId="check02" />
            <GroupCard
              groupName="Group name"
              membersCounter="54"
              creationDate="27th Jan 2021"
            />
          </CardContainer>
          <CardContainer>
            <CheckBox checkId="check03" />
            <GroupCard
              groupName="Group name"
              membersCounter="54"
              creationDate="27th Jan 2021"
            />
          </CardContainer>
          <CardContainer>
            <CheckBox checkId="check03" />
            <GroupCard
              groupName="Group name"
              membersCounter="54"
              creationDate="27th Jan 2021"
            />
          </CardContainer>
          <CardContainer>
            <CheckBox checkId="check03" />
            <GroupCard
              groupName="Group name"
              membersCounter="54"
              creationDate="27th Jan 2021"
            />
          </CardContainer>
        </CardsList>
        <ResultsCounter>Showing <span>1-5</span> of <span>14</span> results</ResultsCounter>
        <Pagination>
          <ul>
            <li className="prev"><a href="/"><i className="icon-Arrow-Left" aria-hidden="true" />prev</a></li>
            <li><a href="/">1</a></li>
            <li><a href="/">2</a></li>
            <li><a href="/">3</a></li>
            <li className="next"><a href="/"><i className="icon-Arrow-Right" aria-hidden="true" />next</a></li>
          </ul>
        </Pagination>
      </div>
    </MainContainer>
  </>
);

export default Groups;
