import styled from 'styled-components';
import { theme } from 'assets/styles/theme';
import { DeviceUp } from 'assets/styles/breakpoints';

export const Styled = styled.div``;

export const MainContainer = styled.div`
  height: 100%;

  h4 {
    font-size: 24px;
    line-height: 26px;
    margin:  0 0 20px;
  }
`;

export const ButtonsHolder = styled.div`
  display: flex;
  align-items: center;
  flex-flow: row wrap;

  @media ${DeviceUp.sm} {
    justify-content: flex-end;
    padding-bottom: 10px;
  }

  @media ${DeviceUp.lg} {
    padding-bottom: 10px;
  }

  @media ${DeviceUp.xl} {
    padding-bottom: 0;
  }

  &.v2 {
    padding-bottom: 0;
    padding-top: 15px;
    margin-right: -20px;

    @media ${DeviceUp.sm} {
      margin-right: 0;
    }

    @media ${DeviceUp.sm} {
      padding-top: 0;
    }

    button {
      @media ${DeviceUp.sm} {
        margin-bottom: 0;
      }
    }
  }

  button {
    display: flex;
    margin: 0 20px 15px 0;
    white-space: nowrap;
    align-items: center;
    justify-content: center;
    min-width: 127px;

    @media ${DeviceUp.sm} {
      margin: 0 0 0 20px;
    }

    &.group {
      background: none;
      color: ${theme.secondary};
      border-color: ${theme.secondary};

      &:hover {
        background: ${theme.secondary};
        color: ${theme.bodyBg};
      }
      &.disabled {
        background: ${theme.lightGray};
        border-color: ${theme.lightGray};
        color: ${theme.bodyBg};
      }
    }

    .icon {
      margin: 0 10px 0 0;
      position: relative;
      font-size: 20px;

      &.right {
        margin: 0 0 0 8px;
      }
    }
  }
`;

export const InfoBlock = styled.div`
  margin: 0 0 20px;
  overflow: hidden;
  
  @media ${DeviceUp.sm} {
    display: flex;
    align-items: center;
  }

  @media ${DeviceUp.lg} {
    margin: 0 0 20px 40px;
  }

  &.v2 {
    display: flex;
    align-items: center;
  }

  &.v3 {
    justify-content: space-between;
  }

  &.v4 {
    display: flex;
    align-items: center;
  }

  .search-block {
    border: 2px solid ${theme.gray};
    border-radius: 4px;
    flex-grow: 1;
    height: 48px;
    display: flex;
    align-items: center;

    @media ${DeviceUp.sm} {
      flex-grow: inherit;
      width: 315px;
    }
    .icon-Search-2-Outline-Color {
      font-size: 20px;
      margin: 12px 12px 12px 17px;
    }
    input[type="search"] {
      border: 0;
      color: ${theme.textSecondary};
      font-size: ${theme.lgFontSize};
      height: 42px;
      flex-grow: 1;
      padding-right: 15px;
      position: relative;
      width: 100%;
    }
  }
  .actions-block {
    margin: 0 0 0 16px;

    @media ${DeviceUp.sm} {
      margin: 0 0 0 28px;
    }
    button {
      background: none;
      color: ${theme.text};
      border-radius: 0;
      height: auto;
      border: 0;
      padding: 0;
      font-size: 13px;

      .icon {
        font-size: 15px;
        margin:  0 5px 0 0;
        color: ${theme.secondary};
      }
      &:hover {
        color: ${theme.secondary};
      }
      &.disabled {
        background: none;
        color: ${theme.gray};
      }
    }
  }

  &.v2 {
    margin-left: 0;
    display: flex;
    align-items: center;
  }
`;

export const DataBlock = styled.div`
  margin: 0 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${DeviceUp.lg} {
    margin: 0 0 20px;
  }

  .info {
    font-weight: 600;
  }

  select {
    margin-left: 10px;
    width: 63px;
    height: 28px;
  }
`;

export const SelectAll = styled.div`
  margin:  0 10px 0 0;
  display: inline;
  @media ${DeviceUp.sm} {
    display: none;
  }
`;

export const Pagination = styled.div`
 max-width: 400px;
 margin: -12px auto 0;
 text-align: center;
 font-weight: 600;
 ul {
   li {
     display: inline-block;
     vertical-align: middle;
     margin: 0 9px;
     a {
      color: ${theme.secondary};
      &:hover {
        color: ${theme.text};
      }
     }
     &.prev,
     &.next {
       a {
        font-size: 0;
        line-height: 0;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: ${theme.secondary};
        display: block;
        color: ${theme.bodyBg};
        i {
          font-size: 16px;
          line-height: 32px;
        }
        &:hover {
          background: ${theme.text};
        }
       }
     }
   }
 }
`;

export const CardsList = styled.div`
  margin-left: 15px;
  margin-bottom: 35px;

  @media ${DeviceUp.sm} {
    margin-bottom: 0;
  }
  @media ${DeviceUp.lg} {
    margin-left: 33px;
  }

  @media ${DeviceUp.xl} {
    margin-left: 0;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  label {
    min-width: auto;
  }

  .checkbox {
    position: relative;
    width: 20px;
    margin:  0 12px 0 -15px;
    
    @media ${DeviceUp.lg} {
      margin:  0 12px 0 -30px;
    }
    @media ${DeviceUp.xl} {
      margin:  0 12px 0 -36px;
    }
  }
`;

export const ResultsCounter = styled.div`
  margin:  0 0 40px;
  font-size: 14px;
  line-height: 16px;
  color: ${theme.gray};

  @media ${DeviceUp.lg} {
    margin: 0 0 20px 40px;
  }

  span {
    color: ${theme.text};
  }
`;

export const HeaderHolder = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 2px ${theme.gray};
  padding:  0 20px;
  margin:  0 -20px 20px;

  @media ${DeviceUp.sm} {
    padding:  0 20px 16px;
    margin:  0 -20px 33px;
  }

  @media ${DeviceUp.lg} {
    padding:  0 35px 16px;
    margin:  0 -35px 33px;
  }

  @media ${DeviceUp.xl} {
    padding:  0 60px 16px 60px;
    margin:  0 -60px 33px -60px;
  }

  h3 {
    margin:  0 10px 0 0;
    font-size: 24px;

    @media ${DeviceUp.sm} {
      font-size: 28px;
    }
  }
`;

export const GroupNameLabel = styled.label`
  color: ${theme.gray};
  display: block;
  font-size: 18px;
  line-height: 22px;
  margin:  0 0 10px;
`;

export const GroupNameWrapper = styled.div`
  padding-bottom: 15px;
  max-width: 600px;

  @media ${DeviceUp.sm} {
    padding-bottom: 33px;
  }

  input[type="text"] {
    border: 2px solid ${theme.gray};
    border-radius: 4px;
    width: 100%;
    height: 48px;
    color: ${theme.primaryText};
    font-size: 16px;
    line-height: 22px;
    padding: 11px 16px;

    &::placeholder {
      color: ${theme.primaryText};
      opacity: 1;
    }
    
    &::-ms-input-placeholder {
      color: ${theme.primaryText};
    }
  }
`;

export const UsersCounter = styled.div`
  font-size: 18px;
  color: ${theme.gray};
  padding-top: 15px;
  @media ${DeviceUp.sm} {
    padding-top: 0;
  }
`;

export const TopSearchWrapper = styled.div`
  @media ${DeviceUp.sm} {
    align-items: flex-end;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`;

export const TagsListWrapper = styled.div`
  padding-bottom: 25px;

  @media ${DeviceUp.sm} {
    padding-bottom: 48px;
  }
`;

export const ExpanderButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ExpanderButton = styled.span`
  color: ${theme.primary};
  font-size: 16px;
  line-height: 18px;
  cursor: pointer;

  i {
    margin: 3px 0 0 8px;
    display: inline-block;
    vertical-align: top;
  }
`;

export const SearchTags = styled.div`
  position: relative;

  .form-control {
    padding-left: 48px;
    padding-right: 40px;
  }

  &:before {
    position: absolute;
    right: 17px;
    top: 17px;
    content: '';
    width: 8px;
    height: 8px;
    border: solid 2px ${theme.text};
    border-width: 0 0 2px 2px;
    pointer-events: none;
    transform: rotate(-45deg);
    z-index: 1;
  }

  .search-icon {
    position: absolute;
    left: 14px;
    top: 11px;
    font-size: 24px;
    z-index: 1;
  }

  &.search-users {
    width: 100%;
    @media ${DeviceUp.sm} {
      width: 305px;
    }
    
    &:before {
      display: none;
    }
  }
`;

export const SearchTagsList = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin:  0 -5px;
  padding-top: 20px;

  .tag-button {
    margin:  0 5px 10px;
    border-radius: 18px;
    background-color: ${theme.primary};
    height: 36px;
    border: 0;
    color: ${theme.bodyBg};
    display: flex;
    align-items: center;
    word-break: break-all;

    img {
      margin-left: 6px;
    }
  }
`;

export const GroupName = styled.h3`
  color: ${theme.primaryText};
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
`;

export const UserInfoWrapper = styled.div`
  border-bottom: solid 2px ${theme.gray};
  padding:  0 20px;
  margin:  0 -20px 20px;

  @media ${DeviceUp.sm} {
    padding:  0 20px;
    margin:  0 -20px 33px;
  }

  @media ${DeviceUp.lg} {
    padding:  0 35px;
    margin:  0 -35px 33px;
  }

  @media ${DeviceUp.xl} {
    padding:  0 60px;
    margin: 0 -60px 47px;
  }
`;

export const InfoRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin:  0 -15px;
`;

export const InfoCol = styled.div`
  width: 100%;
  padding:  0 15px 33px;

  &.col-6 {
    
    @media ${DeviceUp.sm} {
      width: 50%;
    }
  }
`;

export const InfoTitle = styled.div`
  font-size: 16px;
  color: ${theme.gray};
  font-weight: 600;
  margin: 0 0 4px;

  @media ${DeviceUp.sm} {
    font-size: 18px;
  }
`;

export const InfoDetail = styled.div`
  font-size: 20px;
  line-height: 26px;
  color: ${theme.text};
  font-weight: 600;

  @media ${DeviceUp.sm} {
    font-size: 24px;
    line-height: 28px;
  }

`;

export const HeadingHolder = styled.div`
  display: flex;
  padding-bottom: 33px;

  h4 {
    margin: 0;
  }

  .link {
    padding: 0;
    margin: 5px 0 -5px 22px;
    color: ${theme.secondary};

    .icon {
      margin:  3px 0 0 8px;
      display: inline-block;
      vertical-align: top;
    }

    &:hover {
      text-decoration: none;
      color: ${theme.primary};
    }
  }
`;

export const LeftColumn = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 15px;

  @media ${DeviceUp.sm} {
    padding-bottom: 0;
  }
  
`;

export const RightColumn = styled.div`
  .link {
    display: flex;
    align-items: center;
    padding: 0;
    color: ${theme.secondary};

    .icon {
      margin:  3px 8px 0 0;
      display: inline-block;
      vertical-align: top;
    }

    &:hover {
      text-decoration: none;
      color: ${theme.primary};
    }
  }
`;

export const MainContent = styled.div`
  display: block;
`;

export const EnrolCoursesWrapper = styled.div`
  border-top: solid 2px ${theme.gray};
  padding:  20px 20px;
  margin:  0 -20px 20px;

  @media ${DeviceUp.sm} {
    padding:  20px 20px;
    margin:  0 -20px 33px;
  }

  @media ${DeviceUp.lg} {
    padding:  30px 35px;
    margin:  0 -35px 33px;
  }

  @media ${DeviceUp.xl} {
    padding:  33px 60px 16px;
    margin:  0 -60px 33px;
  }

  h3 {
    font-size: 24px;
    line-height: 26px;
    margin-bottom: 20px;
  }
`;

export const NewUserHeader = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  
  @media ${DeviceUp.sm} {
    padding-bottom: 15px;
  }

 h2 {
    font-size: 28px;
    font-weight: 600;
    line-height: 1.3;
    margin: 0 10px 0 0;

    @media ${DeviceUp.sm} {
      margin: 0;
    }
  }
`;

export const SearchField = styled.div`
  position: relative;
  width: 100%;

  @media ${DeviceUp.sm} {
    width: 305px;
  }

  .form-control {
    padding-left: 48px;
  }

  .search-icon {
    position: absolute;
    left: 15px;
    top: 11px;
    font-size: 24px;
    z-index: 1;
  }
`;

export const SearchWrapper = styled.div`
  margin-bottom: 20px;

  @media ${DeviceUp.sm} {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  h3 {
    margin: 0 0 10px;

    @media ${DeviceUp.sm} {
      margin: 0;
    }
  }

  button {
    margin: 0 auto -6px 4px;
  }
`;

export const PopupBox = styled.div`
  border-radius: 16px;
  background-color: ${theme.bodyBg};
  max-width: 680px;
  padding:  30px;
  flex-grow: 1;
  position: relative;

  @media ${DeviceUp.sm} {
    padding:  40px 40px 35px;
    border-radius: 24px;
  }

  label {
    color: ${theme.lightGray};
    margin:  0 0 30px;
    display: block;
    @media ${DeviceUp.sm} {
      margin:  0 0 83px;
    }
    span {
      display: block;
      margin:  0 0 5px;
    }
  }

  h2 {
    margin:  0 0 25px;

    @media ${DeviceUp.sm} {
      margin:  0 0 60px;
    }
  }

  .close-button {
    position: absolute;
    right: 20px;
    top: 15px;
    width: auto;
    height: auto;
    padding: 0;
    border-radius: 0;
    background: none;
    opacity: 0.35;
    border: 0;

    @media ${DeviceUp.sm} {
      right: 35px;
      top: 23px;
    }
  }
`;

export const PopUpButtonsHolder = styled.div`
  display: flex;
  justify-content: flex-start;

  @media ${DeviceUp.sm} {
    justify-content: flex-end;
  }

  button {
    margin: 0 11px;
    min-width: 101px;
  }
`;
