import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from 'utils/values';
import EmptyNotificationIcon from 'assets/images/icon-emptynotification.svg';
import InformationIcon from 'assets/images/icon-information.svg';
import ContentIcon from 'assets/images/icon-content.svg';
import SettingsIcon from 'assets/images/icon-settings.svg';
import {
  EmptyNotification,
  NotificationWrapper,
  NotificationDropWrapper,
  NotificationList,
  Notification,
} from './styled';

const Notifications = () => (
  <>
    <EmptyNotification>
      <div>
        <span className="icon">
          <img src={EmptyNotificationIcon} alt="Empty" />
        </span>
        <strong>No notification yet</strong>
        Stay tuned! We will notify you when something arrives.
      </div>
    </EmptyNotification>
    <NotificationWrapper hidden>
      <NotificationDropWrapper>
        <strong>Notifications</strong>
        <Link className="view-all" to={ROUTES.ROOT}>
          See All
          <i className="fa fa-angle-right" aria-hidden="true" />
        </Link>
      </NotificationDropWrapper>
      <NotificationList>
        <Notification>
          <span className="icon">
            <img src={InformationIcon} alt="info" />
          </span>
          <div className="text-holder">
            <span className="text">
              body content will come here: Lorem ipsum, or lipsum as it is sometimes known is dummy
            </span>
            <span className="time">20 minutes ago</span>
          </div>
        </Notification>
        <Notification>
          <span className="icon">
            <img src={ContentIcon} alt="content" />
          </span>
          <div className="text-holder">
            <span className="text">
              body content will come here: Lorem ipsum, or lipsum as it is sometimes known is dummy
            </span>
            <span className="time">20 minutes ago</span>
          </div>
        </Notification>
        <Notification className="unread">
          <span className="icon">
            <img src={SettingsIcon} alt="settings" />
          </span>
          <div className="text-holder">
            <span className="text">
              body content will come here: Lorem ipsum, or lipsum as it is sometimes known is dummy
            </span>
            <span className="time">20 minutes ago</span>
          </div>
        </Notification>
      </NotificationList>
    </NotificationWrapper>
  </>
);

export default Notifications;
