import React from 'react';
import { store } from 'react-notifications-component';
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom';
import { EMPTY_LIST_PLACEHOLDER, ROUTES } from 'utils/values';

export const capitalCase = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const messageNotification = (type, title, message, duration = 5000, onScreen = true) => {
  if (typeof message === 'string') {
    store.addNotification({
      title,
      message,
      type,
      insert: 'top',
      container: 'top-right',
      animationIn: ['animated', 'fadeIn'],
      animationOut: ['animated', 'fadeOut'],
      dismiss: {
        duration,
        onScreen,
      },
    });
  }
};

export const getUserRepresentation = (user) => ((user.name && user.name !== '') ? user.name : user.username);

export const getUserNameForNav = (user, route = ROUTES.USERS) => {
  if ((!user.name || user.name === '') && user.username === '') return EMPTY_LIST_PLACEHOLDER;
  return <Link to={`${route}/${user.id}`}>{getUserRepresentation(user)}</Link>;
};

export const confirmAlertPopup = (title, message, accept, reject) => {
  confirmAlert({
    title,
    message,
    buttons: [
      {
        label: 'Yes',
        onClick: () => accept(),
      },
      {
        label: 'No',
        onClick: () => reject(),
      },
    ],
  });
};

export const getCookie = (name) => {
  if (!document.cookie) {
    return false;
  }

  const xsrfCookies = document.cookie.split(';')
    .map((c) => c.trim())
    .filter((c) => c.startsWith(`${name}=`));

  if (xsrfCookies.length === 0) {
    return false;
  }
  return decodeURIComponent(xsrfCookies[0].split('=')[1]);
};
