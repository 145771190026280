import styled from 'styled-components';
import { theme } from 'assets/styles/theme';
import { DeviceUp } from 'assets/styles/breakpoints';

export const Styled = styled.div`
  background: #ecf0f4;
  padding: 30px 0;
  width: 270px;
  position: fixed;
  left: 0;
  top: 82px;
  z-index: 1000;
  display: none;
  height: calc(100vh - 82px);
  overflow-y: auto;
  overflow-x: hidden;

  @media ${DeviceUp.lg} {
    max-width: 250px;
    height: 100%;
    flex-basis: 250px;
    min-height: 100vh;
    width: auto;
    position: static;
    z-index: auto;
    left: auto;
    top: auto;
    box-shadow: none;
    display: block;
  }

  @media ${DeviceUp.xl} {
    flex-basis: 300px;
    padding: 49px 0;
    max-width: 300px;
  }
`;

export const Links = styled.div`
  ul {
    font-size: ${theme.lgFontSize};
    text-transform: capitalize;

    li {

      a {
        color: #70787E;
        display: flex;
        align-items: center;
        padding: 15px 18px 15px 34px;
        position: relative;
        transition: linear 0.3s;
        @media ${DeviceUp.xl} {
          padding: 15px 18px 15px 40px;
        }

        i {
          margin:  0 8px 0 0;
          font-size: 24px;
        }

        &:before {
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          z-index: 1;
          content: '';
          width: 4px;
          opacity: 0;
          transition: linear 0.3s;
          background: ${theme.primary};
        }


        &:hover,
        &.active {
          background: ${theme.bodyBg};
          color: ${theme.text};
          &:before {
            opacity: 1;
          }
        }
      }
    }

    &.desktop-hidden {
      border-top: solid 1px ${theme.secondary};
      margin-left: -20px;
      margin-right: -20px;
      padding: 10px 20px;
      @media ${DeviceUp.sm} {
        display: none;
      }
    }
  }
`;
