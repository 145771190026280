import styled from 'styled-components';
import { theme } from 'assets/styles/theme';

const RadioBoxStyled = styled.label`
  position: relative;
  padding-left: 28px;

  .custom-radio {
    position: absolute;
    left: -9999px;
    top: -9999px;
    opacity: 0;
    visibility: hidden;
  }

  span {
    &:after {
      width: 20px;
      height: 20px;
      border-radius: 100%;
      content: '';
      position: absolute;
      left: 0;
      top: 1px;
      border: 2px solid ${theme.gray};
      transition: linear 0.3s;
    }

    &:before {
      width: 10px;
      height: 10px;
      border-radius: 100%;
      content: '';
      position: absolute;
      left: 5px;
      top: 6px;
      background: ${theme.primary};
      opacity: 0;
      transition: linear 0.3s;
    }
  }
  input[type="radio"]:checked ~ span{
    &:before {
      opacity: 1;
    }
    &:after {
      border-color: ${theme.primary};
    }
  }
`;

export default RadioBoxStyled;
