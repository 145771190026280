import styled from 'styled-components';
import { theme } from 'assets/styles/theme';
import { DeviceUp } from 'assets/styles/breakpoints';

export const Styled = styled.div`
  position: relative;

  .section-loader {
    position: absolute;
    height: auto;
  }

  .page-counter {
    margin-left: 0;
  }
`;

export const StyledCourseStats = styled.div`
  padding-top: 40px;
`;

export const DataBlock = styled.div`
  margin: 0 0 10px;
  overflow: hidden;
  @media ${DeviceUp.xs} {
    margin: 0 0 10px 38px;
  }


  .select-info {
    margin: 2px 0 12px;
    @media ${DeviceUp.xs} {
      float: left;
      margin: 2px 0 0;
    }
    .block {
      font-weight: 600;
      overflow: hidden;
      .info {
        display: inline-block;
        vertical-align: middle;
        margin: 0 10px 0 0;
      }
      .clear {
        display: inline-block;
        vertical-align: middle;
        margin: 0 10px 0 0;
        color: ${theme.secondary};
        cursor: pointer;
        &:hover {
          color: ${theme.text};
        }
      }
    }
  }
  .results-info {
    @media ${DeviceUp.xs} {
      float: right;
    }
    .info {
      display: inline-block;
      vertical-align: middle;
      font-weight: 600;
      margin: 0 10px 0 0;
    }
    select {
      background: ${theme.bodyBg};
      border: 1.75px solid ${theme.gray};
      border-radius: 3.5px;
      color: ${theme.textSecondary};
      outline: none;
      padding: 4px 11px 4px 5px;
      display: inline-block;
      vertical-align: middle;
      min-width: 70px;
    }
  }
`;

export const Pagination = styled.div`
 max-width: 400px;
 margin: -12px auto 0;
 text-align: center;
 font-weight: 600;
 ul {
   li {
     display: inline-block;
     vertical-align: middle;
     margin: 0 9px;
     a {
      color: ${theme.primary};
      &:hover {
        color: ${theme.text};
      }
     }
     &.prev,
     &.next {
       a {
        font-size: 0;
        line-height: 0;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: ${theme.primary};
        display: block;
        color: ${theme.bodyBg};
        i {
          font-size: 16px;
          line-height: 32px;
        }
        &:hover {
          background: ${theme.text};
        }
       }
     }
   }
 }
`;

export const AnalyticsStats = styled.div`
  .view-all {
    display: none;
  }
`;
export const TableHeader = styled.div`
  @media ${DeviceUp.xs} {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;
