import React, { useState, useEffect } from 'react';
import { analyticsService } from 'services/analytics';
import { TableBlock } from 'assets/styles/table-styles';
import { messageNotification } from 'utils/functions';
import PaginationLoader from 'components/loader/paginationLoader';
import {
  TableHeader,
  StyledCourseStats,
} from './styled';

const CourseStats = () => {
  const [inProgress, setProgress] = useState(false);
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    setProgress(true);
    analyticsService
      .fetchCoursesStats()
      .then((response) => {
        const { data } = response;
        setCourses(data);
        setProgress(false);
      })
      .catch(() => {
        messageNotification(
          'danger',
          'Error',
          'Something went wrong, Please try again later.',
        );
        setProgress(false);
      });
  }, []);

  return (
    <StyledCourseStats>
      {
        inProgress && <PaginationLoader />
      }
      <TableHeader>
        <h2>Workplace Essentials Training</h2>
      </TableHeader>
      <TableBlock className="course-table responsive">
        <table>
          <thead>
            <tr>
              <th>Course Title</th>
              <th>Assigned</th>
              <th>In Progress</th>
              <th>Completed</th>
              <th>Completion Rate</th>
            </tr>
          </thead>
          <tbody>
            {
              courses
              && courses.map((course) => (
                <tr key={course.id}>
                  <td data-title="Course Title">{course.display_name}</td>
                  <td data-title="Assigned">{course.enrolled}</td>
                  <td data-title="In Progress">{course.in_progress}</td>
                  <td data-title="Completed">{course.completed}</td>
                  <td data-title="Completion Rate">{course.completion_rate} %</td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </TableBlock>
    </StyledCourseStats>
  );
};

export default CourseStats;
