import React from 'react';
import CardWrapperStyled from './styled';

const GroupCard = (props) => {
  const { groupName, membersCounter, creationDate } = props;

  return (
    <CardWrapperStyled>
      <div className="text-wrapper">
        <strong className="group-name">{groupName}</strong>
        <span className="members-counter">{membersCounter} members</span>
        <span className="group-info">Date: {creationDate}</span>
      </div>
    </CardWrapperStyled>
  );
};

export default GroupCard;
