import React from 'react';
import CheckBox from 'common/checkbox';
import BreadCrumbs from 'components/breadcrumbs';
import CourseCard from 'dashboard/components/coursecard/coursecard';
import IconRemove from 'assets/images/icon-remove.svg';
import {
  MainContent,
  ButtonsHolder,
  NewUserHeader,
  EnrolCoursesWrapper,
  SearchField,
  SearchWrapper,
  CardsList,
  CardContainer,
  Pagination,
  SearchTags,
  SearchTagsList,
  UsersCounter,
  TopSearchWrapper,
  TagsListWrapper,
  ExpanderButtonWrapper,
  ExpanderButton,
} from './styled';

const GroupBulkEnrolling = () => (
  <MainContent>
    <h1>Groups</h1>
    <BreadCrumbs />
    <NewUserHeader>
      <h2>Enroll Groups</h2>
      <ButtonsHolder className="v2">
        <button type="submit" className="group">
          Cancel
          <span className="icon icon-Cancel right" />
        </button>
        <button type="submit">
          Enroll
          <span className="icon icon-Check right" />
        </button>
      </ButtonsHolder>
    </NewUserHeader>
    <EnrolCoursesWrapper>
      <TopSearchWrapper>
        <SearchTags className="search-users">
          <span className="search-icon icon-Search-2-Outline-Color" />
          <input className="form-control" type="text" placeholder="Search Users or Groups" />
        </SearchTags>
        <UsersCounter>24 Users selected</UsersCounter>
      </TopSearchWrapper>
      <TagsListWrapper>
        <SearchTagsList>
          <button type="submit" className="tag-button">
            Group: Compliance Group (54)
            <img src={IconRemove} alt="Remove" />
          </button>
          <button type="submit" className="tag-button">
            Group: Group Name (22)
            <img src={IconRemove} alt="Remove" />
          </button>
          <button type="submit" className="tag-button">
            Group: Group Name (13)
            <img src={IconRemove} alt="Remove" />
          </button>
          <button type="submit" className="tag-button">
            Group: Group Name (13)
            <img src={IconRemove} alt="Remove" />
          </button>
          <button type="submit" className="tag-button">
            Group: Group Name (13)
            <img src={IconRemove} alt="Remove" />
          </button>
          <button type="submit" className="tag-button">
            Group: Group Name (13)
            <img src={IconRemove} alt="Remove" />
          </button>
          <button type="submit" className="tag-button">
            Group: Group Name (13)
            <img src={IconRemove} alt="Remove" />
          </button>
          <button type="submit" className="tag-button">
            Group: Group Name (13)
            <img src={IconRemove} alt="Remove" />
          </button>
          <button type="submit" className="tag-button">
            faizan@arbisoft.com
            <img src={IconRemove} alt="Remove" />
          </button>
          <button type="submit" className="tag-button">
            faizan@arbisoft.com
            <img src={IconRemove} alt="Remove" />
          </button>
          <button type="submit" className="tag-button">
            faizan@arbisoft.com
            <img src={IconRemove} alt="Remove" />
          </button>
          <button type="submit" className="tag-button">
            faizan@arbisoft.com
            <img src={IconRemove} alt="Remove" />
          </button>
          <button type="submit" className="tag-button">
            faizan@arbisoft.com
            <img src={IconRemove} alt="Remove" />
          </button>
          <button type="submit" className="tag-button">
            faizan@arbisoft.com
            <img src={IconRemove} alt="Remove" />
          </button>
          <button type="submit" className="tag-button">
            faizan@arbisoft.com
            <img src={IconRemove} alt="Remove" />
          </button>
          <button type="submit" className="tag-button">
            faizan@arbisoft.com
            <img src={IconRemove} alt="Remove" />
          </button>
          <button type="submit" className="tag-button">
            faizan@arbisoft.com
            <img src={IconRemove} alt="Remove" />
          </button>
          <button type="submit" className="tag-button">
            faizan@arbisoft.com
            <img src={IconRemove} alt="Remove" />
          </button>
          <button type="submit" className="tag-button">
            faizan@arbisoft.com
            <img src={IconRemove} alt="Remove" />
          </button>
          <button type="submit" className="tag-button">
            faizan@arbisoft.com
            <img src={IconRemove} alt="Remove" />
          </button>
          <button type="submit" className="tag-button">
            faizan@arbisoft.com
            <img src={IconRemove} alt="Remove" />
          </button>
          <button type="submit" className="tag-button">
            faizan@arbisoft.com
            <img src={IconRemove} alt="Remove" />
          </button>
          <button type="submit" className="tag-button">
            faizan@arbisoft.com
            <img src={IconRemove} alt="Remove" />
          </button>
        </SearchTagsList>
        <ExpanderButtonWrapper>
          <ExpanderButton>show more <i className="icon-Chevron-Down" aria-hidden="true" /></ExpanderButton>
        </ExpanderButtonWrapper>
      </TagsListWrapper>
      <SearchWrapper>
        <h3>Courses</h3>
        <SearchField>
          <span className="search-icon icon-Search-2-Outline-Color" />
          <input className="form-control" type="text" placeholder="Search Courses" />
        </SearchField>
      </SearchWrapper>
      <CardsList>
        <CardContainer>
          <CheckBox checkId="check01" />
          <CourseCard
            courseName="Course Name"
            instructorName={['Ibrahim Khan']}
            startDate="27th Jan 2021"
            endDate="30th March 2021"
          />
        </CardContainer>
        <CardContainer>
          <CheckBox checkId="check02" />
          <CourseCard
            courseName="Course Name"
            instructorName={['Ibrahim Khan']}
            startDate="27th Jan 2021"
            endDate="30th March 2021"
          />
        </CardContainer>
        <CardContainer>
          <CheckBox checkId="check03" />
          <CourseCard
            courseName="Course Name"
            instructorName={['Ibrahim Khan']}
            startDate="27th Jan 2021"
            endDate="30th March 2021"
          />
        </CardContainer>
      </CardsList>
      <Pagination>
        <ul>
          <li className="prev"><a href="/"><i className="icon-Arrow-Left" aria-hidden="true" />prev</a></li>
          <li><a href="/">1</a></li>
          <li><a href="/">2</a></li>
          <li><a href="/">3</a></li>
          <li className="next"><a href="/"><i className="icon-Arrow-Right" aria-hidden="true" />next</a></li>
        </ul>
      </Pagination>
    </EnrolCoursesWrapper>
  </MainContent>
);
export default GroupBulkEnrolling;
