import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from 'utils/values';
import CheckBox from 'common/checkbox';
import BreadCrumbs from 'components/breadcrumbs';
import { TableBlock } from 'assets/styles/table-styles';
import {
  MainContainer,
  InfoBlock,
  DataBlock,
  ButtonsHolder,
  Pagination,
  ResultsCounter,
  HeaderHolder,
  GroupNameLabel,
  GroupNameWrapper,
  SelectAll,
} from './styled';

const NewGroup = () => (
  <>
    <MainContainer>
      <h1>Groups</h1>
      <BreadCrumbs />
      <HeaderHolder>
        <h3>New Group</h3>
        <ButtonsHolder className="v2">
          <button type="submit" className="group">
            Cancel
            <i className="icon-Cancel icon right" aria-hidden="true" />
          </button>
          <button type="submit">
            Next
            <i className="icon-Chevron-Right icon right" aria-hidden="true" />
          </button>
        </ButtonsHolder>
      </HeaderHolder>
      <GroupNameWrapper>
        <GroupNameLabel htmlFor="group">Group Name *</GroupNameLabel>
        <input type="text" id="group" placeholder="Compliance Group" />
      </GroupNameWrapper>
      <h4>Select Users to Add</h4>
      <InfoBlock className="v2">
        <div className="search-block">
          <i className="icon-Search-2-Outline-Color" aria-hidden="true" />
          <input type="search" placeholder="Search Groups" />
        </div>
        <div className="actions-block">
          <button><span className="icon-Filter icon" /> Filters</button>
        </div>
      </InfoBlock>
      <DataBlock>
        <div className="select-info">
          <SelectAll>
            <CheckBox checkId="check-all" />
          </SelectAll>
          <span className="info">3 selected</span>
        </div>
        <div className="results-info">
          <span className="info">Results per page</span>
          <select>
            <option>5</option>
            <option>10</option>
            <option>15</option>
            <option>20</option>
          </select>
        </div>
      </DataBlock>
      <TableBlock className="responsive">
        <table>
          <thead>
            <tr>
              <th className="col-check">
                <CheckBox checkId="check01" />
              </th>
              <th>Name<span className="sort">sort</span></th>
              <th>Email<span className="sort">sort</span></th>
              <th>Team<span className="sort">sort</span></th>
              <th>Role</th>
              <th>Active</th>
              <th>Employee ID<span className="sort">sort</span></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td data-title="Select">
                <CheckBox checkId="check02" />
              </td>
              <td data-title="Name"><Link to={ROUTES.USER_DETAILS}>Rehan Qazi</Link></td>
              <td data-title="Email"><a href="mailto:rehan@pkx.com">rehan@pkx.com</a></td>
              <td data-title="Team">Nurture</td>
              <td data-title="Role">Learner</td>
              <td data-title="Active">Active</td>
              <td data-title="Employee ID">002</td>
            </tr>
            <tr>
              <td data-title="Select">
                <CheckBox checkId="check03" />
              </td>
              <td data-title="Name"><Link to={ROUTES.USER_DETAILS}>Rehan Qazi</Link></td>
              <td data-title="Email"><a href="mailto:rehan@pkx.com">rehan@pkx.com</a></td>
              <td data-title="Team">Nurture</td>
              <td data-title="Role">Learner</td>
              <td data-title="Active">Active</td>
              <td data-title="Employee ID">002</td>
            </tr>
            <tr>
              <td data-title="Select">
                <CheckBox checkId="check04" />
              </td>
              <td data-title="Name"><Link to={ROUTES.USER_DETAILS}>Rehan Qazi</Link></td>
              <td data-title="Email"><a href="mailto:rehan@pkx.com">rehan@pkx.com</a></td>
              <td data-title="Team">Nurture</td>
              <td data-title="Role">Learner</td>
              <td data-title="Active">Active</td>
              <td data-title="Employee ID">002</td>
            </tr>
            <tr>
              <td data-title="Select">
                <CheckBox checkId="check05" />
              </td>
              <td data-title="Name"><Link to={ROUTES.USER_DETAILS}>Rehan Qazi</Link></td>
              <td data-title="Email"><a href="mailto:rehan@pkx.com">rehan@pkx.com</a></td>
              <td data-title="Team">Nurture</td>
              <td data-title="Role">Learner</td>
              <td data-title="Active">Active</td>
              <td data-title="Employee ID">002</td>
            </tr>
            <tr>
              <td data-title="Select">
                <CheckBox checkId="check06" />
              </td>
              <td data-title="Name"><Link to={ROUTES.USER_DETAILS}>Rehan Qazi</Link></td>
              <td data-title="Email"><a href="mailto:rehan@pkx.com">rehan@pkx.com</a></td>
              <td data-title="Team">Nurture</td>
              <td data-title="Role">Learner</td>
              <td data-title="Active">Active</td>
              <td data-title="Employee ID">002</td>
            </tr>
            <tr>
              <td data-title="Select">
                <CheckBox checkId="check7" />
              </td>
              <td data-title="Name"><Link to={ROUTES.USER_DETAILS}>Rehan Qazi</Link></td>
              <td data-title="Email"><a href="mailto:rehan@pkx.com">rehan@pkx.com</a></td>
              <td data-title="Team">Nurture</td>
              <td data-title="Role">Learner</td>
              <td data-title="Active">Active</td>
              <td data-title="Employee ID">002</td>
            </tr>
            <tr>
              <td data-title="Select">
                <CheckBox checkId="check8" />
              </td>
              <td data-title="Name"><Link to={ROUTES.USER_DETAILS}>Rehan Qazi</Link></td>
              <td data-title="Email"><a href="mailto:rehan@pkx.com">rehan@pkx.com</a></td>
              <td data-title="Team">Nurture</td>
              <td data-title="Role">Learner</td>
              <td data-title="Active">Active</td>
              <td data-title="Employee ID">002</td>
            </tr>
            <tr>
              <td data-title="Select">
                <CheckBox checkId="check9" />
              </td>
              <td data-title="Name"><Link to={ROUTES.USER_DETAILS}>Rehan Qazi</Link></td>
              <td data-title="Email"><a href="mailto:rehan@pkx.com">rehan@pkx.com</a></td>
              <td data-title="Team">Nurture</td>
              <td data-title="Role">Learner</td>
              <td data-title="Active">Active</td>
              <td data-title="Employee ID">002</td>
            </tr>
            <tr>
              <td data-title="Select">
                <CheckBox checkId="check10" />
              </td>
              <td data-title="Name"><Link to={ROUTES.USER_DETAILS}>Rehan Qazi</Link></td>
              <td data-title="Email"><a href="mailto:rehan@pkx.com">rehan@pkx.com</a></td>
              <td data-title="Team">Nurture</td>
              <td data-title="Role">Learner</td>
              <td data-title="Active">Active</td>
              <td data-title="Employee ID">002</td>
            </tr>
          </tbody>
        </table>
      </TableBlock>
      <ResultsCounter>Showing <span>1-10</span> of <span>40</span> results</ResultsCounter>
      <Pagination>
        <ul>
          <li className="prev"><a href="/"><i className="icon-Arrow-Left" aria-hidden="true" />prev</a></li>
          <li><a href="/">1</a></li>
          <li><a href="/">2</a></li>
          <li><a href="/">3</a></li>
          <li className="next"><a href="/"><i className="icon-Arrow-Right" aria-hidden="true" />next</a></li>
        </ul>
      </Pagination>
    </MainContainer>
  </>
);

export default NewGroup;
