import React from 'react';
import { TableBlock } from 'assets/styles/table-styles';
import {
  TableHeader,
  StyledCourseStats,
} from './styled';

const CourseAssessments = () => (
  <>
    <StyledCourseStats hidden>
      <TableHeader>
        <h2>Workplace Essentials Post-Assessments</h2>
      </TableHeader>
      <TableBlock className="course-table responsive">
        <table>
          <thead>
            <tr>
              <th>Course Title</th>
              <th>Completed</th>
              <th>Passed</th>
              <th>Average score</th>
              <th>Passing rate</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td data-title="Course Title">A</td>
              <td data-title="Completed">1</td>
              <td data-title="Passed">Total number of learners</td>
              <td data-title="Average score">Percentage</td>
              <td data-title="Passing rate">Percentage of total people</td>
            </tr>
            <tr>
              <td data-title="Course Title">A</td>
              <td data-title="Completed">1</td>
              <td data-title="Passed">Total number of learners</td>
              <td data-title="Average score">Percentage</td>
              <td data-title="Passing rate">Percentage of total people</td>
            </tr>
            <tr>
              <td data-title="Course Title">A</td>
              <td data-title="Completed">1</td>
              <td data-title="Passed">Total number of learners</td>
              <td data-title="Average score">Percentage</td>
              <td data-title="Passing rate">Percentage of total people</td>
            </tr>
            <tr>
              <td data-title="Course Title">A</td>
              <td data-title="Completed">1</td>
              <td data-title="Passed">Total number of learners</td>
              <td data-title="Average score">Percentage</td>
              <td data-title="Passing rate">Percentage of total people</td>
            </tr>
          </tbody>
        </table>
      </TableBlock>
    </StyledCourseStats>
  </>
);

export default CourseAssessments;
