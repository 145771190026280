import styled, { keyframes } from 'styled-components';
import { theme } from 'assets/styles/theme';

const loadingBouncedelay = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
`;

const Loader = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  min-height: 30px;

  .loading-dots {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    padding: 0;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;

    & > li {
      display: inline-block;
      width: 14px;
      height: 14px;
      margin: 3px;
      padding: 0;
      animation: ${loadingBouncedelay} 1.4s infinite ease-in-out both;
      border-radius: 50%;
      background-color: ${theme.primary};
      position: relative;
      overflow: hidden;
      text-indent: -9999px;

      &:first-child {
        animation-delay: -0.32s;
      }

      &:nth-child(2) {
        animation-delay: -0.16s;
      }
    }
  }
`;

export const PaginationLoaderStyle = styled(Loader)`
  position: absolute;
  height: auto;
`;

export const FullPageLoaderStyle = styled(Loader)`
  position: fixed;

  a {
    color: ${theme.primary};
  }

  .loading-box {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .loading-dots {
      position: static;
    }
  }
`;
