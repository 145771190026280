import styled from 'styled-components';
import { theme } from 'assets/styles/theme';
import { DeviceUp } from 'assets/styles/breakpoints';

export const Styled = styled.div`
  margin: 0 0 20px;
  overflow: hidden;

  @media ${DeviceUp.sm} {
    margin: 0 0 40px;
  }

  @media ${DeviceUp.xl} {
    margin: 0 0 62px;
  }
`;

export const MainHeading = styled.div`
  font-weight: 600;
  font-size: 30px;
  margin: 0 0 30px;

  @media ${DeviceUp.xl} {
    font-size: 36px;
  }
  a {
    color: ${theme.primary};
    display: inline-block;
    font-size: ${theme.lgFontSize};
    font-weight: normal;
    vertical-align: middle;
    margin: 0 0 0 20px;
    &:hover {
      color: ${theme.text};
    }
    i {
      font-size: 20px;
      padding: 0 0 0 7px;
      position: relative;
      top: 2px;
    }
  }
`;

export const StatsHolder = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const Block = styled.div`
  border-radius: 5px;
  border: ${theme.borderSecondary};
  margin: 0 0 15px;
  overflow: hidden;
  width: 100%;
  text-align: center;
  transition: linear 0.3s;

  @media ${DeviceUp.xxxs} {
    display: flex;
    text-align: left;
  }

  @media ${DeviceUp.sm} {
    width: 49%;
  }

  @media ${DeviceUp.xl} {
    width: 49.3%;
  }

  &:hover {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  }
  .image-holder {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    background: rgba(199, 243, 255, 0.4);

    @media ${DeviceUp.xxxs} {
      float: left;
      width: 138px;
      max-width: 138px;
    }
    img {
      max-width: 100%;
      width: 100%;
    }
  }
  .text-holder {
    padding: 15px;
    @media ${DeviceUp.xxxs} {
      overflow: hidden;
      padding: 19px 10px 17px 25px;
    }
    .title {
      display: block;
      font-size: 20px;
      font-weight: 600;
      line-height: 1;
      margin: 0 0 10px;

      @media ${DeviceUp.xl} {
        font-size: 24px;
      }
    }
    p {
      margin: 0 0 10px;

      @media ${DeviceUp.xs} {
        margin: 0 0 15px;
      }
      @media ${DeviceUp.xl} {
        margin: 0 0 24px;
      }
    }
    a {
      color: ${theme.secondary};
      font-size: 16px;

      i {
        position: relative;
        padding: 0 0 0 5px;
        top: 2px;
      }
      &:hover {
        color: ${theme.text};
      }
    }
  }
`;
