import usersConstants from 'users/constants';
import {
  ROLES,
  LANGUAGES,
  INITIAL_STATE_FETCH_USERS,
  INITIAL_STATE_LOGGEDIN_USER,
} from 'utils/values';

const fetchUsers = (state = INITIAL_STATE_FETCH_USERS, action) => {
  switch (action.type) {
    case usersConstants.FETCH_SUCCESS: {
      const {
        next,
        count,
        results,
        filters,
        previous,
        totalUsersCount,
      } = action;
      return {
        ...state,
        next,
        count,
        results,
        filters,
        previous,
        totalUsersCount,
        isDBUsersSelected: false,
      };
    }
    case usersConstants.USER_CHECKED: {
      const { id, status } = action;
      const userId = `user${id}`;
      return {
        ...state,
        results: {
          ...state.results,
          [userId]: {
            ...state.results[userId],
            isChecked: status,
          },
        },
      };
    }
    case usersConstants.TOGGLE_USER_STATUS: {
      const { id, status } = action;
      const userId = `user${id}`;
      return {
        ...state,
        results: {
          ...state.results,
          [userId]: {
            ...state.results[userId],
            is_active: status,
          },
        },
      };
    }
    case usersConstants.TOGGLE_USERS_STATUS: {
      const { ids, status } = action;
      const users = { ...state.results };
      const userIds = (ids === 'all') ? Object.keys(users).map((user) => users[user].id) : ids;
      userIds.forEach((id) => {
        const userId = `user${id}`;
        users[userId].is_active = status;
      });
      return {
        ...state,
        results: users,
      };
    }
    case usersConstants.USER_ALL_CHECKED: {
      const { status } = action;
      const users = Object.fromEntries(
        Object.keys(state.results).map((id) => [id, { ...state.results[id], isChecked: status }]),
      );
      return {
        ...state,
        results: users,
      };
    }
    case usersConstants.TOGGLE_DB_USERS_STATUS: {
      const { value } = action;
      return {
        ...state,
        isDBUsersSelected: value,
      };
    }
    default:
      return state;
  }
};

const fetchCourses = (state = { data: {} }, action) => {
  switch (action.type) {
    case usersConstants.FETCH_COURSES_SUCCESS:
      return { ...state, data: action.response.data };
    default:
      return state;
  }
};

const fetchUserSearchOptions = (state = { data: {} }, action) => {
  switch (action.type) {
    case usersConstants.FETCH_USER_SEARCH_OPTIONS_SUCCESS:
      return { ...state, usersOptions: (action.response.data && action.response.data.users) || [] };
    default:
      return state;
  }
};

export const fetchLoggedInUsers = (state = INITIAL_STATE_LOGGEDIN_USER, action) => {
  switch (action.type) {
    case usersConstants.FETCH_LOGGEDIN_USER: {
      const {
        id,
        role,
        name,
        username,
        isSuperuser,
        csrfToken,
        languages,
        allLanguages,
        profileImage,
      } = action;
      const languagesCodes = new Set(LANGUAGES.map((lang) => lang.code));
      localStorage.setItem('lms-csrf-token', csrfToken);
      return {
        ...state,
        id,
        role,
        name,
        username,
        roles: ROLES,
        isSuperuser,
        profileImage,
        allLanguages,
        languages: [
          ...state.languages,
          ...languages
            .filter((lang) => !languagesCodes.has(lang.code))
            .map((lang) => ({
              ...lang,
              isChecked: false,
              isApplied: false,
            })),
        ],
      };
    }
    case usersConstants.ADD_FILTER_LANGUAGE: {
      const { code, value } = action.value;
      const languages = [...state.languages];
      if (value === 'all') {
        languages.forEach((language, index) => {
          languages[index].isChecked = true;
        });
      } else {
        languages.forEach((language, index) => {
          if (language.code === code) {
            languages[index].isChecked = true;
          }
        });
      }
      return {
        ...state,
        languages,
      };
    }
    case usersConstants.REMOVE_FILTER_LANGUAGE: {
      const { code, value = 'all' } = action.value;
      const languages = [...state.languages];
      if (value === 'all') {
        languages.forEach((language, index) => {
          languages[index].isChecked = false;
        });
      } else {
        languages.forEach((language, index) => {
          if (language.code === code) {
            languages[index].isChecked = false;
          }
        });
      }
      return {
        ...state,
        languages,
      };
    }
    case usersConstants.RESTORE_FILTERS: {
      const languages = state.languages.map((language) => ({ ...language, isChecked: language.isApplied }));
      const roles = Object.fromEntries(
        Object.keys(state.roles).map((id) => [id, { ...state.roles[id], isChecked: state.roles[id].isApplied }]),
      );
      return {
        ...state,
        languages,
        roles,
      };
    }
    case usersConstants.FILTER_BACKUP: {
      const languages = state.languages.map((language) => ({ ...language, isApplied: language.isChecked }));
      const roles = Object.fromEntries(
        Object.keys(state.roles).map((id) => [id, { ...state.roles[id], isApplied: state.roles[id].isChecked }]),
      );
      return {
        ...state,
        languages,
        roles,
      };
    }
    case usersConstants.ADD_FILTER_ROLE: {
      const { value } = action;
      let roles = { ...state.roles };
      if (value === 'all') {
        roles = Object.fromEntries(
          Object.keys(roles).map((id) => [id, { ...state.roles[id], isChecked: true }]),
        );
      } else {
        roles[`role_${value}`].isChecked = true;
      }
      return {
        ...state,
        roles,
      };
    }
    case usersConstants.REMOVE_FILTER_ROLE: {
      const { value = 'all' } = action;
      let roles = { ...state.roles };
      if (value === 'all') {
        roles = Object.fromEntries(
          Object.keys(roles).map((id) => [id, { ...state.roles[id], isChecked: false }]),
        );
      } else {
        roles[`role_${value}`].isChecked = false;
      }
      return {
        ...state,
        roles,
      };
    }
    default:
      return state;
  }
};

/* eslint-disable import/prefer-default-export */
export const userReducer = {
  fetchUsers,
  fetchCourses,
  fetchLoggedInUsers,
  fetchUserSearchOptions,
};
