import React, { useState } from 'react';
import RadioBoxStyled from 'common/radiobox/styled';

const RadioBox = (props) => {
  const { radioId, radioGroup, value, dataLabel, children, isSelected, handleClick } = props;
  const [select, setSelected] = useState(isSelected || false);

  const handleOnChange = () => {
    setSelected(!select);
    if (handleClick) {
      handleClick(value);
    }
  };

  return (
    <RadioBoxStyled htmlFor={radioId} className="radio-button">
      <input
        type="radio"
        id={radioId}
        value={value}
        name={radioGroup}
        defaultChecked={isSelected}
        className="custom-radio"
        onChange={() => handleOnChange()}
      />
      {
        dataLabel && (<span className="text">{ dataLabel }&nbsp;</span>)
      }
      { children }
    </RadioBoxStyled>
  );
};

export default RadioBox;
