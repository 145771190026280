import styled from 'styled-components';
import { theme } from 'assets/styles/theme';

const CardWrapperStyled = styled.div`
  display: flex;
  flex-grow: 1;

  .text-wrapper {
    flex-grow: 1;
    border: 2px solid ${theme.gray};
    padding:  12px 22px;
  }

  .group-name {
    font-size: 18px;
    line-height: 20px;
    font-weight: 600;
    margin-bottom: 7px;
    display: block;
    color: ${theme.secondary};
  }

  .group-info {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 5px;
    display: block;
  }

  .members-counter {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 10px;
    font-weight: 600;
    display: block;
  }

`;

export default CardWrapperStyled;
