import React from 'react';

import CheckBox from 'common/checkbox';
import CourseCard from 'dashboard/components/coursecard/coursecard';
import { CardContainer, CardsList } from '../styled';

const CourseList = ({ courses, selectedIds, selectCallback }) => (
  <CardsList>
    {
      courses.map((course) => (
        <CardContainer key={course.id}>
          <CheckBox
            value={course.id}
            checkId={course.id}
            isChecked={selectedIds.indexOf(course.id) > -1}
            callback={selectCallback}
          />
          <CourseCard
            courseId={course.id}
            courseName={course.display_name}
            instructorName={course.instructor}
            imageURL={course.course_image_url}
            startDate={course.start_date}
            endDate={course.end_date}
          />
        </CardContainer>
      ))
    }
  </CardsList>
);

export default CourseList;
