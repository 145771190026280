import React, { useState } from 'react';
import ToggleBoxStyled from 'common/togglebox/styled';
import { ReactComponent as IconCheck } from 'assets/images/icon-check-form.svg';

const ToggleBox = (props) => {
  const { checkId, isChecked, callback, disabled, ...rest } = props;
  const [checked, setChecked] = useState(Boolean(isChecked));

  const handleChange = (e) => {
    setChecked(!checked);
    if (typeof (callback) === 'function') {
      callback(e);
    }
  };

  return (
    <ToggleBoxStyled htmlFor={checkId} disabled={disabled} className="switch">
      <input
        {...rest}
        id={checkId}
        type="checkbox"
        checked={Boolean(isChecked)}
        disabled={Boolean(disabled)}
        className="custom-checkbox"
        onClick={(e) => handleChange(e)}
      />
      <span className={`slider round ${disabled ? 'disabled' : ''}`}>
        <IconCheck />
        Toggle
      </span>
    </ToggleBoxStyled>
  );
};

export default ToggleBox;
