import React from 'react';
import Moment from 'react-moment';
import { LMS_BASE_URL } from 'config';
import { EMPTY_LIST_PLACEHOLDER } from 'utils/values';
import PlaceHolder from 'assets/images/card-image.png';
import CardWrapperStyled from 'dashboard/components/coursecard/styled';

const CourseCard = (props) => {
  const { courseId, courseName, instructorName, imageURL, startDate, endDate } = props;
  const courseImage = imageURL ? LMS_BASE_URL + imageURL.slice(1) : PlaceHolder;
  const instructors = instructorName.filter((name) => name);

  const getFormattedDate = (date) => {
    if (!date) return 'N/A';
    return <Moment format="Do MMM YYYY">{date}</Moment>;
  };

  const getCourseDates = () => {
    if (!startDate && !endDate) {
      return EMPTY_LIST_PLACEHOLDER;
    }
    return (
      <>
        Date: {getFormattedDate(startDate)} - {getFormattedDate(endDate)}
      </>
    );
  };

  return (
    <CardWrapperStyled>
      <div className="image-wrapper">
        <img src={courseImage} alt="Card" />
      </div>
      <div className="text-wrapper">
        <strong className="course-name">
          {courseName}
        </strong>
        <span className="course-info">
          ID: {courseId}
        </span>
        <span className="course-info">
          Instructors: {instructors.length !== 0 ? instructors.join(' | ') : EMPTY_LIST_PLACEHOLDER}
        </span>
        <span className="course-info">
          {getCourseDates()}
        </span>
      </div>
    </CardWrapperStyled>
  );
};

export default CourseCard;
