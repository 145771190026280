import React from 'react';
import { NavLink } from 'react-router-dom';
import { ROUTES } from 'utils/values';
import {
  LMS_BASE_URL,
  CMS_BASE_URL,
  ADMIN_PANEL_BASE_URL,
} from 'config';
import {
  Styled,
  Links,
} from './styled';

const SideBar = () => (
  <Styled className="side-menu">
    <Links>
      <ul>
        <li>
          <NavLink to={ROUTES.DASHBOARD} activeClassName="active">
            <i className="icon-Home-22"><span className="sr-only">Dashboard</span></i>
            <span className="text">Dashboard</span>
          </NavLink>
        </li>
        <li>
          <NavLink to={ROUTES.USERS} activeClassName="active">
            <i className="icon-Profile-Outline-Color"><span className="sr-only">Dashboard</span></i>
            <span className="text">Users</span>
          </NavLink>
        </li>
        <li>
          <NavLink to={ROUTES.GROUPS} activeClassName="active">
            <i className="icon-Team-Outline-Color"><span className="sr-only">Dashboard</span></i>
            <span className="text">Groups</span>
          </NavLink>
        </li>
        <li>
          <NavLink to={ROUTES.ANALYTICS} activeClassName="active">
            <i className="icon-Trending-Up"><span className="sr-only">Dashboard</span></i>
            <span className="text">Analytics</span>
          </NavLink>
        </li>
      </ul>
      <ul className="desktop-hidden">
        <li><a href={LMS_BASE_URL}>LMS</a></li>
        <li><a href={CMS_BASE_URL}>Studio</a></li>
        <li><a href={ADMIN_PANEL_BASE_URL}>Admin Panel</a></li>
      </ul>
    </Links>
  </Styled>
);

export default SideBar;
