import React, { useEffect, useState } from 'react';
import Stats from 'components/stats';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { analyticsActions } from 'analytics/actions';
import PaginationBlock from 'common/pagination';
import PaginationLoader from 'components/loader/paginationLoader';
import TableResultSelection from 'common/table-results-selection';
import CourseStats from 'analytics/courseStats';
import CourseAssessments from 'analytics/courseAssessments';
import {
  EMPTY_LIST_PLACEHOLDER,
  INITIAL_STATE_FETCH_LEARNERS,
  ROUTES,
} from 'utils/values';
import { getUserNameForNav } from 'utils/functions';
import { TableBlock } from 'assets/styles/table-styles';
import {
  Styled,
  DataBlock,
  AnalyticsStats,
  TableHeader,
} from './styled';

const Analytics = (props) => {
  const [inProgress, setProgress] = useState(false);
  const { dispatch, learners, count, filters } = props;

  useEffect(() => {
    setProgress(true);
    const params = INITIAL_STATE_FETCH_LEARNERS.filters;
    dispatch(analyticsActions.fetchLearners({ params, setProgress }));
  }, [dispatch]);

  const handlePageChange = (property, value) => {
    if (inProgress) return;
    const params = {
      ...filters,
      [property]: value,
    };
    setProgress(true);
    dispatch(analyticsActions.fetchLearners({ params, setProgress }));
  };

  const handlePageSize = (value) => {
    const params = {
      ...filters,
      page_size: value,
      page: 1,
    };
    setProgress(true);
    dispatch(analyticsActions.fetchLearners({ params, setProgress }));
  };

  return (
    <Styled>
      <AnalyticsStats>
        <Stats />
      </AnalyticsStats>
      <TableHeader>
        <h2>Learners</h2>
        {
          inProgress && <PaginationLoader />
        }
        <DataBlock>
          <TableResultSelection
            handleOnChange={handlePageSize}
            pageSize={INITIAL_STATE_FETCH_LEARNERS.filters.page_size}
          />
        </DataBlock>
      </TableHeader>
      <TableBlock className="course-table responsive">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Last Login</th>
              <th>Assigned Courses</th>
              <th>Incomplete Courses</th>
              <th>Completed Courses</th>
            </tr>
          </thead>
          <tbody>
            {
              learners && (
                learners.map((learner) => (
                  <tr key={learner.id}>
                    <td data-title="Name">
                      { getUserNameForNav(learner, `${ROUTES.ANALYTICS}${ROUTES.LEARNER}`) }
                    </td>
                    <td data-title="Email">
                      <a target="_blank" rel="noreferrer" href={`mailto:${learner.email}`}>{learner.email}</a>
                    </td>
                    <td data-title="Last Login">
                      {
                        learner.last_login
                          ? (
                            <Moment format="hh:mm a, Do MMM YYYY">
                              {learner.last_login}
                            </Moment>
                          ) : EMPTY_LIST_PLACEHOLDER
                      }
                    </td>
                    <td data-title="Assigned Courses">
                      {
                        learner.assigned_courses ? learner.assigned_courses : EMPTY_LIST_PLACEHOLDER
                      }
                    </td>
                    <td data-title="Incomplete Courses">
                      {
                        learner.incomplete_courses ? learner.incomplete_courses : EMPTY_LIST_PLACEHOLDER
                      }
                    </td>
                    <td data-title="Completed Courses">
                      {
                        learner.completed_courses ? learner.completed_courses : EMPTY_LIST_PLACEHOLDER
                      }
                    </td>
                  </tr>
                ))
              )
            }
          </tbody>
        </table>
      </TableBlock>
      { learners
        && Object.keys(learners).length > 0
        && (
          <PaginationBlock
            activePage={filters.page}
            itemsCountPerPage={filters.page_size}
            totalItemsCount={count}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            showPageCounter
          />
        )}
      <CourseStats />
      <CourseAssessments />
    </Styled>
  );
};

function mapStateToProps(state) {
  const { results, count, filters } = state.LEARNERSDATA;
  return {
    count,
    filters,
    learners: results,
  };
}

export default connect(mapStateToProps)(Analytics);
