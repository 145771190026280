import styled from 'styled-components';
import { theme } from 'assets/styles/theme';
import { DeviceUp } from 'assets/styles/breakpoints';

const CardWrapperStyled = styled.div`
  flex-grow: 1;
  @media ${DeviceUp.xs} {
    display: flex;
  }

  .image-wrapper {
    width: 100%;
    overflow: hidden;
    margin-bottom: -2px;

    @media ${DeviceUp.xs} {
      width: 149px;
      flex-shrink: 0;
      margin-right: -2px;
      margin-bottom: 0;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      //border: 2px solid #AAAAAA;
    }
  }

  .text-wrapper {
    flex-grow: 1;
    border: 2px solid ${theme.gray};
    padding:  12px 22px;
  }

  .course-name {
    font-size: 18px;
    line-height: 20px;
    font-weight: 600;
    margin-bottom: 7px;
    display: block;
  }

  .course-info {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 5px;
    display: block;
  }
`;

export default CardWrapperStyled;
