import React from 'react';
import Stats from 'components/stats';
import QuickLinks from 'dashboard/components/quick-links/quick-links';
import Started from 'dashboard/components/started/started';
import { Styled } from './styled';

const Dashboard = () => (
  <Styled>
    <Stats />
    <QuickLinks />
    <Started />
  </Styled>
);

export default Dashboard;
