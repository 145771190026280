const PRIMARY = '#56B7F7';
const SECONDARY = '#395B87';
const SECONDARYBORDER = '#CADBDB';

export const theme = {
  name: 'default',

  fontFamily: 'hkgrotesk, Helvetica, Arial, sans-serif',
  baseFontSize: '14px',
  lgFontSize: '16px',
  mdFontSize: '12px',
  smFontSize: '10px',
  baseLineHeight: '1.23',
  bodyBg: '#fff',
  text: '#292c2e',
  textSecondary: '#111',
  gray: '#aaa',
  lightGray: '#CACACA',
  MediumGray: '#70787E',

  primary: PRIMARY,
  secondary: SECONDARY,
  LightSecondary: 'rgba(199, 243, 255, 0.4)',

  borderPrimary: `1px solid ${PRIMARY}`,
  borderSecondary: `1px solid ${SECONDARYBORDER}`,
  primaryText: '#292C2E',
  unreadText: '#E2E6EA',
  iconBg: '#ECF0F4',
};

export default theme;
