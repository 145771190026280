import styled from 'styled-components';
import { theme } from 'assets/styles/theme';
import { DeviceUp } from 'assets/styles/breakpoints';

export const Styled = styled.div`
  position: relative;

  .section-loader {
    position: absolute;
    height: auto;
  }
`;

export const MainContainer = styled.div`
  background: #ECF0F4;
  overflow: hidden;
  height: 100%;
`;

export const MainContent = styled.div`
  background: ${theme.bodyBg};
  overflow: hidden;
  padding: 20px;
  min-height: 350px;

  @media ${DeviceUp.lg} {
    padding: 30px 35px;
  }

  @media ${DeviceUp.xl} {
    padding: 41px 89px 30px 61px;
  }
`;
