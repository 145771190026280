import styled from 'styled-components';
import { theme } from 'assets/styles/theme';

import { DeviceUp } from 'assets/styles/breakpoints';

export const DatePickerStyle = styled.div`
  margin-bottom: 20px;

  .date-label {
    display: block;
    font-size: 18px;
    margin: 0 0 9px;
  }

  .date-label,
  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    display: block;
  }

  .react-datepicker__day--selected {

    &, &:hover {
      background-color: ${theme.primary};
    }
  }

  .date-row {
    position: relative;
    max-width: 182px;
  }
`;

export const DrawerContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0, .42);
  overflow-y: auto;
  padding: 15px;
  transition: opacity .3s linear;
  z-index: 100;

  @media ${DeviceUp.sm} {
    padding: 15px 35px 10px;
  }

  &.drawer-hidden {
    opacity: 0;
    pointer-events: none;
  }

  &.drawer-shown {

    .drawer-content {
      transform: translateX(-100%);
    }
  }

  .drawer-content {
    position: fixed;
    top: 0;
    left: 100%;
    height: 100%;
    background: #fff;
    width: 90%;
    max-width: 795px;
    transition: transform .3s linear;
  }

  .drawer-header {
    border-bottom: 2px solid #aaa;
    padding: 15px;
    @media ${DeviceUp.sm} {
      padding: 55px 77px 17px 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    button {
      padding: 0 20px;

      @media ${DeviceUp.xxxs} {
        padding: 0 28px;
      }

      .icon {
        margin: 0px 0 0 10px;
        font-size: 19px;
        display: inline-block;
        vertical-align: top;
      }

      &.link {
        padding: 10px 0 0 0;
        color: ${theme.secondary};
        @media ${DeviceUp.sm} {
          padding: 0 28px;
          margin-top: 12px;
        }
      }

      + button {
        margin-left: 21px;
      }
    }

    h3 {
      font-size: 28px;
      font-weight: 700;
      margin: 0 13px 0 0;

      @media ${DeviceUp.sm} {
        margin: 3px 5px 0 0;
      }
    }

    @media ${DeviceUp.sm} {
      .btn-group {
        margin-left: auto;
      }

      .outline {
        margin-left: auto;
      }
    }
  }

  .drawer-body {
    padding: 30px 15px 0;
    overflow-y: auto;
    max-height: calc(100% - 122px);

    @media ${DeviceUp.sm} {
      padding: 34px 69px 0;
    }
  }

  .drawer-row {
    @media ${DeviceUp.xs} {
      display: flex;
      justify-content: space-between;
    }
  }

  .drawer-col {
    padding-bottom: 49px;

    @media ${DeviceUp.xs} {
      flex-basis: 34%;
      max-width: 34%;
    }

    &:first-child {
      @media ${DeviceUp.xs} {
        flex-basis: 40%;
        max-width: 40%;
      }
    }

    .checkbox-list {

      li {
        margin-bottom: 17px;

        label {
          display: block;
        }
      }
    }

    .checkbox {
      display: block;
      .text {
        padding-left: 14px;
      }
    }

    header {
      display: flex;
      padding-bottom: 26px;

      .link {
        margin-top: 5px;
        vertical-align: top;

        svg {
          transition: transform .3s ease;
        }
      }

      .list-shown {

        svg {
          transform: rotate(-180deg);
        }
      }

      h4 {
        margin: 0;
        font-size: 24px;
        line-height: 1.1;
      }

      .checkbox {

        svg {
          top: 9px;
        }

        &:before {
          top: 4px;
        }

        &:after {
          top: 6px;
        }

        .text {
          font-size: 24px;
          font-weight: 700;
        }
      }
    }
  }
`;

export const HeadingHolder = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  justify-content: flex-start;
`;

export const ButtonsHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
