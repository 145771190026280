import styled from 'styled-components';
import { theme } from 'assets/styles/theme';
import { DeviceUp } from 'assets/styles/breakpoints';

export const PageStyles = styled.div``;

export const PaginationStyles = styled.div`
  max-width: 400px;
  margin: -12px auto 0;
  text-align: center;
  font-weight: 600;

  ul {

    li {
      display: inline-block;
      vertical-align: middle;
      margin: 0 9px;

      a,
      button {
        color: ${theme.secondary};
        padding: 0;
        border: 0;
        background: none;

        &:hover {
          color: ${theme.text};
        }
      }

      &.active-item {

        a,
        button {
          color: ${theme.text};
          pointer-events: none;
        }
      }

      &.prev,
      &.next {
        a,
        button {
          font-size: 0;
          line-height: 0;
          width: 32px;
          height: 32px;
          border-radius: 50%;
          background: ${theme.secondary};
          display: block;
          color: ${theme.bodyBg};

          i {
            font-size: 16px;
            line-height: 32px;
          }

          &:hover {
            background: ${theme.text};
          }

          &:before {
            color: white;
            display: inline-block;
            font: 16px/32px "ilmx" !important;
            vertical-align: top;
            content: '\\e94c';
          }
        }

        &.next {

          a,
          button {
            &:before {
              content: "\\e94d";
            }
          }
        }

        &.disabled {

          a,
          button {
            pointer-events: none;
            background: ${theme.gray};
          }
        }
      }
    }
  }
`;

export const ResultsBlock = styled.div`
  font-weight: 600;
  color: ${theme.gray};
  margin: 0 0 28px;

  @media ${DeviceUp.sm} {
    margin: 0 0 0 38px;
  }
  span {
    color: ${theme.text};
  }
`;
