import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, Link, Redirect } from 'react-router-dom';
import Moment from 'react-moment';
import ToggleBox from 'common/togglebox';
import { usersService } from 'services/users';
import BreadCrumbs from 'components/breadcrumbs';
import PaginationLoader from 'components/loader/paginationLoader';
import { getUserRepresentation, messageNotification } from 'utils/functions';
import { EMPTY_LIST_PLACEHOLDER, ROUTES, HTTP_STATUS } from 'utils/values';
import STRINGS from 'utils/strings';
import PaginationBlock from 'common/pagination';
import { TableBlock } from 'assets/styles/table-styles';
import { usersActions } from 'users/actions';
import {
  MainContent,
  ButtonsHolder,
  NewUserHeader,
  UserInfoWrapper,
  InfoRow,
  InfoCol,
  InfoTitle,
  InfoDetail,
  AccountStatus,
  CourseDetailWrapper,
  ProgressBar,
} from './styled';

const UserDetail = (props) => {
  const { breadCrumbsPath, loggedInUserId } = props;
  const [user, setUser] = useState({});
  const [redirect, setRedirectTo] = useState(null);
  const [inProgress, setProgress] = useState(false);
  const [enrollmentInProgress, setEnrollmentProgress] = useState(false);
  const [enrollments, setEnrollments] = useState({});
  const [activeCoursePage, setActiveCoursePage] = useState(1);
  const { id } = useParams();

  const fetchUserEnrollments = (userId, page) => {
    setEnrollmentProgress(true);
    setProgress(true);
    usersService
      .fetchUserEnrollments(userId, page)
      .then((response) => {
        setEnrollments(response.data);
        setEnrollmentProgress(false);
        setProgress(false);
      })
      .catch(() => {
        messageNotification(
          'danger',
          STRINGS.DEFAULT_AJAX_ERROR_TITLE,
          STRINGS.DEFAULT_AJAX_ERROR_MESSAGE,
        );
        setProgress(false);
      });
  };

  const handleUserSuccess = (responseData) => {
    fetchUserEnrollments(id, activeCoursePage);
    setUser(responseData);
    setProgress(false);
  };

  const handleUserError = (response) => {
    if (response && response.status === HTTP_STATUS.NOT_FOUND_404) {
      setProgress(false);
      setRedirectTo(ROUTES.USERS);
    }
  };

  useEffect(() => {
    setProgress(true);
    usersActions.retrieveUserDetail({ userId: id, handleUserSuccess, handleUserError });
    // eslint-disable-next-line
  }, [id]);

  const handleUserStatus = (status) => {
    setProgress(true);
    if (!status) {
      usersService
        .activateUser([id])
        .then(() => {
          messageNotification(
            'success',
            STRINGS.DEFAULT_AJAX_SUCCESS_TITLE,
            STRINGS.USER_ACTIVATION_SUCCESS,
          );
          setUser({
            ...user,
            is_active: true,
          });
          setProgress(false);
        })
        .catch(() => {
          messageNotification(
            'danger',
            STRINGS.DEFAULT_AJAX_ERROR_TITLE,
            STRINGS.DEFAULT_AJAX_ERROR_MESSAGE,
          );
          setProgress(false);
        });
    } else {
      usersService
        .deactivateUser(id)
        .then(() => {
          messageNotification(
            'success',
            STRINGS.DEFAULT_AJAX_SUCCESS_TITLE,
            STRINGS.USER_DEACTIVATION_SUCCESS,
          );
          setUser({
            ...user,
            is_active: false,
          });
          setProgress(false);
        })
        .catch(() => {
          messageNotification(
            'danger',
            STRINGS.DEFAULT_AJAX_ERROR_TITLE,
            STRINGS.DEFAULT_AJAX_ERROR_MESSAGE,
          );
          setProgress(false);
        });
    }
  };

  const handlePagination = (page, pageNumber) => {
    if (inProgress) return;
    setActiveCoursePage(pageNumber);
    fetchUserEnrollments(id, pageNumber);
  };

  const noDataFound = !enrollmentInProgress && enrollments.count === 0;

  if (redirect) return <Redirect to={redirect} />;

  return (
    <MainContent>
      <h1>Users</h1>
      <BreadCrumbs paths={breadCrumbsPath} current={getUserRepresentation(user)} />
      <NewUserHeader>
        <h2>{getUserRepresentation(user)}</h2>
        <ButtonsHolder>
          <button type="submit" className="group" hidden>
            <i className="icon icon-Pen-2-Outline-Color" />
            Edit User
          </button>
          <Link to={`${ROUTES.USERS}/${id}${ROUTES.ENROLL}`}>
            <button type="submit">
              <i className="icon icon-Enroll" />
              Enroll User
            </button>
          </Link>
        </ButtonsHolder>
      </NewUserHeader>
      <UserInfoWrapper>
        <InfoRow>
          <InfoCol className="col-6">
            <InfoTitle>Employee ID</InfoTitle>
            <InfoDetail>{ user.employee_id ? user.employee_id : EMPTY_LIST_PLACEHOLDER }</InfoDetail>
          </InfoCol>
          <InfoCol className="col-6">
            <InfoTitle>Courses Enrolled</InfoTitle>
            <InfoDetail>{ user.course_enrolled ? user.course_enrolled : EMPTY_LIST_PLACEHOLDER }</InfoDetail>
          </InfoCol>
          <InfoCol className="col-6 order-4">
            <InfoTitle>Courses Completed</InfoTitle>
            <InfoDetail>{ user.completed_courses ? user.completed_courses : EMPTY_LIST_PLACEHOLDER }</InfoDetail>
          </InfoCol>
          <InfoCol className="col-6 order-3">
            <InfoTitle>Email</InfoTitle>
            <InfoDetail>{user.email}</InfoDetail>
          </InfoCol>
          <InfoCol className="order-5">
            <InfoTitle>Account status</InfoTitle>
            <AccountStatus>
              <ToggleBox
                checkId={`user${user.id}`}
                isChecked={user.is_active}
                disabled={loggedInUserId === user.id}
                readOnly
                dataid={id}
                callback={() => handleUserStatus(user.is_active)}
              />
              { user.is_active ? 'Active' : 'Deactive' }
            </AccountStatus>
          </InfoCol>
          <InfoCol className="order-6">
            <InfoTitle>Account Created</InfoTitle>
            <InfoDetail>
              {
                user.date_joined
                  ? (
                    <Moment format="hh:mm a, Do MMM YYYY">
                      {user.date_joined}
                    </Moment>
                  ) : EMPTY_LIST_PLACEHOLDER
              }
            </InfoDetail>
          </InfoCol>
          <InfoCol className="order-7">
            <InfoTitle>Last Login</InfoTitle>
            <InfoDetail>
              {
                user.last_login
                  ? (
                    <Moment format="hh:mm a, Do MMM YYYY">
                      {user.last_login}
                    </Moment>
                  ) : EMPTY_LIST_PLACEHOLDER
              }
            </InfoDetail>
          </InfoCol>
        </InfoRow>
      </UserInfoWrapper>
      {
        (inProgress || enrollmentInProgress) && <PaginationLoader />
      }
      <CourseDetailWrapper>
        <h3>Course Details</h3>
        <TableBlock className="course-table responsive">
          <table>
            <thead>
              <tr>
                <th style={{ width: '50%' }}>Course Title</th>
                <th>Enrollment Date</th>
                <th>Enrollment Status</th>
                <th>Completion Date</th>
                <th>Grade</th>
                <th style={{ minWidth: '170px' }}>Progress</th>
              </tr>
            </thead>
            <tbody>
              {
                (enrollments && enrollments.count > 0) && (
                  enrollments.results.map((course) => (
                    <tr key={course.course_id}>
                      <td data-title="Course Title">{course.display_name}</td>
                      <td data-title="Enrollment Date">
                        <Moment format="Do MMM YYYY">
                          {new Date(course.enrollment_date).toString()}
                        </Moment>
                      </td>
                      <td data-title="Enrollment Status">{course.enrollment_status}</td>
                      <td data-title="Completion Date">
                        {
                          course.completion_date
                            ? (
                              <Moment format="Do MMM YYYY">
                                {course.completion_date}
                              </Moment>
                            ) : EMPTY_LIST_PLACEHOLDER
                        }
                      </td>
                      <td data-title="Grade">
                        {course.grades ? course.grades : EMPTY_LIST_PLACEHOLDER }
                      </td>
                      <td data-title="Progress">
                        <ProgressBar>
                          <div className="progress">
                            <div
                              className="bar"
                              style={{ width: `${course.progress}%` }}
                            >
                              <span>{course.progress}%</span>
                            </div>
                          </div>
                        </ProgressBar>
                      </td>
                    </tr>
                  ))
                )
              }
            </tbody>
          </table>
          {
            noDataFound && (
              <div className="no-data">No results to show</div>
            )
          }
        </TableBlock>
        {
          (enrollments && enrollments.count > 0) && (
            <PaginationBlock
              activePage={activeCoursePage}
              itemsCountPerPage={3}
              totalItemsCount={enrollments.count}
              pageRangeDisplayed={5}
              onPageChange={handlePagination}
            />
          )
        }
      </CourseDetailWrapper>
    </MainContent>
  );
};

function mapStateToProps(state) {
  const { id } = state.LOGGEDINUSER;
  return { loggedInUserId: id };
}

export default connect(mapStateToProps)(UserDetail);
