import React from 'react';
import BreadCrumbs from 'components/breadcrumbs';
import IconRemove from 'assets/images/icon-remove.svg';
import {
  MainContainer,
  ButtonsHolder,
  HeaderHolder,
  GroupNameLabel,
  GroupNameWrapper,
  TopSearchWrapper,
  TagsListWrapper,
  ExpanderButtonWrapper,
  ExpanderButton,
  SearchTags,
  SearchTagsList,
  UsersCounter,
  GroupName,
} from './styled';

const NewGroupConfirmation = () => (
  <>
    <MainContainer>
      <h1>Groups</h1>
      <BreadCrumbs />
      <HeaderHolder>
        <h3>Confirmation</h3>
        <ButtonsHolder className="v2">
          <button type="submit">
            <i className="icon-Chevron-Left icon" aria-hidden="true" />
            Go Back
          </button>
          <button type="submit" className="group">
            Cancel
            <i className="icon-Block-Outline icon right" aria-hidden="true" />
          </button>
          <button type="submit">
            Next
            <i className="icon-Chevron-Right icon right" aria-hidden="true" />
          </button>
        </ButtonsHolder>
      </HeaderHolder>
      <GroupNameWrapper>
        <GroupNameLabel>Group Name *</GroupNameLabel>
        <GroupName>Compliance Group</GroupName>
      </GroupNameWrapper>
      <TopSearchWrapper>
        <SearchTags className="search-users">
          <span className="search-icon icon-Search-2-Outline-Color" />
          <input className="form-control" type="text" placeholder="Search Users or Groups" />
        </SearchTags>
        <UsersCounter>24 Users selected</UsersCounter>
      </TopSearchWrapper>
      <TagsListWrapper>
        <SearchTagsList>
          <button type="submit" className="tag-button">
            rehanqazi@arbisoft.com
            <img src={IconRemove} alt="Remove" />
          </button>
          <button type="submit" className="tag-button">
            ibrahim@arbisoft.com
            <img src={IconRemove} alt="Remove" />
          </button>
          <button type="submit" className="tag-button">
            ahmed@arbisoft.com
            <img src={IconRemove} alt="Remove" />
          </button>
          <button type="submit" className="tag-button">
            ali@edly.io
            <img src={IconRemove} alt="Remove" />
          </button>
          <button type="submit" className="tag-button">
            nauman@edly.io
            <img src={IconRemove} alt="Remove" />
          </button>
          <button type="submit" className="tag-button">
            faizan@arbisoft.com
            <img src={IconRemove} alt="Remove" />
          </button>
          <button type="submit" className="tag-button">
            faizan@arbisoft.com
            <img src={IconRemove} alt="Remove" />
          </button>
          <button type="submit" className="tag-button">
            faizan@arbisoft.com
            <img src={IconRemove} alt="Remove" />
          </button>
          <button type="submit" className="tag-button">
            faizan@arbisoft.com
            <img src={IconRemove} alt="Remove" />
          </button>
          <button type="submit" className="tag-button">
            faizan@arbisoft.com
            <img src={IconRemove} alt="Remove" />
          </button>
          <button type="submit" className="tag-button">
            faizan@arbisoft.com
            <img src={IconRemove} alt="Remove" />
          </button>
          <button type="submit" className="tag-button">
            faizan@arbisoft.com
            <img src={IconRemove} alt="Remove" />
          </button>
          <button type="submit" className="tag-button">
            faizan@arbisoft.com
            <img src={IconRemove} alt="Remove" />
          </button>
          <button type="submit" className="tag-button">
            faizan@arbisoft.com
            <img src={IconRemove} alt="Remove" />
          </button>
          <button type="submit" className="tag-button">
            faizan@arbisoft.com
            <img src={IconRemove} alt="Remove" />
          </button>
          <button type="submit" className="tag-button">
            faizan@arbisoft.com
            <img src={IconRemove} alt="Remove" />
          </button>
          <button type="submit" className="tag-button">
            faizan@arbisoft.com
            <img src={IconRemove} alt="Remove" />
          </button>
          <button type="submit" className="tag-button">
            faizan@arbisoft.com
            <img src={IconRemove} alt="Remove" />
          </button>
          <button type="submit" className="tag-button">
            faizan@arbisoft.com
            <img src={IconRemove} alt="Remove" />
          </button>
          <button type="submit" className="tag-button">
            faizan@arbisoft.com
            <img src={IconRemove} alt="Remove" />
          </button>
          <button type="submit" className="tag-button">
            faizan@arbisoft.com
            <img src={IconRemove} alt="Remove" />
          </button>
          <button type="submit" className="tag-button">
            faizan@arbisoft.com
            <img src={IconRemove} alt="Remove" />
          </button>
          <button type="submit" className="tag-button">
            faizan@arbisoft.com
            <img src={IconRemove} alt="Remove" />
          </button>
        </SearchTagsList>
        <ExpanderButtonWrapper>
          <ExpanderButton>show more<i className="icon-Chevron-Down" aria-hidden="true" /></ExpanderButton>
        </ExpanderButtonWrapper>
      </TagsListWrapper>
    </MainContainer>
  </>
);

export default NewGroupConfirmation;
