import React from 'react';
import { FullPageLoaderStyle } from 'components/loader/styled';

const FullPageLoader = (props) => (
  <FullPageLoaderStyle className="section-loader loading">
    <div className="loading-box">
      <ul className="loading-dots">
        <li>Loader</li>
        <li>Loader</li>
        <li>Loader</li>
      </ul>
      { props.children }
    </div>
  </FullPageLoaderStyle>
);

export default FullPageLoader;
