import React from 'react';
import { Link } from 'react-router-dom';
import {
  Styled,
  BreadCrumbsWrapper,
} from './styled';

const BreadCrumbs = (props) => {
  const { paths, current } = props;

  return (
    <Styled>
      <BreadCrumbsWrapper>
        {
          current && (
            <ul className="breadcrumbs">
              {
                paths && paths.map((path) => (
                  <li key={path.route}><Link to={path.route}>{path.label}</Link></li>
                ))
              }
              <li>{current}</li>
            </ul>
          )
        }
      </BreadCrumbsWrapper>
    </Styled>
  );
};

export default BreadCrumbs;
