import React, { useState } from 'react';
import CheckBoxStyled from 'common/checkbox/styled';
import { ReactComponent as IconCheck } from 'assets/images/icon-check-form.svg';

const CheckBox = (props) => {
  const { checkId, dataLabel, children, callback, value, isChecked } = props;
  const [checked, setChecked] = useState(isChecked);

  const handleChange = (e) => {
    setChecked(!checked);
    if (typeof (callback) === 'function') {
      callback(e);
    }
  };

  return (
    <CheckBoxStyled htmlFor={checkId}>
      <input
        key={checkId}
        id={checkId}
        type="checkbox"
        value={value}
        checked={isChecked}
        className="custom-checkbox"
        onChange={(e) => handleChange(e)}
      />
      <span className="checkbox">
        <IconCheck />
        {
          dataLabel && (<span className="text">{ dataLabel }&nbsp;</span>)
        }
        { children }
      </span>
    </CheckBoxStyled>
  );
};

export default CheckBox;
