import analyticsConstants from 'analytics/constants';
import { analyticsService } from 'services/analytics';
import { messageNotification } from 'utils/functions';
import STRINGS from 'utils/strings';

const fetchLearners = ({ params, setProgress }) => (dispatch) => {
  analyticsService
    .fetchLearners(params)
    .then((response) => {
      const { data } = response;
      Promise.resolve(
        dispatch({
          type: analyticsConstants.FETCH_LEARNERS,
          data,
          filters: params,
        }),
      );
      setProgress(false);
    })
    .catch(() => {
      messageNotification(
        'danger',
        STRINGS.defaultAjaxErrorTitle,
        STRINGS.defaultAjaxErrorMessage,
      );
      setProgress(false);
    });
};

/* eslint-disable import/prefer-default-export */
export const analyticsActions = {
  fetchLearners,
};
