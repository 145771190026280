import Select from 'react-select';
import { Link, withRouter } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import STRINGS from 'utils/strings';
import { ROUTES } from 'utils/values';
import { theme } from 'assets/styles/theme';
import PaginationBlock from 'common/pagination';
import BreadCrumbs from 'components/breadcrumbs';
import CourseList from 'users/enrollment/courselist';
import { messageNotification } from 'utils/functions';
import PaginationLoader from 'components/loader/paginationLoader';
import IconRemove from 'assets/images/icon-remove.svg';

import { usersActions } from 'users/actions';
import {
  MainContent,
  ButtonsHolder,
  NewUserHeader,
  EnrolCoursesWrapper,
  SearchField,
  SearchWrapper,
  SearchTags,
  SearchTagsList,
  UsersCounter,
  TopSearchWrapper,
  TagsListWrapper,
  ExpanderButtonWrapper,
  ExpanderButton,
} from '../styled';

const BulkEnrolling = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { breadCrumbsPath, dispatch, courses, usersOptions } = props;
  const { initialSelectedUsers, allDBUsers } = props.location;
  const { primary, LightSecondary } = theme;

  const userOptions = Object.keys(usersOptions || []).map((user) => (
    { value: user, label: usersOptions[user].email }
  ));
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [showMore, setShowMore] = useState(false);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const isEnrollEnabled = selectedUsers && selectedUsers.length > 0 && selectedCourses && selectedCourses.length > 0;

  const singleUserId = (initialSelectedUsers && initialSelectedUsers.length === 1) ? initialSelectedUsers[0] : null;
  const [userId, setUserId] = useState(singleUserId);
  const [params, setParams] = useState({ activePage: 1, searchText: '' });

  useEffect(() => {
    const initialUsersMap = userOptions.filter(({ value }) => ((initialSelectedUsers || []).includes(parseInt(value))));
    const initialUsers = allDBUsers ? userOptions : initialUsersMap;
    setSelectedUsers(initialUsers);
    // eslint-disable-next-line
  }, [usersOptions]);

  useEffect(() => {
    setIsLoading(true);
    const queryParam = { page: params.activePage, name: params.searchText, user_id: userId };
    dispatch(usersActions.fetchCourses(queryParam, setIsLoading));
    // eslint-disable-next-line
  }, [params, userId]);

  useEffect(() => {
    dispatch(usersActions.userSearch({}));
  }, [dispatch]);

  if (!usersOptions) {
    return (
      <MainContent>
        <PaginationLoader />
      </MainContent>
    );
  }

  const handleEnrollSuccess = (responseMsg) => {
    setIsLoading(false);
    messageNotification('success', STRINGS.DEFAULT_AJAX_SUCCESS_TITLE, responseMsg);
    props.history.push(ROUTES.USERS);
  };

  const handleEnrollError = (responseMsg) => {
    setIsLoading(false);
    messageNotification('danger', STRINGS.DEFAULT_AJAX_ERROR_TITLE, responseMsg);
  };

  const handleEnrollSubmission = () => {
    setIsLoading(true);
    const selectedUsersIDs = selectedUsers.map(({ value }) => value);
    const requestData = { user_ids: selectedUsersIDs, course_keys: selectedCourses };
    setSelectedCourses([]);
    usersActions.enrollUsers(requestData, handleEnrollSuccess, handleEnrollError);
  };

  const handleCheckboxChange = (event) => {
    const { checked, value } = event.target;
    const updatedCourses = [...selectedCourses];
    if (checked) {
      updatedCourses.push(value);
    } else if (updatedCourses.indexOf(value) > -1) {
      updatedCourses.splice(updatedCourses.indexOf(value), 1);
    }
    setSelectedCourses(updatedCourses);
  };

  const handleParamChange = (key, value) => {
    if (isLoading && key === 'activePage') return;
    if (key === 'searchText') {
      setSelectedCourses([]);
      setParams({ activePage: 1, searchText: value });
    } else if (key === 'activePage') {
      setParams({ ...params, activePage: value });
    }
  };

  const handleSelectedUserChange = (updatedUsers) => {
    setUserId(updatedUsers.length === 1 ? updatedUsers[0].value : null);
    setSelectedUsers(updatedUsers);
  };

  const removeSelectedUser = (userIdToRemove) => {
    const updatedUsers = selectedUsers.filter(({ value }) => (value !== userIdToRemove));
    handleSelectedUserChange(updatedUsers);
  };

  const renderCourses = () => {
    if (!courses.results || courses.results.length === 0) return <div className="no-data">No results to show</div>;

    return (
      <>
        <CourseList courses={courses.results} selectedIds={selectedCourses} selectCallback={handleCheckboxChange} />
        <PaginationBlock
          showPageCounter
          itemsCountPerPage={5}
          pageRangeDisplayed={5}
          activePage={params.activePage}
          totalItemsCount={courses.count}
          onPageChange={(_, pageNo) => handleParamChange('activePage', pageNo)}
        />
      </>
    );
  };

  return (
    <MainContent>
      <h1>Users</h1>
      <BreadCrumbs paths={breadCrumbsPath} current="Bulk Enrollment" />
      <NewUserHeader>
        <h2>Enroll Users</h2>
        <ButtonsHolder>
          <Link to={ROUTES.USERS}>
            <button className="group">
              Cancel
              <i className="icon right icon-Cancel" />
            </button>
          </Link>
          <button
            type="submit"
            disabled={!isEnrollEnabled}
            onClick={() => handleEnrollSubmission()}
            className={isEnrollEnabled ? '' : 'disabled'}
          >
            Enroll
            <i className="icon right icon-Check" />
          </button>
        </ButtonsHolder>
      </NewUserHeader>
      <EnrolCoursesWrapper>
        <TopSearchWrapper>
          <SearchTags className="search-users">
            <Select
              isMulti
              isSearchable
              isClearable={false}
              value={selectedUsers}
              options={userOptions}
              className="user-select"
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              classNamePrefix="user-select"
              controlShouldRenderValue={false}
              onChange={handleSelectedUserChange}
              placeholder="Search user(s) by email"
              theme={(inputTheme) => ({
                ...inputTheme,
                borderRadius: 0,
                colors: {
                  ...inputTheme.colors,
                  primary25: LightSecondary,
                  primary,
                },
              })}
            />
            <span className="search-icon icon-Search-2-Outline-Color" />
          </SearchTags>
          <UsersCounter>{selectedUsers.length} Users selected</UsersCounter>
        </TopSearchWrapper>
        {
          selectedUsers.length > 0
          && (
            <ExpanderButtonWrapper style={{ marginTop: '10px', justifyContent: 'flex-start' }}>
              <ExpanderButton onClick={() => setSelectedUsers([])}>
                clear selection
              </ExpanderButton>
            </ExpanderButtonWrapper>
          )
        }
        <TagsListWrapper>
          <SearchTagsList>
            {
              (showMore ? selectedUsers : selectedUsers.slice(0, 10)).map(({ value, label }) => (
                <button key={`user${value}`} type="submit" className="tag-button">
                  {label}
                  {/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */}
                  <img src={IconRemove} alt="Remove" onClick={() => removeSelectedUser(value)} />
                </button>
              ))
            }
          </SearchTagsList>

          {
            selectedUsers.length > 10
            && (
              <ExpanderButtonWrapper className={selectedUsers.length > 10 ? '' : 'disabled'}>
                <ExpanderButton onClick={() => setShowMore(!showMore)}>show {showMore ? 'less' : 'more'}
                  <i className={`fa fa-chevron-${showMore ? 'up' : 'down'}`} aria-hidden="true" />
                </ExpanderButton>
              </ExpanderButtonWrapper>
            )
          }
        </TagsListWrapper>

        <SearchWrapper>
          <h3>Courses</h3>
          <SearchField>
            <span className="search-icon icon-Search-2-Outline-Color" />
            <input
              type="text"
              className="form-control"
              value={params.searchText}
              placeholder="Search Courses"
              onChange={(e) => handleParamChange('searchText', e.target.value)}
            />
          </SearchField>
        </SearchWrapper>
        { isLoading ? <PaginationLoader /> : renderCourses() }
      </EnrolCoursesWrapper>
    </MainContent>
  );
};

function mapStateToProps(state) {
  const { data } = state.COURSES;
  const { usersOptions } = state.USER_SEARCH_OPTIONS;
  return { courses: data, usersOptions };
}

export default connect(mapStateToProps)(withRouter(BulkEnrolling));
