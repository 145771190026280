import { LMS_BASE_URL } from 'config';

const axios = require('axios');

const registerNewUser = (userData) => {
  const requestOptions = {
    withCredentials: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': localStorage.getItem('lms-csrf-token'),
    },
    data: userData,
    url: `${LMS_BASE_URL}adminpanel/users/`,
  };

  return axios(requestOptions);
};

const userBulkRegistration = (fileData) => {
  const data = new FormData();
  data.append('file', fileData);
  const requestOptions = {
    data,
    method: 'POST',
    withCredentials: true,
    url: `${LMS_BASE_URL}adminpanel/users/bulk-registration/`,
    headers: {
      'X-CSRFToken': localStorage.getItem('lms-csrf-token'),
    },
  };
  return axios(requestOptions);
};

const fetchUsers = (params) => {
  const filters = {
    ...params,
    languages: params.languages.toString(),
    roles: params.roles.toString(),
  };
  const requestOtions = {
    withCredentials: true,
    method: 'GET',
    ContentType: 'application/json',
    url: `${LMS_BASE_URL}adminpanel/users/`,
    params: filters,
  };

  return axios(requestOtions);
};

const searchUsers = (queryParams) => {
  const requestOptions = {
    method: 'GET',
    params: queryParams,
    withCredentials: true,
    ContentType: 'application/json',
    url: `${LMS_BASE_URL}adminpanel/users/search/`,
  };

  return axios(requestOptions);
};

const fetchCourses = (queryParams) => {
  const requestOptions = {
    withCredentials: true,
    method: 'GET',
    ContentType: 'application/json',
    url: `${LMS_BASE_URL}adminpanel/courses/`,
    params: queryParams,
  };

  return axios(requestOptions);
};

const enrollUserInCourses = (requestData) => {
  const requestOptions = {
    withCredentials: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': localStorage.getItem('lms-csrf-token'),
    },
    data: requestData,
    url: `${LMS_BASE_URL}adminpanel/users/enroll/`,
  };
  return axios(requestOptions);
};

const fetchUser = (id) => {
  const requestOtions = {
    withCredentials: true,
    method: 'GET',
    ContentType: 'application/json',
    url: `${LMS_BASE_URL}adminpanel/users/${id}/`,
  };

  return axios(requestOtions);
};

const fetchLoggedInUser = () => {
  const requestOtions = {
    withCredentials: true,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': localStorage.getItem('lms-csrf-token'),
    },
    url: `${LMS_BASE_URL}adminpanel/userinfo/`,
  };

  return axios(requestOtions);
};

const fetchUserEnrollments = (id, pageNumber) => {
  const requestOtions = {
    withCredentials: true,
    method: 'GET',
    ContentType: 'application/json',
    url: `${LMS_BASE_URL}adminpanel/user-course-enrollments/${id}/?page=${pageNumber}`,
  };

  return axios(requestOtions);
};

const deactivateUser = (id) => {
  const requestOtions = {
    withCredentials: true,
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': localStorage.getItem('lms-csrf-token'),
    },
    url: `${LMS_BASE_URL}adminpanel/users/${id}/`,
  };

  return axios(requestOtions);
};

const deactivateUsers = (ids) => {
  const requestOtions = {
    withCredentials: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': localStorage.getItem('lms-csrf-token'),
    },
    data: {
      ids,
    },
    url: `${LMS_BASE_URL}adminpanel/users/deactivate/`,
  };

  return axios(requestOtions);
};

const activateUser = (id) => {
  const requestOtions = {
    withCredentials: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': localStorage.getItem('lms-csrf-token'),
    },
    data: {
      ids: id,
    },
    url: `${LMS_BASE_URL}adminpanel/users/activate/`,
  };

  return axios(requestOtions);
};

/* eslint-disable import/prefer-default-export */
export const usersService = {
  fetchUser,
  fetchUsers,
  searchUsers,
  activateUser,
  fetchCourses,
  deactivateUser,
  deactivateUsers,
  registerNewUser,
  fetchLoggedInUser,
  enrollUserInCourses,
  userBulkRegistration,
  fetchUserEnrollments,
};
