import React from 'react';
import CheckBox from 'common/checkbox';
import BreadCrumbs from 'components/breadcrumbs';
import CourseCard from 'dashboard/components/coursecard/coursecard';
import {
  MainContent,
  ButtonsHolder,
  NewUserHeader,
  EnrolCoursesWrapper,
  SearchField,
  SearchWrapper,
  CardsList,
  CardContainer,
  Pagination,
} from './styled';

const GroupIndividualEnrolling = () => (
  <MainContent>
    <h1>Groups</h1>
    <BreadCrumbs />
    <NewUserHeader>
      <h2>Enroll Compliance Group</h2>
      <ButtonsHolder className="v2">
        <button type="submit" className="group">
          Cancel
          <i className="icon icon-Cancel right" />
        </button>
        <button type="submit">
          Enroll
          <i className="icon icon-Check right" />
        </button>
      </ButtonsHolder>
    </NewUserHeader>
    <EnrolCoursesWrapper>
      <SearchWrapper>
        <h3>Courses</h3>
        <SearchField>
          <span className="search-icon icon-Search-2-Outline-Color" />
          <input className="form-control" type="text" placeholder="Search Groups" />
        </SearchField>
      </SearchWrapper>
      <CardsList>
        <CardContainer>
          <CheckBox checkId="check01" />
          <CourseCard
            courseName="Course Name"
            instructorName={['Ibrahim Khan']}
            startDate="27th Jan 2021"
            endDate="30th March 2021"
          />
        </CardContainer>
        <CardContainer>
          <CheckBox checkId="check02" isChecked />
          <CourseCard
            courseName="Course Name"
            instructorName={['Ibrahim Khan']}
            startDate="27th Jan 2021"
            endDate="30th March 2021"
          />
        </CardContainer>
        <CardContainer>
          <CheckBox checkId="check03" />
          <CourseCard
            courseName="Course Name"
            instructorName={['Ibrahim Khan']}
            startDate="27th Jan 2021"
            endDate="30th March 2021"
          />
        </CardContainer>
        <CardContainer>
          <CheckBox checkId="check04" />
          <CourseCard
            courseName="Course Name"
            instructorName={['Ibrahim Khan']}
            startDate="27th Jan 2021"
            endDate="30th March 2021"
          />
        </CardContainer>
        <CardContainer>
          <CheckBox checkId="check05" />
          <CourseCard
            courseName="Course Name"
            instructorName={['Ibrahim Khan']}
            startDate="27th Jan 2021"
            endDate="30th March 2021"
          />
        </CardContainer>
      </CardsList>
      <Pagination>
        <ul>
          <li className="prev"><a href="/"><i className="icon-Arrow-Left" aria-hidden="true" />prev</a></li>
          <li><a href="/">1</a></li>
          <li><a href="/">2</a></li>
          <li><a href="/">3</a></li>
          <li className="next"><a href="/"><i className="icon-Arrow-Right" aria-hidden="true" />next</a></li>
        </ul>
      </Pagination>
    </EnrolCoursesWrapper>
  </MainContent>
);
export default GroupIndividualEnrolling;
