import styled from 'styled-components';
import { theme } from 'assets/styles/theme';
import { DeviceUp, DeviceDown } from 'assets/styles/breakpoints';

export const TableBlock = styled.div`
  font-weight: 600;
  position: relative;

  @media ${DeviceUp.sm} {
    margin: 0 0 15px;
  }

  table {
    border-collapse: collapse;
    width: 100%;
  }
  
  th, td {
    text-align: left;
    vertical-align: middle;
    padding: 20px 8px;
    border: 0.05em solid ${theme.gray};
  }

  th:first-child, td:first-child {
    background: none;
    padding-left: 0;
    border: 0;
  }

  .loading {
    position: absolute;
    margin-top: -40px;
    height: auto;
    z-index: 10;
  }

  li {
    position: relative;
  }

  .col-check {
    width: 38px;

    + th {
      min-width: 100px;
    }
  }

  th {
    background: #eee;
    .sort {
      float: right;
      font-size: 0;
      line-height: 0;
      position: relative;

      &:before,
      &:after {
        color: ${theme.gray};
        cursor: pointer;
        content:'\f0de';
        font-family: 'FontAwesome';
        font-size: 20px;
        line-height: 20px;
        position: absolute;
        top: 0;
        right: 2px;
      }

      &:after {
        content:'\f0dd';
        top: 1px;
      }

      &.asc {

        &:after {
          opacity: 0;
        }
      }

      &.desc {

        &:before {
          opacity: 0;
        }
      }
    }
  }

  td {

    a {
      color: ${theme.secondary};
      text-decoration: underline;
      word-break: break-all;
      &:hover {
        color: ${theme.text};
      }
    }
  }

  &.course-table {
    margin-bottom: 10px;
    @media ${DeviceUp.sm} {
      margin-bottom: 27px;
    }
    th {
      padding: 8px 14px;
      height: 54px;

      &:first-child {
        padding: 8px 14px;
        border: 0.05em solid ${theme.gray};
        background-color: #eee;
      }
    }
    td {
      height: 54px;
      &:first-child {
        padding: 20px 14px;
        border: 0.05em solid ${theme.gray};
      }
    }
  }

  &.responsive {
    @media ${DeviceDown.sm} {
      th, thead {
        display: none;
      }
      tbody, td, tr {
        display: block;
      }

      thead {
        tr {
          margin: 0;
        }
      }
      tr {
        margin-bottom: 20px;
      }

      td {
        border: 1px solid ${theme.gray};
        border-top: 0;
        display: flex;
        height: auto;
        min-height: 45px;
        padding: 10px;
        &:first-child {
          padding: 10px;
          border-top: 1px solid ${theme.gray};
        }
        &:before {
          flex-shrink: 0;
          content: attr(data-title);
          display: flex;
          align-items: center;
          background-color: #eee;
          margin: -10px 10px -10px -10px;
          width: 160px;
          padding: 10px;
        }
      }
    }
    @media ${DeviceDown.xxxs} {
      td {
        &:before {
          width: 50%;
        }
      }
    }
  }
`;

export default TableBlock;
