import styled from 'styled-components';
import { theme } from 'assets/styles/theme';
import { DeviceUp, DeviceDown } from 'assets/styles/breakpoints';

export const Styled = styled.div``;

export const MainContainer = styled.div`
  background: #ECF0F4;
  height: 100%;
  display: flex;
  flex-flow: nowrap;
`;

export const ContentWrapper = styled.div`
  background: ${theme.bodyBg};
  padding: 20px;
  flex-grow: 1;

  @media ${DeviceUp.lg} {
    padding: 30px 35px;
  }

  @media ${DeviceUp.lg} {
    min-height: 350px;
    max-width: calc(100% - 250px);
  }

  @media ${DeviceUp.xl} {
    padding: 41px 60px 30px;
    max-width: calc(100% - 300px);
  }

  h1 {
    margin: 0 0 20px;
  }
`;

export const MainContent = styled.div`
  display: block;
  position: relative;
`;

export const ButtonsHolder = styled.div`
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin-right: -20px;

  @media ${DeviceUp.sm} {
    justify-content: flex-end;
    margin-left: -20px;
    margin-right: 0;
  }  

  button {
    display: flex;
    margin: 0 20px 15px 0;
    white-space: nowrap;
    align-items: center;
    justify-content: center;
    vertical-align: top;
    min-width: 127px;

    @media ${DeviceUp.sm} {
      margin: 0 0 0 20px;
    }



    &.group {
      background: none;
      color: ${theme.secondary};
      border-color: ${theme.secondary};
      color: ${theme.secondary};

      &:hover {
        background: ${theme.secondary};
        color: ${theme.bodyBg};
      }
    }

    .icon, i {
      margin: 0 12px 0 0;
      position: relative;
      font-size: 21px;

      &.right {
        margin: 0 0 0 12px;
      }
    }

    &.disabled {
      background: ${theme.lightGray};
      border-color: ${theme.lightGray};
      color: ${theme.bodyBg};
      pointer-events: none;
    }
  }

  &.v2 {
    padding-bottom: 0;
    margin-right: -20px;

    @media ${DeviceUp.sm} {
      margin-right: 0;
    }

    @media ${DeviceUp.sm} {
      padding-top: 0;
    }

    button {
      @media ${DeviceUp.sm} {
        margin-bottom: 0;
      }
    }
  }

  .file-button {
      background: ${theme.primary};
      border-radius: 4px;
      height: 48px;
      text-align: center;
      color: ${theme.bodyBg};
      padding: 0 18px;
      border: 1px solid transparent;
      font-size: ${theme.lgFontSize};
      transition: linear 0.3s;
      white-space: nowrap;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 20px 15px 0;
      cursor: pointer;

      @media ${DeviceUp.sm} {
        margin: 0 0 0 20px;
      }

      &:hover {
        border-color: ${theme.primary};
        color: ${theme.primary};
        background: ${theme.bodyBg};
        cursor: pointer;
      }

      input {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        opacity: 0;
        width: 100%;
        cursor: pointer;
        z-index: 1;
        font-size: 0;
        line-height: 0;
      }

      i {
        cursor: pointer;
      }
    }

`;

export const InfoBlock = styled.div`
  margin: 0 0 10px;

  @media ${DeviceUp.sm} {
    margin: 0 0 10px 38px;
  }

  &.v2 {
    display: flex;
    align-items: center;
  }
  &:after {
    content: '';
    display: block;
    clear: both;
  }

  .search-block {
    height: 48px;
    position: relative;

    @media ${DeviceUp.xs} {
      width: 250px;
      float: left;
    }

    @media ${DeviceUp.sm} {
      width: 280px;
    }

    .clear-search {
      position: absolute;
      right: 6px;
      top: 11px;
      width: 18px;
      cursor: pointer;

      &:hover {
        path {
          stroke: ${theme.gray};
        }
      }

      svg {
        display: block;
        width: 100%;
      }

      path {
        stroke: ${theme.primary};
      }
    }

    .icon-Search-2-Outline-Color {
      font-size: 20px;
      margin: 14px 12px 12px 14px;
      position: absolute;
      left: 0;
      top: 0;
    }

    .form-control {
      padding-left: 40px;
      padding-right: 30px;
      text-overflow: ellipsis;
    }
  }
  .actions-block {
    margin: 16px 0 0;

    @media ${DeviceUp.xs} {
      float: right;
      text-align: right;
    }

    a {
      &.disabled {
        pointer-events: none;
      }
    }
    button {
      background: none;
      color: ${theme.text};
      border-radius: 0;
      height: auto;
      border: 0;
      padding: 0;
      font-size: 13px;
      margin: 0 15px 0 0;

      @media ${DeviceUp.xs} {
        margin: 0 0 0 15px;
      }

      @media ${DeviceUp.sm} {
        margin: 0 0 0 40px;
      }

      .text-holder {
        display: flex;
        align-items: center;
      }

      .icon {
        color: ${theme.secondary};
        font-size: 18px;
        margin:  0 6px 0 0;
      }
      &:hover {
        color: ${theme.secondary};
      }
      &.disabled {
        background: none;
        color: ${theme.gray};

        .icon {
          color: ${theme.gray};
        }
      }
    }
  }
`;

export const DataBlock = styled.div`
  margin: 0 0 10px;
  overflow: hidden;

  @media ${DeviceUp.sm} {
    margin: 0 0 10px 38px;
  }
  .select-info {
    margin: 10px 0 0;
    padding-left: 33px;
    position: relative;
    min-height: 21px;

    @media ${DeviceUp.xs} {
      float: left;
    }

    @media ${DeviceUp.sm} {
      padding: 0;
      margin: 2px 0 0;
    }
    .block {
      font-weight: 600;
      overflow: hidden;
      .info {
        display: inline-block;
        vertical-align: middle;
        margin: 0 10px 0 0;
      }
      .clear {
        display: inline-block;
        vertical-align: middle;
        color: ${theme.secondary};
        cursor: pointer;
        margin: 0 10px 0 0;
        &:hover {
          color: ${theme.text};
        }
      }
    }
    label {
      position: absolute;
      left: 0;
      top: 1px;
      @media ${DeviceUp.sm} {
        display: none;
      }
    }
  }
  .results-info {
    @media ${DeviceUp.xs} {
      float: right;
    }
    .info {
      display: inline-block;
      vertical-align: middle;
      font-weight: 600;
      margin: 0 10px 0 0;
    }
    select {
      background: ${theme.bodyBg};
      border: 1.75px solid ${theme.gray};
      border-radius: 3.5px;
      color: ${theme.textSecondary};
      outline: none;
      padding: 4px 11px 4px 5px;
      display: inline-block;
      vertical-align: middle;
      min-width: 70px;
    }
  }
  .md-order-reverse {
      @media ${DeviceDown.sm} {
        display: flex;
        flex-flow: column nowrap;
        flex-direction: column-reverse;
      }
    }
  }
`;

export const ResultsBlock = styled.div`
 font-weight: 600;
 color: ${theme.gray};

 @media ${DeviceUp.xl} {
    margin: 0 0 0 38px;
  }
 span {
  color: ${theme.text};
 }
`;

export const Pagination = styled.div`
 max-width: 400px;
 margin: -12px auto 0;
 text-align: center;
 font-weight: 600;
 ul {
   li {
     display: inline-block;
     vertical-align: middle;
     margin: 0 9px;
     a {
      color: ${theme.primary};
      &:hover {
        color: ${theme.text};
      }
     }
     &.prev,
     &.next {
       a {
        font-size: 0;
        line-height: 0;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: ${theme.primary};
        display: block;
        color: ${theme.bodyBg};
        i {
          font-size: 16px;
          line-height: 32px;
        }
        &:hover {
          background: ${theme.text};
        }
       }
     }
   }
 }
`;

export const NewUserHeader = styled.div`
 padding-bottom: 15px;

 @media ${DeviceUp.xxs} {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
 }

 h2 {
   font-size: 28px;
   font-weight: 600;
   line-height: 1.3;
   margin: 0 10px 15px 0;

   @media ${DeviceUp.sm} {
     margin: 0;
   }
 }
`;

export const AddUserForm = styled.div`
  margin: 0 -20px;
  padding: 30px 20px;
  border-top: solid 2px ${theme.gray};

  @media ${DeviceUp.lg} {
    margin: 0 -35px;
    padding: 30px 35px;
  }

  @media ${DeviceUp.xl} {
    margin: 0 -60px;
    padding: 33px 60px 0;
  }

  h3 {
    font-size: 24px;
    line-height: 26px;
    margin-bottom: 13px;
  }
`;

export const FormHolder = styled.div`
  max-width: 600px;
`;

export const FormRow = styled.div`
`;

export const FormCol = styled.div`
  padding: 0 0 27px;
  width: 100%;

  &.col-6 {
    @media ${DeviceUp.sm} {
      width: 50%;
    }
  }

  label, .custom-select {
    span {
      display: block;
      font-size: 18px;
      line-height: 20px;
      font-weight: 600;
      margin-bottom: 8px;
      color: ${theme.gray};
    }
  }
`;

export const CustomSlect = styled.div`
  position: relative;

  &:before {
    position: absolute;
    right: 17px;
    top: 17px;
    content: '';
    width: 8px;
    height: 8px;
    border: solid 2px ${theme.text};
    border-width: 0 0 2px 2px;
    pointer-events: none;
    transform: rotate(-45deg);
  }
`;

export const RadioButtonsList = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin: 0 -8px;

  .radio-button {
    margin: 0 8px 16px;
    min-width: 160px;
  }

  label {
    span {
      margin: 0;
      color: ${theme.text};
      font-weight: 400;
    }
  }
`;

export const InfoText = styled.div`
  display: block;
  color: ${theme.gray};
  margin-bottom: 20px;
`;

export const SearchTags = styled.div`
  position: relative;

  .form-control {
    padding-left: 48px;
    padding-right: 40px;
  }

  &:before {
    position: absolute;
    right: 17px;
    top: 17px;
    content: '';
    width: 8px;
    height: 8px;
    border: solid 2px ${theme.text};
    border-width: 0 0 2px 2px;
    pointer-events: none;
    transform: rotate(-45deg);
    z-index: 1;
  }

  .search-icon {
    position: absolute;
    left: 15px;
    top: 12px;
    z-index: 1;
    font-size: 24px;
  }

  &.search-users {
    margin-bottom: 15px;
    z-index: 9;

    .user-select__input-container {
      margin-top: 0;
      padding-top 0;
    }
    .user-select__placeholder {
      cursor: text;
      font-size: 16px;
      color: #292c2e;
      opacity: 0.8;
    }
    .user-select__option {
      cursor: pointer;
    }
    .user-select__control {
      border: 2px solid #aaa;
      height: 48px;
      font-size: 16px;
      line-height: 28px;
      color: #292c2e;
      border-radius: 4px;
      &.user-select__control--is-focused {
        border-color: ${theme.primary};
        outline: 0;
        box-shadow: 0 0 0 3px rgba(86, 183, 247, .25);
      }
    }
    .user-select__multi-value {
      display: none;
    }
    .user-select__value-container {
      height: 35px;
      padding: 0 0 0 48px;
    }
    @media ${DeviceUp.sm} {
      width: 305px;
      margin-bottom: 0;
    }
    
    &:before {
      display: none;
    }
  }
`;

export const SearchTagsList = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  margin:  0 -5px;
  padding-top: 10px;

  @media ${DeviceUp.sm} {
    padding-top: 20px;
  }

  .tag-button {
    margin: 0 5px 10px;
    border-radius: 18px;
    padding: 5px 18px;
    background-color: ${theme.primary};
    min-height: 36px;
    line-height: 18px;
    height: auto;
    border: 0;
    color: ${theme.bodyBg};
    display: flex;
    align-items: center;
    cursor: default;
    word-break: break-all;

    button {
      margin: 0;
      padding: 0;
      background: none;
      border: 0;
      height: auto;

      &:hover {
        color: ${theme.text};

        path {
          stroke: #fff;
        }
      }
    }

    @media ${DeviceDown.sm} {
      padding:  6px 10px;
      font-size: 12px;
    }

    img {
      margin-left: 6px;
      cursor: pointer;
    }
  }

  .clear-link {
    margin-top: 8px;
    padding:  0 5px;
    color: ${theme.secondary};
    svg {
      width: 16px;
      margin: 1px 0 0 6px;
      vertical-align: top;
      height: 16px;
    }
    .icon {
      margin: 1px 0 0 6px;
      vertical-align: top;
      display: inline-block;
    }
  }
`;

export const UserInfoWrapper = styled.div`
  margin: 0 -20px;
  padding: 33px 20px 10px;
  border-top: solid 2px ${theme.gray};

  @media ${DeviceUp.lg} {
    margin: 0 -35px;
    padding: 30px 35px;
  }

  @media ${DeviceUp.xl} {
    margin: 0 -60px;
    padding: 33px 60px 0;
  }
`;

export const InfoRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin:  0 -15px;

  @media ${DeviceUp.xs} {
    .order-3 {
      order: 3;
    }
    .order-4 {
      order: 4;
    }
    .order-5 {
      order: 5;
    }
    .order-6 {
      order: 6;
    }
    .order-7 {
      order: 7;
    }
  }
`;

export const InfoCol = styled.div`
  width: 100%;
  padding:  0 15px 33px;

  &.col-6 {
    @media ${DeviceUp.xs} {
      width: 50%;
    }
  }
`;

export const InfoTitle = styled.div`
  font-size: 16px;
  color: ${theme.gray};
  font-weight: 600;
  margin: 0 0 4px;

  @media ${DeviceUp.sm} {
    font-size: 18px;
  }
`;

export const InfoDetail = styled.div`
  font-size: 20px;
  line-height: 24px;
  color: ${theme.text};
  font-weight: 600;
  word-break: break-all;

  @media ${DeviceUp.sm} {
    font-size: 24px;
    line-height: 28px;
  }
`;

export const AccountStatus = styled.div`
  font-size: 20px;
  line-height: 24px;
  color: ${theme.text};
  font-weight: 600;
  display: flex;
  align-items: center;
  padding-top: 5px;

  @media ${DeviceUp.sm} {
    font-size: 24px;
    line-height: 28px;
    padding-top: 0;
  }

  .switch {
    margin-right: 10px;
  }
`;

export const CourseDetailWrapper = styled.div`
  margin: 0 -20px;
  padding: 33px 20px;
  border-top: solid 2px ${theme.gray};

  @media ${DeviceUp.lg} {
    margin: 0 -35px;
    padding: 30px 35px;
  }

  @media ${DeviceUp.xl} {
    margin: 0 -60px;
    padding: 33px 60px 0;
  }

  h3 {
    font-size: 28px;
    line-height: 30px;
    margin-bottom: 33px;
  }
`;

export const EnrolCoursesWrapper = styled.div`
  margin: 0 -20px;
  padding: 33px 20px;
  border-top: solid 2px ${theme.gray};

  @media ${DeviceUp.lg} {
    margin: 0 -35px;
    padding: 30px 35px;
  }

  @media ${DeviceUp.xl} {
    margin: 0 -60px;
    padding: 33px 60px 0;
  }

  h3 {
    font-size: 24px;
    line-height: 26px;
    margin-bottom: 20px;
  }

  .page-counter {
    margin-left: 30px;
    @media ${DeviceUp.lg} {
      margin-left: 0;
    }
  }
`;

export const SearchField = styled.div`
  position: relative;
  width: 100%;

  @media ${DeviceUp.sm} {
    width: 305px;
  }

  .form-control {
    padding-left: 48px;
  }

  .search-icon {
    position: absolute;
    left: 15px;
    top: 12px;
    font-size: 24px;
    z-index: 1;
  }
`;

export const SearchWrapper = styled.div`
  margin-bottom: 20px;

  @media ${DeviceUp.sm} {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  h3 {
    margin: 0 0 10px;

    @media ${DeviceUp.sm} {
      margin: 0;
    }
  }

  button {
    margin: 0 auto -6px 4px;
  }
`;

export const CardsList = styled.div`
  padding-bottom: 7px;
`;

export const CardContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .checkbox {
    position: relative;
    width: 20px;
    margin:  0 12px 0 0;

    @media ${DeviceUp.lg} {
      margin:  0 7px 0 -26px;
    }

    @media ${DeviceUp.xl} {
      margin:  0 12px 0 -40px;
    }
  }

  label {
    min-width: auto;
    min-height: auto;
  }
`;

export const UsersCounter = styled.div`
  font-size: 18px;
  color: ${theme.gray};
`;

export const TopSearchWrapper = styled.div`
  @media ${DeviceUp.sm} {
    align-items: flex-end;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .select-search__input{
    padding: 8px 30px 8px 46px
  }
`;

export const TagsListWrapper = styled.div`
  padding-bottom: 20px;

  @media ${DeviceUp.sm} {
    padding-bottom: 48px;
  }
`;

export const TextCenter = styled.div`
  text-align: center;
`;

export const ExpanderButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ProgressBar = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  .progress {
    flex: 1;
    height: 20px;
    position: relative;
    margin-right: 40px;

    .bar {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      min-width: 1px;
      background: ${theme.secondary};

      span {
        font-size: 12px;
        width: 40px;
        text-align: right;
        position: absolute;
        left: 100%;
        top: 3px;
      }
    }
  }
`;

export const ExpanderButton = styled.span`
  color: ${theme.secondary};
  font-size: 16px;
  line-height: 18px;
  cursor: pointer;

  i {
    margin: 4px 0 0 8px;
    display: inline-block;
    vertical-align: top;
  }
`;

export const ErrorSpan = styled.span`
  color: ${'#FF7A00'};
`;

export const HeadingButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 15px;

  @media ${DeviceUp.sm} {
    padding-bottom: 0;
  }

  h3 {
    margin-bottom: 0;
  }
  button {
    &.link {
      color: ${theme.secondary};

      &:hover {
        text-decoration: none;
      }
      i {
        display: inline-block;
        vertical-align: top;
        margin:  3px 0 0 6px;
      }
    }
  }
`;

export const PopupBox = styled.div`
  border-radius: 16px;
  background-color: ${theme.bodyBg};
  max-width: 680px;
  padding:  30px;
  flex-grow: 1;
  position: relative;

  @media ${DeviceUp.sm} {
    padding:  40px 40px 35px;
    border-radius: 24px;
  }

  label {
    color: ${theme.lightGray};
    margin:  0 0 30px;
    display: block;
    @media ${DeviceUp.sm} {
      margin:  0 0 83px;
    }
    span {
      display: block;
      margin:  0 0 5px;
    }
  }

  h2 {
    margin:  0 0 25px;

    @media ${DeviceUp.sm} {
      margin:  0 0 60px;
    }
  }

  .close-button {
    position: absolute;
    right: 20px;
    top: 15px;
    width: auto;
    height: auto;
    padding: 0;
    border-radius: 0;
    background: none;
    opacity: 0.35;
    border: 0;
    font-size: 20px;
    color: ${theme.text};

    @media ${DeviceUp.sm} {
      right: 35px;
      top: 23px;
    }
  }
`;

export const PopUpButtonsHolder = styled.div`
  display: flex;
  justify-content: flex-start;

  @media ${DeviceUp.sm} {
    justify-content: flex-end;
  }

  button {
    margin: 0 11px;
    min-width: 101px;
  }
`;
