import React from 'react';
import { PaginationLoaderStyle } from 'components/loader/styled';

const PaginationLoader = () => (
  <PaginationLoaderStyle className="section-loader loading">
    <ul className="loading-dots">
      <li>Loader</li>
      <li>Loader</li>
      <li>Loader</li>
    </ul>
  </PaginationLoaderStyle>
);

export default PaginationLoader;
