import analyticsConstants from 'analytics/constants';
import { INITIAL_STATE_FETCH_LEARNERS } from 'utils/values';

const fetchLearners = (state = INITIAL_STATE_FETCH_LEARNERS, action) => {
  switch (action.type) {
    case analyticsConstants.FETCH_LEARNERS: {
      const { filters, data } = action;
      const {
        next,
        count,
        results,
        previous,
      } = data;

      return {
        ...state,
        next,
        count,
        results,
        previous,
        filters,
      };
    }
    default:
      return state;
  }
};

export default fetchLearners;
