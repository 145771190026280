/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Drawer from 'components/drawer';
import CheckBox from 'common/checkbox';
import ToggleBox from 'common/togglebox';
import PaginationLoader from 'components/loader/paginationLoader';
import TableResultSelection from 'common/table-results-selection';
import PaginationBlock from 'common/pagination';
import { DebounceInput } from 'react-debounce-input';
import { usersActions } from 'users/actions';
import { confirmAlertPopup, getUserNameForNav, getUserRepresentation, messageNotification } from 'utils/functions'
import { ReactComponent as IconRemove } from 'assets/images/icon-remove.svg';
import { ReactComponent as IconClose } from 'assets/images/icon-close.svg';
import {
  ROLES,
  ROUTES,
  EMPTY_LIST_PLACEHOLDER,
  INITIAL_STATE_FETCH_USERS,
} from 'utils/values';
import { TableBlock } from 'assets/styles/table-styles';
import {
  MainContent,
  ButtonsHolder,
  InfoBlock,
  DataBlock,
  SearchTagsList,
} from './styled';
import STRINGS from '../utils/strings'

const Users = (props) => {
  const {
    users,
    filters,
    dispatch,
    userCount,
    totalUsersCount,
    isDBUsersSelected,
    selectedLangValues,
    selectedRolesLabels,
  } = props;
  const [showDrawer, setShowDrawer] = useState(false);
  const [inputKey, setInputKey] = useState(false);
  const [inProgress, setProgress] = useState(false);
  const [isBulkRegistrationEnabled, setBulkRegistrationStatus] = useState(true);

  useEffect(() => {
    const filterParam = { ...INITIAL_STATE_FETCH_USERS.filters };
    const fetchAllUsers = (params) => {
      setProgress(true);
      dispatch(usersActions.fetchUsers({ params, setProgress }));
      dispatch(usersActions.removeFilterLanguage('all'));
      dispatch(usersActions.removeFilterRole());
    };
    fetchAllUsers(filterParam);
  }, [dispatch]);

  /* eslint-disable arrow-body-style */
  const userIds = Object.keys(users).map((user) => users[user].id);
  const selectedUsers = userIds.filter((user) => {
    const userId = `user${user}`;
    return users[userId].isChecked;
  });

  const checkAllUsers = selectedUsers.length > 0 && selectedUsers.length === Object.keys(users).length;
  const checkCompleteUsers = selectedUsers.length > 0 && selectedUsers.length === totalUsersCount;
  const setDisable = !selectedUsers.length;
  const disableClass = setDisable ? 'disabled' : '';

  const noDataFound = !inProgress && userCount === 0;
  const showSelectedUsers = !noDataFound && !checkAllUsers && selectedUsers.length > 0;
  const showAllSelectedUsers = !noDataFound && checkAllUsers && !isDBUsersSelected;
  const showSelectedDBUsers = !noDataFound && isDBUsersSelected;

  const updateCheckList = (event) => {
    const { value, checked } = event.target;
    dispatch(usersActions.updateCheckedUser({ value, checked }));
    if (
      checked
      && selectedUsers.length === totalUsersCount
    ) {
      dispatch(usersActions.toggleDBUsers(true));
    } else {
      dispatch(usersActions.toggleDBUsers(false));
    }
  };

  const updateAllCheckList = (event) => {
    const { checked } = event.target;
    dispatch(usersActions.checkedAllUser({ checked }));
    if (checked && Object.keys(users).length === totalUsersCount) {
      dispatch(usersActions.toggleDBUsers(true));
    } else {
      dispatch(usersActions.toggleDBUsers(false));
    }
  };

  const onSort = (event) => {
    const name = event.target.getAttribute('name');
    let sortDirection = '';
    let sortOrder = '';
    if (name === filters.column) {
      if (filters.direction === 'asc') {
        sortDirection = 'desc';
        sortOrder = `-${name}`;
      } else if (filters.direction === 'desc') {
        sortDirection = '';
        sortOrder = '';
      } else {
        sortDirection = 'asc';
        sortOrder = name;
      }
    } else {
      sortDirection = 'asc';
      sortOrder = name;
    }
    const params = {
      ...filters,
      column: name,
      direction: sortDirection,
      ordering: sortOrder,
    };
    setProgress(true);
    dispatch(usersActions.fetchUsers({ params, setProgress }));
  };

  const handlePageChange = (property, value) => {
    if (inProgress) return;
    const params = {
      ...filters,
      [property]: value,
    };
    setProgress(true);
    dispatch(usersActions.fetchUsers({ params, setProgress }));
  };

  const handlePageSize = (value) => {
    const params = {
      ...filters,
      page_size: value,
      page: 1,
    };
    setProgress(true);
    dispatch(usersActions.fetchUsers({ params, setProgress }));
  };

  const handleSearch = (value) => {
    const params = {
      ...filters,
      search: value,
      page: 1,
    };
    setProgress(true);
    dispatch(usersActions.fetchUsers({ params, setProgress }));
  };

  const handleMultipleUserStatus = (status) => {
    const ids = isDBUsersSelected ? 'all' : selectedUsers;
    const statusText = status ? 'activate' : 'deactivate';
    confirmAlertPopup(
      `${statusText} User`,
      `Are you sure you want to ${statusText} the selected ${selectedUsers.length === 1 ? 'user' : 'users'}.`,
      () => {
        setProgress(true);
        dispatch(usersActions.toggleUsersStatus({ ids, status, setProgress }));
      },
      () => {},
    );
  };

  const applyFilters = () => {
    const params = {
      ...filters,
      languages: [],
      roles: [],
    };
    setProgress(true);
    dispatch(usersActions.removeFilterRole());
    dispatch(usersActions.removeFilterLanguage('all'));
    dispatch(usersActions.fetchUsers({ params, setProgress }));
    dispatch(usersActions.makeFilterBackup());
  };

  const clearFiltersLanguage = () => {
    const params = {
      ...filters,
      languages: [],
    };
    setProgress(true);
    dispatch(usersActions.removeFilterLanguage('all'));
    dispatch(usersActions.fetchUsers({ params, setProgress }));
    dispatch(usersActions.makeFilterBackup());
  };

  const clearFiltersRoles = () => {
    const params = {
      ...filters,
      roles: [],
    };
    setProgress(true);
    dispatch(usersActions.removeFilterRole());
    dispatch(usersActions.fetchUsers({ params, setProgress }));
    dispatch(usersActions.makeFilterBackup());
  };

  const handleSuccessBulkRegistration = () => {
    setProgress(false);
    setBulkRegistrationStatus(true);
  };

  const handleBulkRegistration = (event) => {
    const fileData = event.target.files[0];
    if (!fileData.name.endsWith('.csv')) {
      messageNotification(
        'danger',
        STRINGS.DEFAULT_AJAX_ERROR_TITLE,
        "Invalid File! Only CSV file is allowed.",
      );
      return;
    }
    setProgress(true);
    setBulkRegistrationStatus(false);
    dispatch(usersActions.bulkRegistration({ fileData, handleSuccessBulkRegistration }));
    setInputKey(Math.random().toString()); // setting random value to reset input field for onChange callback
  };

  const handleSingleUserStatus = (event) => {
    event.preventDefault();
    const id = event.target.getAttribute('dataid');
    const userId = `user${id}`;
    const status = event.target.checked;
    const statusText = status ? 'activate' : 'deactivate';
    confirmAlertPopup(
      `${statusText} User`,
      `Are you sure you want to ${statusText} ${getUserRepresentation(users[userId])}.`,
      () => {
        setProgress(true);
        dispatch(usersActions.toggleUserStatus({ id, status, setProgress }));
      },
      () => {},
    );
  };

  return (
    <>
      <MainContent>
        <h1>Users</h1>
        <ButtonsHolder>
          <button type="submit" className={`group ${disableClass}`} hidden>
            <i className="icon-Team-Outline-Color" aria-hidden="true" /> Add to Group
          </button>
          <Link to={ROUTES.ADD_USER}>
            <button><i className="icon-Add" aria-hidden="true" /> New User</button>
          </Link>
          <button className={`file-button ${isBulkRegistrationEnabled ? '' : 'disabled'}`}>
            <input
              type="file"
              accept=".csv"
              key={inputKey}
              onChange={handleBulkRegistration}
              disabled={!isBulkRegistrationEnabled}
            />
            <i className="icon-Add" aria-hidden="true" />
            Bulk Registration
          </button>
        </ButtonsHolder>
        <InfoBlock>
          <div className="search-block">
            <DebounceInput
              minLength={1}
              debounceTimeout={300}
              className="form-control"
              value={filters.search}
              placeholder="Search user by name/email"
              onChange={(event) => handleSearch(event.target.value)}
            />
            {
              filters.search && (
                <span className="clear-search" onClick={() => handleSearch('')}>
                  <IconClose />
                </span>
              )
            }
            <i className="icon-Search-2-Outline-Color" aria-hidden="true" />
          </div>
          <div className="actions-block">
            <button onClick={() => setShowDrawer(true)}>
              <div className="text-holder">
                <span className="icon icon-Filter" /> Filters
              </div>
            </button>
            <Link className={disableClass} disabled={setDisable}
              to={{
                allDBUsers: isDBUsersSelected,
                pathname: ROUTES.BULK_ENROLLING,
                initialSelectedUsers: selectedUsers,
              }}
            >
              <button className={disableClass} disabled={setDisable}>
                <div className="text-holder">
                  <span className="icon icon-Enroll" /> Enroll
                </div>
              </button>
            </Link>
            <button className={disableClass} disabled={setDisable} onClick={() => handleMultipleUserStatus(true)}>
              <div className="text-holder">
                <span className="icon icon-Check-Circle" /> Activate
              </div>
            </button>
            <button className={disableClass} disabled={setDisable} onClick={() => handleMultipleUserStatus(false)}>
              <div className="text-holder">
                <span className="icon icon-Block-Outline" /> Deactivate
              </div>
            </button>
          </div>
        </InfoBlock>
        <DataBlock>
          <SearchTagsList>
            {
              selectedLangValues
              && selectedLangValues.length > 0
              && (
                <span className="tag-button">
                  {`Language: ${selectedLangValues}`}
                  <button type="button" onClick={clearFiltersLanguage}>
                    <IconRemove />
                  </button>
                </span>
              )
            }
            {
              filters
              && filters.roles.length > 0
              && (
                <span className="tag-button">
                  {`Roles: ${selectedRolesLabels}`}
                  <button type="button" onClick={clearFiltersRoles}>
                    <IconRemove />
                  </button>
                </span>
              )
            }
            {
              filters
              && (filters.roles.length > 0 && filters.languages.length > 0)
              && (
                <button type="button" className="link clear-link" onClick={() => applyFilters()}>
                  Clear All
                  <span className="icon-Cancel icon" />
                </button>
              )
            }
          </SearchTagsList>
          <div className="md-order-reverse">
            <div className="select-info">
              <CheckBox
                checkid="checkAll"
                isChecked={checkAllUsers}
                callback={updateAllCheckList}
              />
              <div className="block">
                {
                  showSelectedUsers
                  && (<span className="info">{selectedUsers.length} selected </span>)
                }
                {
                  showAllSelectedUsers
                  && (
                    <div className="block">
                      <span className="info">All {selectedUsers.length} users on this page selected.</span>
                      <span
                        className="clear"
                        onClick={() => dispatch(usersActions.toggleDBUsers(true))}
                      >
                        Select all {totalUsersCount} users
                      </span>
                    </div>
                  )
                }
              </div>
              {
                showSelectedDBUsers
                && (
                  <div className="block">
                    <span className="info">All {totalUsersCount} users selected.</span>
                    {
                      !checkCompleteUsers && (
                        <span
                          className="clear"
                          onClick={() => dispatch(usersActions.toggleDBUsers(false))}
                        >
                          Clear selection
                        </span>
                      )
                    }
                  </div>
                )
              }
            </div>
            <TableResultSelection
              handleOnChange={handlePageSize}
              pageSize={INITIAL_STATE_FETCH_USERS.filters.page_size}
            />
          </div>
        </DataBlock>
        <TableBlock className="responsive">
          {
            inProgress && <PaginationLoader />
          }
          <table>
            <thead>
              <tr>
                <th className="col-check">
                  <CheckBox
                    checkid="checkAll"
                    isChecked={checkAllUsers}
                    callback={updateAllCheckList}
                  />
                </th>
                <th>
                  Name
                  <span
                    name="profile__name"
                    className={`sort ${(filters.column === 'profile__name') ? filters.direction : ''}`}
                    onClick={onSort}
                  >
                    sort
                  </span>
                </th>
                <th>
                  Email
                  <span
                    name="email"
                    className={`sort ${(filters.column === 'email') ? filters.direction : ''}`}
                    onClick={onSort}
                  >
                    sort
                  </span>
                </th>
                <th>Role</th>
                <th>Active</th>
                <th>Employee ID</th>
              </tr>
            </thead>
            <tbody>
              { users && (
                Object.keys(users).map((id) => (
                  <tr key={id}>
                    <td data-title="Select">
                      <CheckBox
                        checkid={`check-${users[id].employeeID}`}
                        callback={updateCheckList}
                        value={users[id].id}
                        isChecked={users[id].isChecked}
                        {...users[id]}
                      />
                    </td>
                    <td data-title="Name">
                      { getUserNameForNav(users[id]) }
                    </td>
                    <td data-title="Email">
                      <a target="_blank" rel="noreferrer" href={`mailto:${users[id].email}`}>{users[id].email}</a>
                    </td>
                    <td data-title="Role">
                      {ROLES[`role_${users[id].role}`].label}
                    </td>
                    <td data-title="Active">
                      <ToggleBox
                        readOnly
                        checkId={`status-${id}`}
                        isChecked={users[id].is_active}
                        dataid={users[id].id}
                        callback={handleSingleUserStatus}
                      />
                    </td>
                    <td data-title="Employee ID">
                      {users[id].employee_id ? users[id].employee_id : EMPTY_LIST_PLACEHOLDER}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </TableBlock>
        {
          noDataFound && (
            <div className="no-data">No results to show</div>
          )
        }
        { users
          && Object.keys(users).length > 0
          && (
            <PaginationBlock
              activePage={filters.page}
              itemsCountPerPage={filters.page_size}
              totalItemsCount={userCount}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              showPageCounter
            />
          )}
      </MainContent>
      <Drawer
        showDrawer={showDrawer}
        setShowDrawer={setShowDrawer}
      />
    </>
  );
};

function mapStateToProps(state) {
  const { results, count, filters, totalUsersCount, isDBUsersSelected } = state.USERSDATA;
  const { languages, roles } = state.LOGGEDINUSER;
  const selectedRolesIds = [];
  const selectedRolesLabels = [];
  const selectedLangCodes = [];
  const selectedLangValues = [];
  Object.keys(roles).forEach((key) => {
    const role = roles[key];
    if (role.isChecked) {
      selectedRolesIds.push(role.id);
      selectedRolesLabels.push(role.label);
    }
  });
  languages.forEach((lang) => {
    if (lang.isChecked) {
      selectedLangCodes.push(lang.code);
      selectedLangValues.push(lang.value);
    }
  });
  filters.languages = selectedLangCodes;
  filters.roles = selectedRolesIds;
  return {
    users: results,
    userCount: count,
    filters,
    totalUsersCount,
    isDBUsersSelected,
    selectedLangValues,
    selectedRolesLabels,
  };
}

export default connect(mapStateToProps)(Users);
