import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from 'utils/values';
import CheckBox from 'common/checkbox';
import BreadCrumbs from 'components/breadcrumbs';
import IconClose from 'assets/images/icon-close.svg';
import { TableBlock } from 'assets/styles/table-styles';
import Modal from 'react-bootstrap/Modal';
import {
  MainContainer,
  InfoBlock,
  DataBlock,
  ButtonsHolder,
  Pagination,
  ResultsCounter,
  HeaderHolder,
  UserInfoWrapper,
  InfoRow,
  InfoCol,
  InfoTitle,
  InfoDetail,
  HeadingHolder,
  LeftColumn,
  RightColumn,
  PopupBox,
  PopUpButtonsHolder,
  SelectAll,
} from './styled';

const GroupDetail = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <MainContainer>
        <h1>Groups</h1>
        <BreadCrumbs />
        <HeaderHolder>
          <h3>Compliance Group</h3>
          <ButtonsHolder className="v2">
            <button type="submit" className="group">
              <span className="icon icon-Pen-2-Outline-Color" />
              Edit Group
            </button>
            <button type="submit">
              <span className="icon icon-Enroll" />
              Enroll Group
            </button>
          </ButtonsHolder>
        </HeaderHolder>
        <UserInfoWrapper>
          <InfoRow>
            <InfoCol>
              <InfoTitle>Total members</InfoTitle>
              <InfoDetail>54</InfoDetail>
            </InfoCol>
            <InfoCol>
              <InfoTitle>Date Created</InfoTitle>
              <InfoDetail>10:50 pm, 21st Apr 2021</InfoDetail>
            </InfoCol>
          </InfoRow>
        </UserInfoWrapper>
        <HeadingHolder>
          <h4>Members</h4>
          <button
            type="button"
            className="link"
            onClick={handleShow}
          >
            Create New Group
            <span className="icon-Add icon" />
          </button>
        </HeadingHolder>
        <InfoBlock className="v3">
          <LeftColumn>
            <div className="search-block">
              <i className="icon-Search-2-Outline-Color" aria-hidden="true" />
              <input type="search" placeholder="Search Groups" />
            </div>
            <div className="actions-block">
              <button><span className="icon-Filter icon" /> Filters</button>
            </div>
          </LeftColumn>
          <RightColumn>
            <button type="button" className="link">
              <i className="icon icon-Profile-Cancel-Outline-Color" aria-hidden="true" />
              Remove Member
            </button>
          </RightColumn>
        </InfoBlock>
        <DataBlock>
          <div className="select-info">
            <SelectAll>
              <CheckBox checkId="check-all" />
            </SelectAll>
            <span className="info">2 selected</span>
          </div>
          <div className="results-info">
            <span className="info">Results per page</span>
            <select>
              <option>5</option>
              <option>10</option>
              <option>15</option>
              <option>20</option>
            </select>
          </div>
        </DataBlock>
        <TableBlock className="responsive">
          <table>
            <thead>
              <tr>
                <th className="col-check">
                  <CheckBox checkId="check01" />
                </th>
                <th>Name<span className="sort">sort</span></th>
                <th>Email<span className="sort">sort</span></th>
                <th>Team<span className="sort">sort</span></th>
                <th>Employee ID<span className="sort">sort</span></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td data-title="Select">
                  <CheckBox checkId="check02" />
                </td>
                <td data-title="Name"><Link to={ROUTES.USER_DETAILS}>Rehan Qazi</Link></td>
                <td data-title="Email"><a href="mailto:rehan@pkx.com">rehan@pkx.com</a></td>
                <td data-title="Team">Nurture</td>
                <td data-title="Employee ID">002</td>
              </tr>
              <tr>
                <td data-title="Select">
                  <CheckBox checkId="check03" isChecked />
                </td>
                <td data-title="Name"><Link to={ROUTES.USER_DETAILS}>Rehan Qazi</Link></td>
                <td data-title="Email"><a href="mailto:rehan@pkx.com">rehan@pkx.com</a></td>
                <td data-title="Team">Nurture</td>
                <td data-title="Employee ID">002</td>
              </tr>
              <tr>
                <td data-title="Select">
                  <CheckBox checkId="check04" isChecked />
                </td>
                <td data-title="Name"><Link to={ROUTES.USER_DETAILS}>Rehan Qazi</Link></td>
                <td data-title="Email"><a href="mailto:rehan@pkx.com">rehan@pkx.com</a></td>
                <td data-title="Team">Nurture</td>
                <td data-title="Employee ID">002</td>
              </tr>
              <tr>
                <td data-title="Select">
                  <CheckBox checkId="check05" />
                </td>
                <td data-title="Name"><Link to={ROUTES.USER_DETAILS}>Rehan Qazi</Link></td>
                <td data-title="Email"><a href="mailto:rehan@pkx.com">rehan@pkx.com</a></td>
                <td data-title="Team">Nurture</td>
                <td data-title="Employee ID">002</td>
              </tr>
              <tr>
                <td data-title="Select">
                  <CheckBox checkId="check06" />
                </td>
                <td data-title="Name"><Link to={ROUTES.USER_DETAILS}>Rehan Qazi</Link></td>
                <td data-title="Email"><a href="mailto:rehan@pkx.com">rehan@pkx.com</a></td>
                <td data-title="Team">Nurture</td>
                <td data-title="Employee ID">002</td>
              </tr>
            </tbody>
          </table>
        </TableBlock>
        <ResultsCounter>Showing <span>1-5</span> of <span>54</span> results</ResultsCounter>
        <Pagination>
          <ul>
            <li className="prev"><a href="/"><i className="icon-Arrow-Left" aria-hidden="true" />prev</a></li>
            <li><a href="/">1</a></li>
            <li><a href="/">2</a></li>
            <li><a href="/">3</a></li>
            <li className="next"><a href="/"><i className="icon-Arrow-Right" aria-hidden="true" />next</a></li>
          </ul>
        </Pagination>
      </MainContainer>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="user-popup"
      >
        <PopupBox>
          <h2>New Group</h2>
          <label htmlFor="group-name">
            <span>Group Name *</span>
            <input
              className="form-control"
              id="group-name"
              type="text"
              placeholder="Compliance Group"
            />
          </label>
          <PopUpButtonsHolder>
            <button type="submit" className="group" onClick={handleClose}>Cancel</button>
            <button type="submit">Save</button>
          </PopUpButtonsHolder>
          <button type="submit" className="close-button" onClick={handleClose}>
            <img src={IconClose} alt="Close" />
          </button>
        </PopupBox>
      </Modal>
    </>
  );
};

export default GroupDetail;
