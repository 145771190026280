import React, { useState } from 'react';
import CheckBox from 'common/checkbox';
import Pagination from 'common/pagination';
import BreadCrumbs from 'components/breadcrumbs';
import GroupCard from 'dashboard/components/groupcard/groupcard';
import IconRemove from 'assets/images/icon-remove.svg';
import Modal from 'react-bootstrap/Modal';
import {
  MainContent,
  ButtonsHolder,
  NewUserHeader,
  EnrolCoursesWrapper,
  SearchField,
  SearchWrapper,
  CardsList,
  CardContainer,
  SearchTags,
  SearchTagsList,
  UsersCounter,
  TopSearchWrapper,
  TagsListWrapper,
  ExpanderButtonWrapper,
  ExpanderButton,
  HeadingButtonWrapper,
  PopupBox,
  PopUpButtonsHolder,
} from './styled';

const AddUserToGroup = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <MainContent>
        <h1>Users</h1>
        <BreadCrumbs />
        <NewUserHeader>
          <h2>Add to Group</h2>
          <ButtonsHolder className="v2">
            <button type="submit" className="group">
              Cancel
              <i className="icon icon-Cancel right" />
            </button>
            <button type="submit">
              Enroll
              <i className="icon icon-Check right" />
            </button>
          </ButtonsHolder>
        </NewUserHeader>
        <EnrolCoursesWrapper>
          <TopSearchWrapper>
            <SearchTags className="search-users">
              <span className="search-icon icon-Search-2-Outline-Color" />
              <input className="form-control" type="text" placeholder="Search Users" />
            </SearchTags>
            <UsersCounter>24 Users selected</UsersCounter>
          </TopSearchWrapper>
          <TagsListWrapper>
            <SearchTagsList>
              <button type="button" className="tag-button">
                rehanqazi@arbisoft.com
                <img src={IconRemove} alt="Remove" />
              </button>
              <button type="button" className="tag-button">
                ibrahim@arbisoft.com
                <img src={IconRemove} alt="Remove" />
              </button>
              <button type="button" className="tag-button">
                ahmed@arbisoft.com
                <img src={IconRemove} alt="Remove" />
              </button>
              <button type="button" className="tag-button">
                ali@edly.io
                <img src={IconRemove} alt="Remove" />
              </button>
              <button type="button" className="tag-button">
                nauman@edly.io
                <img src={IconRemove} alt="Remove" />
              </button>
              <button type="button" className="tag-button">
                faizan@arbisoft.com
                <img src={IconRemove} alt="Remove" />
              </button>
              <button type="button" className="tag-button">
                faizan@arbisoft.com
                <img src={IconRemove} alt="Remove" />
              </button>
              <button type="button" className="tag-button">
                faizan@arbisoft.com
                <img src={IconRemove} alt="Remove" />
              </button>
              <button type="button" className="tag-button">
                faizan@arbisoft.com
                <img src={IconRemove} alt="Remove" />
              </button>
              <button type="button" className="tag-button">
                faizan@arbisoft.com
                <img src={IconRemove} alt="Remove" />
              </button>
              <button type="button" className="tag-button">
                faizan@arbisoft.com
                <img src={IconRemove} alt="Remove" />
              </button>
              <button type="button" className="tag-button">
                faizan@arbisoft.com
                <img src={IconRemove} alt="Remove" />
              </button>
              <button type="button" className="tag-button">
                faizan@arbisoft.com
                <img src={IconRemove} alt="Remove" />
              </button>
              <button type="button" className="tag-button">
                faizan@arbisoft.com
                <img src={IconRemove} alt="Remove" />
              </button>
              <button type="button" className="tag-button">
                faizan@arbisoft.com
                <img src={IconRemove} alt="Remove" />
              </button>
              <button type="button" className="tag-button">
                faizan@arbisoft.com
                <img src={IconRemove} alt="Remove" />
              </button>
              <button type="button" className="tag-button">
                faizan@arbisoft.com
                <img src={IconRemove} alt="Remove" />
              </button>
              <button type="button" className="tag-button">
                faizan@arbisoft.com
                <img src={IconRemove} alt="Remove" />
              </button>
              <button type="button" className="tag-button">
                faizan@arbisoft.com
                <img src={IconRemove} alt="Remove" />
              </button>
              <button type="button" className="tag-button">
                faizan@arbisoft.com
                <img src={IconRemove} alt="Remove" />
              </button>
              <button type="button" className="tag-button">
                faizan@arbisoft.com
                <img src={IconRemove} alt="Remove" />
              </button>
              <button type="button" className="tag-button">
                faizan@arbisoft.com
                <img src={IconRemove} alt="Remove" />
              </button>
              <button type="button" className="tag-button">
                faizan@arbisoft.com
                <img src={IconRemove} alt="Remove" />
              </button>
            </SearchTagsList>
            <ExpanderButtonWrapper>
              <ExpanderButton>show more<i className="icon-Chevron-Down" aria-hidden="true" /></ExpanderButton>
            </ExpanderButtonWrapper>
          </TagsListWrapper>
          <SearchWrapper>
            <HeadingButtonWrapper>
              <h3>Groups</h3>
              <button type="button" className="link" onClick={handleShow}>
                Create New Group
                <i className="icon-Add" />
              </button>
            </HeadingButtonWrapper>
            <SearchField>
              <span className="search-icon icon-Search-2-Outline-Color" />
              <input className="form-control" type="text" placeholder="Search Groups" />
            </SearchField>
          </SearchWrapper>
          <CardsList>
            <CardContainer>
              <CheckBox checkId="check01" />
              <GroupCard
                groupName="Group name"
                membersCounter="54"
                creationDate="27th Jan 2021"
              />
            </CardContainer>
            <CardContainer>
              <CheckBox checkId="check02" />
              <GroupCard
                groupName="Group name"
                membersCounter="54"
                creationDate="27th Jan 2021"
              />
            </CardContainer>
            <CardContainer>
              <CheckBox checkId="check03" />
              <GroupCard
                groupName="Group name"
                membersCounter="54"
                creationDate="27th Jan 2021"
              />
            </CardContainer>
          </CardsList>
          <Pagination />
        </EnrolCoursesWrapper>
      </MainContent>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="user-popup"
      >
        <PopupBox>
          <h2>New Group</h2>
          <label htmlFor="group-name">
            <span>Group Name *</span>
            <input
              className="form-control"
              id="group-name"
              type="text"
              placeholder="Compliance Group"
            />
          </label>
          <PopUpButtonsHolder>
            <button type="submit" className="group" onClick={handleClose}>Cancel</button>
            <button type="submit">Save</button>
          </PopUpButtonsHolder>
          <button type="submit" className="close-button" onClick={handleClose}>
            <i className="icon-Cancel" />
          </button>
        </PopupBox>
      </Modal>
    </>
  );
};
export default AddUserToGroup;
