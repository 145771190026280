import { createGlobalStyle } from 'styled-components';
import { theme } from 'assets/styles/theme';

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    max-height: 1000000px;
  }

  html {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
    -moz-box-sizing: inherit;
    -webkit-box-sizing: inherit;
  }
  body {
    background: ${theme.bodyBg};
    color: ${theme.text};
    font: ${theme.baseFontSize}/${theme.baseLineHeight} ${theme.fontFamily};
    margin: 0;
    min-width: 320px;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: none;
  }
  img {
    border-style: none;
    max-width: 100%;
    height: auto;
    display: block;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 36px;
    font-weight: 600;
    line-height: 1.23;
    margin: 0 0 20px;
    text-transform: capitalize;
  }
  h2 { font-size: 36px; }
  h3 { font-size: 32px; }
  h4 { font-size: 28px; }
  h5 { font-size: 25px; }
  h6 { font-size: 20px; }

  p {
    margin: 0 0 20px;
  }
  input,
  button {
    outline: none;
  }
  article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, main { display: block; }
  * { max-height: 1000000px; }
  ul, ol {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  ul>li { list-style-type: none; }
  a {
    text-decoration: none;
  }
  button {
    background: ${theme.primary};
    border-radius: 4px;
    height: 48px;
    text-align: center;
    color: ${theme.bodyBg};
    padding: 0 18px;
    border: 1px solid transparent;
    font-size: ${theme.lgFontSize};
    cursor: pointer;
    transition: linear 0.3s;
    white-space: nowrap;

    path {
      stroke: ${theme.bodyBg};
    }

    svg {
      vertical-align: middle;
      margin-left: 10px;
    }

    &:hover {
      border-color: ${theme.primary};
      color: ${theme.primary};
      background: ${theme.bodyBg};

      path {
        stroke: ${theme.primary};
      }
    }

    &.group {
      background: none;
      color: ${theme.secondary};
      border-color: ${theme.secondary};

      &:hover {
        background: ${theme.secondary};
        color: ${theme.bodyBg};
      }

      &.disabled,
      &:disabled {
        background: ${theme.lightGray};
        border-color: ${theme.lightGray};
        color: ${theme.bodyBg};
        pointer-events: none;

        img {
          &.group {
            display: none;
          }
          &.group-white {
            display: inline-block;
            vertical-align: bottom;
          }
        }
      }
    }

    &.outline {
      border-color: ${theme.secondary};
      color: ${theme.secondary};
      background: ${theme.bodyBg};

      &:hover {
        background: ${theme.secondary};
        color: ${theme.bodyBg};
      }
    }

    &.link {
      border-color: ${theme.bodyBg};
      color: ${theme.primary};
      background: ${theme.bodyBg};
      height: auto;

      path {
        stroke: ${theme.primary};
      }

      &:hover {
        text-decoration: underline;
      }
    }

    &.disabled {
      cursor: not-allowed;
      color: ${theme.bodyBg};
      border-color: ${theme.lightGray};
      background: ${theme.lightGray};
      pointer-events: none;

      &:hover {
        border-color: ${theme.lightGray};
        color: ${theme.bodyBg};
        background: ${theme.lightGray};
      }
    }
  }

  .form-control {
    width: 100%;
    border: 2px solid ${theme.gray};
    height: 48px;
    font-size: 16px;
    line-height: 28px;
    color: ${theme.text};
    padding: 8px 16px;
    border-radius: 4px;
    outline: none;

    &:focus {
      border-color: ${theme.primary};
      outline: 0;
      box-shadow: 0 0 0 3px rgba(86, 183, 247, .25);
    }
  }

  select.form-control {
    -webkit-appearance: none;
    appearance: none;
    paddingRight: 30px;
  }

  ::-webkit-input-placeholder { ${theme.textSecondary}; }
  :-moz-placeholder { color: ${theme.textSecondary}; }
  ::-moz-placeholder {
    color: ${theme.textSecondary};
    opacity: 1;
  }
  :-ms-input-placeholder { color: ${theme.textSecondary}; }
  .no-data {
    padding: 40px 10px;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
  }
  [hidden],
  .hidden {
    display: none !important;
  }
  .page-wrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      color: ${theme.primary};
    }
  }
`;

export default GlobalStyle;
