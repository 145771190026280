import React from 'react';
import Pagination from 'react-js-pagination';

import {
  PaginationStyles,
  ResultsBlock,
} from './styled';

const PaginationBlock = (props) => {
  const { activePage, totalItemsCount, itemsCountPerPage, onPageChange, showPageCounter } = props;
  const handlePageChange = (pageNumber) => {
    if (pageNumber !== activePage) {
      onPageChange('page', pageNumber);
    }
  };

  let startIndex = 0;
  let lastIndex = 0;

  if (showPageCounter) {
    startIndex = Math.max(((activePage - 1) * itemsCountPerPage), 0) + 1;
    lastIndex = Math.min((activePage * itemsCountPerPage), totalItemsCount);
  }

  return (
    <>
      {
        showPageCounter ? (
          <ResultsBlock className="page-counter">
            Showing
            <span className="show-results"> {startIndex} - {lastIndex} </span>
            of
            <span className="total-results"> {totalItemsCount} </span>
            results
          </ResultsBlock>
        ) : ''
      }
      <PaginationStyles>
        <Pagination
          activePage={activePage}
          itemsCountPerPage={itemsCountPerPage}
          totalItemsCount={totalItemsCount}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
          itemClassPrev="prev"
          itemClassNext="next"
          itemClassFirst="hidden"
          itemClassLast="hidden"
          linkClass="link"
          activeClass="active-item"
          activeLinkClass="active-link"
        />
      </PaginationStyles>
    </>
  );
};

export default PaginationBlock;
