import styled from 'styled-components';
import { theme } from 'assets/styles/theme';
import { DeviceUp } from 'assets/styles/breakpoints';

export const Styled = styled.div`
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  padding: 19px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  background-color: #fff;
  top: 0;
  z-index: 100;

  @media ${DeviceUp.lg} {
    padding: 19px 30px;
  }
  
  .logo {
    height: 35px;
    width: 108px;
    margin-top: 4px;

    @media ${DeviceUp.xxxs} {
      width: 130px;
      height: 36px;
      margin-top: 0;
    }
  }

  .side-menu {
    transform: translateX(-100%);
    display: block;
    transition: linear 0.4s;

    @media ${DeviceUp.lg} {
      display: none;
    }
  }

  &.menu-active {
    .bg-overlay {
      opacity: 1;
      visibility: visible;
    }
    .side-menu {
      transform: translateX(0);
      box-shadow: 0 0 15px rgba(0,0,0, 0.2);
    }
  }

  .dropdown {
    position: relative;

    button {
      i {
        color: ${theme.secondary};
        font-size: 24px;
        vertical-align: middle;

        &:hover {
          color: ${theme.text};
        }
      }
    }
  }

  .dropdown-menu {
    position: absolute;
    right: 0;
    top: 100%;
    background: ${theme.bodyBg};
    box-shadow: 0 0 4px rgb(0 0 0 / 20%);
    min-width: 208px;
    margin:  11px 0 0 0;
    z-index: 10;
    display: none;

    &.show {
      display: block;
    }
  }

  .notification-dropdown{
    background: #ECF0F4;
    border: ${theme.borderSecondary};
    box-sizing: border-box;
    border-radius: 4px;
    min-height: 220px;
    width: 210px;
    padding: 0;
    transform: none !important;
    top: 100% !important;
    right: -10px !important;
    bottom: auto !important;
    left: auto !important;

    @media ${DeviceUp.xxxs} {
      width: 260px;
      min-height: 260px;
    }

    @media ${DeviceUp.md} {
      width: 393px;
      min-height: 345px;
    }
    &:after, &:before {
      display: none;
    }
  }
`;

export const Overlay = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    top: 82px;
    height: 100%;
    background-color: rgba(0,0,0, 0.5);
    opacity: 0;
    visibility: hidden;
    transition: linear 0.4s;

    @media ${DeviceUp.lg} {
      display: none;
    }
  }
`;

export const Nav = styled.div`
  text-align: right;
  .links {
    display: inline-block;
    vertical-align: middle;
    font-size: 16px;
    line-height: 20px;

    @media ${DeviceUp.sm} {
      padding: 0 7px 0 0;
      border-right: 1px solid #DDE3E9;
    }
    li {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      margin: 0 10px;

      @media ${DeviceUp.sm} {
        margin: 0 13px 5px;
      }

      @media ${DeviceUp.lg} {
        margin: 0 20px 5px;
      }

      &.mx-2 {
        margin: 0 10px;
      }
      &:hover {
        color: ${theme.text};
      }

      &.mobile-hidden {
        display: none;
        @media ${DeviceUp.sm} {
          display: inline-block;
        }
      }
      >a {
        color: ${theme.secondary};
        font-weight: 600;
        &:hover {
          color: ${theme.text};
        }
        &.mail {
          color: ${theme.secondary};
          font-size: 24px;

          &:hover {
            color: ${theme.text};
          }
        }
      }

      .dropdown-toggle {
        background: none;
        border: 0;
        border-radius: 0;
        padding: 0;
        height: auto;
      }
    }
  }
  .user {
    display: inline-block;
    padding: 7px 0;
    vertical-align: middle;
    li {
      display: inline-block;
      vertical-align: middle;
      margin: 0 0 0 8px;
      position: relative;

      @media ${DeviceUp.sm} {
        margin: 0 0 0 15px;
      }

      @media ${DeviceUp.sm} {
        margin: 0 0 0 20px;
      }

      &.mobile-hidden {
        display: none;
        @media ${DeviceUp.sm} {
          display: inline-block;
        }
      }
      a {
        color: ${theme.MediumGray};
        &:hover {
          color: ${theme.secondary};
        }

        &.user-name {
          max-width: 120px;
          text-overflow: ellipsis;
          overflow: hidden;
          display: block;
          white-space: nowrap;
          color: ${theme.secondary};

          @media ${DeviceUp.lg} {
            max-width: 200px;
          }

          &:hover {
            color: ${theme.text};
          }
        }
      }
      .image {
        border-radius: 50%;
        display: block;
        width: 30px;
        height: 30px;
        padding: 0;
        background: none;
        border: 0;
        overflow: hidden;
        img {
          border: 1px solid #e7e7e7;
          border-radius: 50%;
          display: block;
          width: 30px;
          height: 30px;
          object-fit: cover;
        }
      }
    }
  }
  .dropdown-menu {
    li {
      display: block;
      margin: 0;
      text-align: left;

      + li {
        border-top: solid 1px ${theme.lightGray};
      }
      a {
        display: flex;
        align-items: center;
        padding: 11px 15px;
        font-weight: 600;
        transition: linear 0.4s;

        .icon {
          flex-shrink: 0;
          margin: 0 9px 0 0;
          font-size: 20px;
        }
      }
    }
  }
`;

export const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const BurgerMenuIcon = styled.span`
  position: relative;
  width: 24px;
  height: 18px;
  display: block;
  font-size: 0;
  line-height: 0;
  margin-left: 17px;
  cursor: pointer;

  @media ${DeviceUp.xxxs} {
    margin-left: 20px;
  }

  @media ${DeviceUp.lg} {
    display: none;
  }

  span, &:after, &:before {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    height: 3px;
    background-color: ${theme.secondary};
    transition: linear 0.4s;
  }

  &:after, &:before {
    content: '';
  }

  span {
    top: 8px;
  }

  &:before {
    top: 16px;
  }

  .menu-active &{
    span {
      opacity: 0;
    }
    &:after{
      transform: rotate(45deg);
    }
    &:before {
      transform: rotate(-45deg);
    }
    &:after, &:before {
      top: 9px;
    }
  }
`;
