import React from 'react';
import { ROUTES } from 'utils/values';
import { Link } from 'react-router-dom';
import {
  LMS_BASE_URL,
  CMS_BASE_URL,
} from 'config';
import CreateIcon from 'assets/images/img-create.svg';
import BookIcon from 'assets/images/img-book.svg';
import DiscoverIcon from 'assets/images/img-discover.svg';
import ManageIcon from 'assets/images/img-manage.svg';
import {
  Styled,
  MainHeading,
  StatsHolder,
  Block,
} from './styled';

const QuickLinks = () => (
  <Styled>
    <>
      <MainHeading>Quick Links</MainHeading>
      <StatsHolder>
        <Block>
          <div className="image-holder"><img src={BookIcon} alt="Book" /></div>
          <div className="text-holder">
            <span className="title">Learn</span>
            <p>Get started with your courses.</p>
            <a href={`${LMS_BASE_URL}courses`} target="_blank" rel="noreferrer">
              View courses
              <i className="icon-Chevron-Right" aria-hidden="true" />
            </a>
          </div>
        </Block>
        <Block>
          <div className="image-holder"><img src={CreateIcon} alt="Create" /></div>
          <div className="text-holder">
            <span className="title">Create</span>
            <p>Create and manage your courses.</p>
            <a href={`${CMS_BASE_URL}`} target="_blank" rel="noreferrer">
              Visit Studio
              <i className="icon-Chevron-Right" aria-hidden="true" />
            </a>
          </div>
        </Block>
        <Block>
          <div className="image-holder"><img src={DiscoverIcon} alt="Discover" /></div>
          <div className="text-holder">
            <span className="title">Discover</span>
            <p>Explore the ilmX catalog.</p>
            <a href={`${LMS_BASE_URL}`} target="_blank" rel="noreferrer">
              Explore Now
              <i className="icon-Chevron-Right" aria-hidden="true" />
            </a>
          </div>
        </Block>
        <Block>
          <div className="image-holder"><img src={ManageIcon} alt="Manage" /></div>
          <div className="text-holder">
            <span className="title">Manage</span>
            <p>View and manage your users.</p>
            <Link to={ROUTES.USERS}>
              Manage Users
              <i className="icon-Chevron-Right" aria-hidden="true" />
            </Link>
          </div>
        </Block>
      </StatsHolder>
    </>
  </Styled>
);

export default QuickLinks;
