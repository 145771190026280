import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  ROUTES,
  CONTACT_EMAIL,
} from 'utils/values';
import { LMS_BASE_URL, CMS_BASE_URL, ADMIN_PANEL_BASE_URL } from 'config';
import logo from 'assets/images/logo.svg';
import SideMenu from 'components/sidebar';
import Notifications from 'components/notifications';
import Dropdown from 'react-bootstrap/Dropdown';
import {
  Styled,
  Nav,
  BurgerMenuIcon,
  MenuWrapper,
  Overlay,
} from './styled';

const Header = (props) => {
  const [isActive, setActive] = useState('false');
  const { loggedInUser } = props;
  const profileImage = loggedInUser.profileImage.startsWith('http')
    ? loggedInUser.profileImage
    : LMS_BASE_URL + loggedInUser.profileImage.slice(1);
  const handleToggle = () => {
    setActive(!isActive);
  };
  return (
    <>
      <Styled className={`menu-${isActive ? '' : 'active'}`}>
        <Link to={ROUTES.ROOT} className="logo"><img src={logo} alt="ilmX" /></Link>
        <MenuWrapper>
          <Nav>
            <ul className="links">
              <li className="mobile-hidden">
                <a target="_blank" rel="noreferrer" href={LMS_BASE_URL}>Learn</a>
              </li>
              <li className="mobile-hidden">
                <a target="_blank" rel="noreferrer" href={CMS_BASE_URL}>Create</a>
              </li>
              <li className="mobile-hidden">
                <a href={ADMIN_PANEL_BASE_URL}>Manage</a>
              </li>
              <li className="mx-2">
                <a target="_blank" rel="noreferrer" href={`mailto:${CONTACT_EMAIL}`} className="mail">
                  <i className="icon-Help-Outline"><span className="sr-only">mail</span></i>
                </a>
              </li>
              <li className="mx-2">
                <Dropdown>
                  <Dropdown.Toggle>
                    <i className="icon-Notification-8" aria-hidden="true" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="notification-dropdown">
                    <Notifications />
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
            <ul className="user">
              <li className="mobile-hidden">
                <Link className="user-name" to={ROUTES.DASHBOARD}>{loggedInUser.name}</Link>
              </li>
              <li>
                <Dropdown>
                  <Dropdown.Toggle className="image">
                    <img src={profileImage} alt="Placeholder" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu">
                    <ul>
                      <li>
                        <a target="_blank" rel="noreferrer" href={`${LMS_BASE_URL}dashboard`}>
                          <span className="icon icon-Home-22" />Dashboard
                        </a>
                      </li>
                      <li>
                        <a target="_blank" rel="noreferrer" href={`${LMS_BASE_URL}account/settings`}>
                          <span className="icon icon-Settings" /> Account
                        </a>
                      </li>
                      <li>
                        <a href={`${LMS_BASE_URL}logout`}><span className="icon icon-Logout" />Sign Out</a>
                      </li>
                    </ul>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
          </Nav>
          <BurgerMenuIcon onClick={handleToggle}>
            <span>Burger Menu</span>
          </BurgerMenuIcon>
          <Overlay className="bg-overlay" onClick={handleToggle} />
          <SideMenu />
        </MenuWrapper>
      </Styled>
    </>
  );
};

export default Header;
