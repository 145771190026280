import styled from 'styled-components';
import { theme } from 'assets/styles/theme';
import { DeviceUp } from 'assets/styles/breakpoints';

export const Styled = styled.div`
  margin-bottom: 15px;

  @media ${DeviceUp.sm} {
    margin-bottom: 30px;
  }
`;

export const BreadCrumbsWrapper = styled.div`
  color: ${theme.secondary};
  font-size: 14px;
  line-height: 18px;

  ul {
    display: flex;
    flex-flow: row wrap;
    li {
      a {
        color: ${theme.gray};

        &:hover {
          color: ${theme.secondary};
        }
      }
      &:after {
        color: ${theme.gray};
        content: '\\e909';
        display: inline-block;
        font-family: 'ilmx';
        font-size: 10px;
        margin:  2px 6px 0 8px;
        vertical-align: top;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }
`;
