import styled from 'styled-components';
import { DeviceUp } from 'assets/styles/breakpoints';
import { theme } from 'assets/styles/theme';

export const EmptyNotification = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  min-height: inherit;
  justify-content: center;
  font-size: 12px;
  line-height: 17px;

  @media ${DeviceUp.md} {
    font-size: 16px;
    line-height: 22px;
  }

  >div {
    padding: 15px;
    @media ${DeviceUp.md} {
      max-width: 305px;
      padding: 0;
    }
  }

  .icon {
    display: block;
    margin: 0 auto 20px;
    max-width: 60px;

    @media ${DeviceUp.xxxs} {
      max-width: 90px;
    }

    @media ${DeviceUp.md} {
      margin: 0 auto 30px;
      max-width: 125px;
    }
  }

  strong {
    display: block;
    font-size: 18px;
    line-height: 22px;
    margin: 0 0 5px;

    @media ${DeviceUp.md} {
      font-size: 22px;
      line-height: 26px;
    }
  }
`;
export const NotificationWrapper = styled.div`
  display: block;
`;

export const NotificationDropWrapper = styled.div`
  padding: 15px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${DeviceUp.md} {
    padding: 20px 20px;
  }

  strong {
    font-size: 18px;
    line-height: 22px;

    @media ${DeviceUp.md} {
      font-size: 22px;
      line-height: 26px;
    }
  }

  .view-all {
    text-transform: uppercase;
    margin: 0 0 0 10px;
    font-size: 12px;
    line-height: 16px;
    color: ${theme.secondary};
    font-weight: 700;

    @media ${DeviceUp.md} {
      font-size: 14px;
      line-height: 18px;
    }

    i {
      margin: 0 0 0 6px;
      font-size: 16px;
    }
  }
`;

export const NotificationList = styled.div`
  text-align: left;
`;

export const Notification = styled.div`
  height: 76px;
  background: ${theme.bodyBg};
  margin:  0 0 5px;
  display: flex;
  justify-content: space-between;
  padding:  10px 15px 10px 15px;
  font-size: 13px;

  @media ${DeviceUp.md} {
    padding:  15px 20px 14px 20px;
    font-size: 14px;
    height: 86px;
  }

  &.unread {
    background: ${theme.unreadText};
  }

  .icon {
    background: ${theme.iconBg};
    border: 1px solid #D5DDE6;
    border-radius: 4px;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-right: 10px;
  }

  .text {
    line-height: 17px;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }

  .time {
    display: block;
    color: #828282;
    line-height: 18px;
    padding-top: 5px;
  }
`;
