import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Routes from 'components/routes';
import { GlobalStyle } from 'assets/styles/global-styles';
import { theme } from 'assets/styles/theme';
import { MainContainer, ContentWrapper } from 'users/styled';
import { LMS_BASE_URL } from 'config';
import { usersActions } from 'users/actions';
import ReactNotification from 'react-notifications-component';
import FullPageLoader from 'components/loader/fullPageLoader';
import Header from 'components/header';
import SideBar from 'components/sidebar';
import 'react-notifications-component/dist/theme.css';
import 'react-confirm-alert/src/react-confirm-alert.css';

const App = (props) => {
  const { dispatch, loggedInUser } = props;
  const [haveAccess, allowAccess] = useState(false);
  const [inProgress, setProgress] = useState(false);
  const [authMessage, setAuthMessage] = useState('');
  useEffect(() => {
    setProgress(true);
    dispatch(
      usersActions
        .fetchLoggedInUsers({ allowAccess, setProgress, setAuthMessage }),
    );
  }, [dispatch]);

  if (inProgress) {
    return (
      <div className="app-container">
        <GlobalStyle theme={theme} />
        <FullPageLoader>
          <div>Please wait...</div>
        </FullPageLoader>
      </div>
    );
  }

  if (!haveAccess && !inProgress) {
    return (
      <div className="app-container">
        <GlobalStyle theme={theme} />
        <div className="page-wrapper">
          {authMessage} Please go to&nbsp;<a href={LMS_BASE_URL}>Home</a>.
        </div>
      </div>
    );
  }

  return (
    <div className="app-container">
      <ReactNotification />
      <GlobalStyle theme={theme} />
      <Header loggedInUser={loggedInUser} />
      <MainContainer>
        <SideBar />
        <ContentWrapper>
          <Routes />
        </ContentWrapper>
      </MainContainer>
    </div>
  );
};

function mapStateToProps(state) {
  const loggedInUser = state.LOGGEDINUSER;
  return {
    loggedInUser,
  };
}

export default connect(mapStateToProps)(withRouter(App));
