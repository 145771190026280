import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { ROUTES } from 'utils/values';
import Users from 'users/users';
import NoPageFound from '404/404';
import Groups from 'groups/groups';
import AddUser from 'users/registration/adduser';
import UserDetail from 'users/userdetail';
import NewGroup from 'groups/newgroup';
import GroupDetail from 'groups/group-detail';
import Analytics from 'analytics/analytics';
import Dashboard from 'dashboard/dashboard';
import BulkEnrolling from 'users/enrollment/bulkenrolling';
import AddUserToGroup from 'users/addusertogroup';
import EditGroup from 'groups/edit-group';
import NewGroupConfirmation from 'groups/new-group-confirmation';
import IndividualEnrolling from 'users/enrollment/individualenrolling';
import GroupBulkEnrolling from 'groups/bulkenrolling';
import GroupIndividualEnrolling from 'groups/individualenrolling';
import AddNewMemberToGroup from 'groups/add-new-member';

const Routes = () => (
  <Switch>
    <Redirect exact from={ROUTES.ROOT} to={ROUTES.DASHBOARD} />
    <Route path={ROUTES.DASHBOARD} component={Dashboard} />
    <Route path={ROUTES.ADD_USER} component={AddUser} />
    <Route path={ROUTES.BULK_ENROLLING}>
      <BulkEnrolling
        breadCrumbsPath={
          [{
            label: 'Users',
            route: ROUTES.USERS,
          }]
        }
      />
    </Route>
    <Route exact path={`${ROUTES.USERS}/:id(\\d+)`}>
      <UserDetail
        breadCrumbsPath={
          [{
            label: 'Users',
            route: ROUTES.USERS,
          }]
        }
      />
    </Route>
    <Route path={`${ROUTES.ANALYTICS}${ROUTES.LEARNER}/:id(\\d+)`}>
      <UserDetail
        breadCrumbsPath={
          [{
            label: 'Analytics',
            route: ROUTES.ANALYTICS,
          }]
        }
      />
    </Route>
    <Route path={ROUTES.USERS} exact component={Users} />
    <Route path={ROUTES.ADD_USERS_TO_GROUP} component={AddUserToGroup} />
    <Route path={`${ROUTES.USERS}/:userId${ROUTES.ENROLL}`} component={IndividualEnrolling} />
    <Route path={ROUTES.GROUPS} component={Groups} />
    <Route path={ROUTES.ANALYTICS} component={Analytics} />
    <Route path={ROUTES.NEW_GROUP} component={NewGroup} />
    <Route path={ROUTES.GROUP_DETAIL} component={GroupDetail} />
    <Route path={ROUTES.EDIT_GROUP} component={EditGroup} />
    <Route path={ROUTES.NEW_GROUP_CONFIRMATION} component={NewGroupConfirmation} />
    <Route path={ROUTES.GROUP_BULK_ENROLLING} component={GroupBulkEnrolling} />
    <Route path={ROUTES.GROUP_INDIVIDUAL_ENROLLING} component={GroupIndividualEnrolling} />
    <Route path={ROUTES.GROUP_ADDING_NEW_MEMBER} component={AddNewMemberToGroup} />
    <Route path={ROUTES.NO_PAGE_FOUND} component={NoPageFound} />
  </Switch>
);

export default Routes;
