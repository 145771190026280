import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ROUTES, EMPTY_LIST_PLACEHOLDER } from 'utils/values';
import CountUp from 'react-countup';
import { messageNotification } from 'utils/functions';
import STRINGS from 'utils/strings';
import { analyticsService } from 'services/analytics';
import PaginationLoader from 'components/loader/paginationLoader';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import {
  Styled,
  MainHeading,
  StatsHolder,
  Block,
} from './styled';

const Stats = () => {
  const [inProgress, setProgress] = useState(false);
  const [completedCourse, setCompletedCourse] = useState(0);
  const [courseAssigned, setCourseAssigned] = useState(0);
  const [learners, setLearners] = useState(0);
  const [courseInProgress, setCourseInProgress] = useState(0);
  useEffect(() => {
    setProgress(true);
    analyticsService
      .fetchStats()
      .then((response) => {
        const { data } = response;
        setProgress(false);
        setCompletedCourse(data.completed_course_count);
        setCourseAssigned(data.course_assignment_count);
        setLearners(data.learner_count);
        setCourseInProgress(data.course_in_progress);
      })
      .catch(() => {
        messageNotification(
          'danger',
          STRINGS.DEFAULT_AJAX_ERROR_TITLE,
          STRINGS.DEFAULT_AJAX_ERROR_MESSAGE,
        );
        setProgress(false);
      });
  }, []);
  return (
    <Styled>
      {
        inProgress && <PaginationLoader />
      }
      <MainHeading>
        <span>Quick Stats</span>
        <Link className="view-all" to={ROUTES.ANALYTICS}>View Detailed Analytics
          <i className="icon-Chevron-Right" aria-hidden="true" />
        </Link>
      </MainHeading>
      <StatsHolder>
        <Block>
          <span className="numbers">
            {
              learners > 0 ? (
                <CountUp end={learners} />
              ) : EMPTY_LIST_PLACEHOLDER
            }
          </span>
          <span className="text-info">
            Learners
            <OverlayTrigger
              placement="bottom"
              overlay={(
                <Tooltip>
                  Learners refers to the users on your site that are registered specifically to enroll in a course.
                </Tooltip>
              )}
            >
              <i className="icon-Help-Outline"><span className="sr-only">help</span></i>
            </OverlayTrigger>
          </span>
        </Block>
        <Block>
          <span className="numbers">
            {
              courseAssigned > 0 ? (
                <CountUp end={courseAssigned} />
              ) : EMPTY_LIST_PLACEHOLDER
            }
          </span>
          <span className="text-info">
            Course Assignments
            <OverlayTrigger
              placement="bottom"
              overlay={(
                <Tooltip>
                  The total number of courses assigned to your Learners, which they have yet to begin.
                </Tooltip>
              )}
            >
              <i className="icon-Help-Outline"><span className="sr-only">help</span></i>
            </OverlayTrigger>
          </span>
        </Block>
        <Block>
          <span className="numbers">
            {
              completedCourse > 0 ? (
                <CountUp end={completedCourse} />
              ) : EMPTY_LIST_PLACEHOLDER
            }
          </span>
          <span className="text-info">
            Completed Courses
            <OverlayTrigger
              placement="bottom"
              overlay={(
                <Tooltip>
                  Total number of courses that have been completed by Learners.
                </Tooltip>
              )}
            >
              <i className="icon-Help-Outline"><span className="sr-only">help</span></i>
            </OverlayTrigger>
          </span>
        </Block>
        <Block>
          <span className="numbers">
            {
              courseInProgress > 0 ? (
                <CountUp end={courseInProgress} />
              ) : EMPTY_LIST_PLACEHOLDER
            }
          </span>
          <span className="text-info">
            Courses in progress
            <OverlayTrigger
              placement="bottom"
              overlay={(
                <Tooltip>
                  Total number of courses that are currently in progress.
                </Tooltip>
              )}
            >
              <i className="icon-Help-Outline"><span className="sr-only">help</span></i>
            </OverlayTrigger>
          </span>
        </Block>
      </StatsHolder>
    </Styled>
  );
};
export default Stats;
