import { connect } from 'react-redux';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import BreadCrumbs from 'components/breadcrumbs';
import SelectSearch, { fuzzySearch } from 'react-select-search';
import IconRemove from 'assets/images/icon-remove.svg';
import IconSearch from 'assets/images/icon-search.svg';
import RadioBox from 'common/radiobox';
import { usersActions } from 'users/actions';
import {
  ADMIN,
  ROUTES,
  LEARNER,
  USER_ROLES,
  EMAIL_REGEX,
  TRAINING_MANAGER,
} from 'utils/values';
import {
  validateName,
  validateEmail,
  validateUsername,
  validateLanguage,
  validateEmployeeID,
} from 'users/registration/validations';
import PaginationLoader from 'components/loader/paginationLoader';
import {
  ErrorSpan,
  MainContent,
  ButtonsHolder,
  NewUserHeader,
  AddUserForm,
  FormRow,
  FormCol,
  FormHolder,
  RadioButtonsList,
  InfoText,
  SearchTags,
  SearchTagsList,
} from 'users/styled';

const AddUser = (props) => {
  const userInitialState = {
    name: '',
    email: '',
    username: '',
    employeeID: '',
    language: '',
    role: LEARNER,
  };
  const validationMap = {
    name: validateName,
    email: validateEmail,
    username: validateUsername,
    employeeID: validateEmployeeID,
    language: validateLanguage,
  };

  const { dispatch, allLanguages } = props;
  const OPTIONAL_FIELDS = ['employeeID'];
  const REQUIRED_FIELDS = ['name', 'email', 'username', 'language'];

  const [inProgress, setProgress] = useState(false);
  const [user, setUser] = useState(userInitialState);
  const [errors, setErrors] = useState({});
  const breadCrumbsPath = [
    { label: 'Users', route: ROUTES.USERS },
  ];

  const handleSuccess = (newUserData) => {
    props.history.push(`${ROUTES.USERS}/${newUserData.id}`);
  };

  const handleAPIErrors = (apiErrors) => {
    let profileErrors = {};
    if (apiErrors.profile) {
      profileErrors = { ...apiErrors.profile };
      if (profileErrors.language_code) {
        profileErrors.language = profileErrors.language_code.code;
        delete profileErrors.language_code;
      }
      if (profileErrors.employee_id) {
        profileErrors.employeeID = profileErrors.employee_id;
        delete profileErrors.employee_id;
      }
      // eslint-disable-next-line no-param-reassign
      delete apiErrors.profile;
    }
    setErrors({ ...errors, ...apiErrors, ...profileErrors });
  };

  const handleInputChange = (key, value) => {
    setUser({ ...user, [key]: value });
    if (errors[key]) {
      setErrors({ ...errors, [key]: validationMap[key](value) });
    }
  };

  const isFormValid = () => REQUIRED_FIELDS.every((f) => user[f] && (errors[f] ? !errors[f].length : true))
    && OPTIONAL_FIELDS.every((f) => (errors[f] ? !errors[f].length : true));

  const handleFormSubmit = () => {
    if (!isFormValid()) return;
    setProgress(true);
    const profileData = { name: user.name, employee_id: user.employeeID };
    const params = { username: user.username, role: user.role, email: user.email, profile: profileData };
    if (user.language) profileData.language_code = { code: user.language };

    dispatch(
      usersActions.createUser({ params, setProgress, handleAPIErrors, handleSuccess }),
    );
  };

  const renderErrors = (fieldName) => {
    if (!errors[fieldName]) {
      return null;
    }
    return (
      <ul>
        {errors[fieldName].map((errMsg) => (
          <li>
            <ErrorSpan>{ errMsg }</ErrorSpan>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <MainContent>
      <h1>Users</h1>
      <BreadCrumbs paths={breadCrumbsPath} current="New User" />
      { inProgress && <PaginationLoader /> }

      <NewUserHeader>
        <h2>New User</h2>
        <ButtonsHolder>
          <Link to={ROUTES.USERS}>
            <button className="group">
              Cancel
              <i className="icon right icon-Cancel" />
            </button>
          </Link>
          {/* eslint-disable-next-line no-console */}
          <button type="submit" className={isFormValid() ? '' : 'disabled'} onClick={() => { handleFormSubmit(); }}>
            Save
            <i className="icon right icon-Check" />
          </button>
        </ButtonsHolder>
      </NewUserHeader>

      <AddUserForm>
        <FormHolder>
          { renderErrors('organization') }
          <br />
          <FormRow>

            <FormCol>
              <label htmlFor="full-name">
                <span>Full Name *</span>
                <input
                  className="form-control"
                  type="text"
                  value={user.name}
                  minLength={2}
                  name="name"
                  onBlur={(e) => setErrors({ ...errors, name: validationMap.name(e.target.value) })}
                  onChange={(e) => handleInputChange('name', e.target.value)}
                />
              </label>
              { renderErrors('name') }
            </FormCol>

            <FormCol>
              <label htmlFor="username">
                <span>Username *</span>
                <input
                  required
                  type="text"
                  name="username"
                  value={user.username}
                  className="form-control"
                  onBlur={(e) => setErrors({ ...errors, username: validationMap.username(e.target.value) })}
                  onChange={(e) => handleInputChange('username', e.target.value)}
                />
              </label>
              { renderErrors('username') }
            </FormCol>

            <FormCol>
              <label htmlFor="email">
                <span>Email *</span>
                <input
                  className="form-control"
                  type="email"
                  value={user.email}
                  name="email"
                  onBlur={(e) => setErrors({ ...errors, email: validationMap.email(e.target.value) })}
                  onChange={(e) => handleInputChange('email', e.target.value)}
                  pattern={EMAIL_REGEX}
                />
              </label>
              { renderErrors('email') }
            </FormCol>

            <FormCol className="col-6">
              <label htmlFor="employee-id">
                <span>Employee ID</span>
                <input
                  className="form-control"
                  type="text"
                  value={user.employeeID}
                  name="employeeID"
                  onBlur={(e) => setErrors({ ...errors, employeeID: validationMap.employeeID(e.target.value) })}
                  onChange={(e) => handleInputChange('employeeID', e.target.value)}
                />
              </label>
              { renderErrors('employeeID') }
            </FormCol>

            <FormCol className="col-6">
              <div className="custom-select">
                <span>Language *</span>
                <SelectSearch
                  options={allLanguages}
                  search
                  filterOptions={fuzzySearch}
                  value={user.language}
                  required
                  emptyMessage="Not language found"
                  placeholder="Select your Language"
                  onChange={(lang) => handleInputChange('language', lang)}
                />
              </div>
              { renderErrors('language') }
            </FormCol>

            <FormCol>
              <h3>User Role</h3>
              <RadioButtonsList>
                {
                  [LEARNER, ADMIN, TRAINING_MANAGER].map((role) => (
                    <RadioBox
                      key={role}
                      value={role}
                      radioId={role}
                      radioGroup="role"
                      dataLabel={USER_ROLES[role]}
                      isSelected={user.role === role}
                      handleClick={() => setUser({ ...user, role })}
                    />
                  ))
                }
              </RadioButtonsList>
              { renderErrors('role') }
            </FormCol>

            {/* hidden sections */}
            <FormCol className="col-6" aria-hidden hidden>
              <h3>Team</h3>
              <InfoText>A single user can be added to multiple teams</InfoText>
              <SearchTags>
                <img className="icon-search" src={IconSearch} alt="Search" />
                <input className="form-control" type="text" placeholder="Search Teams" />
              </SearchTags>
              <SearchTagsList>
                <button type="submit" className="tag-button">
                  Team 1
                  <img src={IconRemove} alt="Remove" />
                </button>
                <button type="submit" className="tag-button">
                  Team 2
                  <img src={IconRemove} alt="Remove" />
                </button>
              </SearchTagsList>
            </FormCol>
            <FormCol className="col-6" aria-hidden hidden>
              <h3>Group</h3>
              <InfoText>A single user can be added to multiple groups</InfoText>
              <SearchTags>
                <img className="icon-search" src={IconSearch} alt="Search" />
                <input className="form-control" type="text" placeholder="Search Groups" />
              </SearchTags>
            </FormCol>
          </FormRow>
        </FormHolder>
      </AddUserForm>
    </MainContent>
  );
};

function mapStateToProps(state) {
  const { languages } = state.LOGGEDINUSER;
  const allLanguages = state.LOGGEDINUSER.allLanguages.map((lang) => ({
    value: lang.code,
    name: lang.value,
  }));
  return {
    languages,
    allLanguages,
  };
}

export default connect(mapStateToProps)(AddUser);
