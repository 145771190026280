import styled from 'styled-components';
import { theme } from 'assets/styles/theme';

const CheckBoxStyled = styled.label`
  min-height: 20px;
  min-width: 20px;
  display: inline-block;
  vertical-align: middle;

  .text {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    font-size: 18px;
    line-height: 1;
    padding-left: 10px;
  }

  .custom-checkbox {
    left: -999em;
    position: absolute;

    ~ .checkbox {
      position: relative;
      display: inline-block;
      vertical-align: top;
      padding-left: 20px;
      cursor: pointer;

        &:before {
          background: ${theme.bodyBg};
          border: 2px solid ${theme.gray};
          border-radius: 2px;
          position: absolute;
          left: 0;
          top: 0;
          width: 20px;
          height: 20px;
          content: '';
        }

        svg {
          position: absolute;
          left: 3px;
          top: 5px;
        }
      }

    &:checked {

      + .checkbox {

          &:before {
            background: ${theme.primary};
            border-color: ${theme.primary};
          }
        }
      }
    }
  }
`;

export default CheckBoxStyled;
