import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import BreadCrumbs from 'components/breadcrumbs';
import CourseList from 'users/enrollment/courselist';
import { ROUTES } from 'utils/values';
import { usersActions } from 'users/actions';
import PaginationLoader from 'components/loader/paginationLoader';
import PaginationBlock from 'common/pagination';
import { getUserRepresentation, messageNotification } from 'utils/functions';
import STRINGS from 'utils/strings';
import {
  MainContent,
  ButtonsHolder,
  NewUserHeader,
  EnrolCoursesWrapper,
  SearchField,
  SearchWrapper,
} from '../styled';

const IndividualEnrolling = (props) => {
  const { userId } = useParams();
  const { dispatch, courses } = props;
  const [user, setUser] = useState(props.user);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [params, setParams] = useState({ activePage: 1, searchText: '' });
  const isEnrollDisabled = selectedCourses.length === 0;

  const breadCrumbsPath = [
    { label: 'Users', route: ROUTES.USERS },
    { label: (!user) ? 'User' : getUserRepresentation(user), route: `${ROUTES.USERS}/${userId}` },
  ];

  const handleUserSuccess = (responseData) => {
    setUser(responseData);
  };
  const handleUserError = (response) => {
    if (response && response.status === 404) {
      props.history.push(ROUTES.USERS);
    }
  };
  const handleCheckboxChange = (event) => {
    const { checked, value } = event.target;
    const updatedCourses = [...selectedCourses];
    if (checked) {
      updatedCourses.push(value);
    } else if (updatedCourses.indexOf(value) > -1) {
      updatedCourses.splice(updatedCourses.indexOf(value), 1);
    }
    setSelectedCourses(updatedCourses);
  };
  const handleParamChange = (key, value) => {
    if (isLoading && key === 'activePage') return;
    if (key === 'searchText') {
      setSelectedCourses([]);
      setParams({ activePage: 1, searchText: value });
    } else if (key === 'activePage') {
      setParams({ ...params, activePage: value });
    }
  };

  const handleEnrollSuccess = (responseMsg) => {
    setIsLoading(false);
    setParams({ activePage: 1, searchText: '' });
    const queryParam = { user_id: userId, page: 1, name: '' };
    dispatch(usersActions.fetchCourses(queryParam, setIsLoading));
    messageNotification('success', STRINGS.DEFAULT_AJAX_SUCCESS_TITLE, responseMsg);
    props.history.push(`${ROUTES.USERS}/${userId}`);
  };
  const handleEnrollError = (responseMsg) => {
    setIsLoading(false);
    setSelectedCourses([]);
    messageNotification('danger', STRINGS.DEFAULT_AJAX_ERROR_TITLE, responseMsg);
  };

  const handleEnrollSubmission = () => {
    setIsLoading(true);
    const requestData = { user_ids: [userId], course_keys: selectedCourses };
    setSelectedCourses([]);
    usersActions.enrollUsers(requestData, handleEnrollSuccess, handleEnrollError);
  };

  const renderCourses = () => {
    if (!courses.results || courses.results.length === 0) return <div className="no-data">No results to show</div>;

    return (
      <>
        <CourseList courses={courses.results} selectedIds={selectedCourses} selectCallback={handleCheckboxChange} />
        <PaginationBlock
          showPageCounter
          itemsCountPerPage={5}
          pageRangeDisplayed={5}
          activePage={params.activePage}
          totalItemsCount={courses.count}
          onPageChange={(_, pageNo) => handleParamChange('activePage', pageNo)}
        />
      </>
    );
  };

  useEffect(() => {
    if (!user) {
      usersActions.retrieveUserDetail({ userId, handleUserSuccess, handleUserError });
    } else {
      setIsLoading(true);
      const queryParam = { user_id: userId, page: params.activePage, name: params.searchText };
      dispatch(usersActions.fetchCourses(queryParam, setIsLoading));
    }
    // eslint-disable-next-line
  }, [userId, user, params]);

  if (!user) return <PaginationLoader />;

  return (
    <MainContent>
      <h1>Users</h1>
      <BreadCrumbs paths={breadCrumbsPath} current="Enroll" />
      <NewUserHeader>
        <h2>Enroll { getUserRepresentation(user) }</h2>
        <ButtonsHolder>
          <Link to={`${ROUTES.USERS}/${userId}`}>
            <button type="submit" className="group">
              Cancel
              <i className="icon right icon-Cancel" />
            </button>
          </Link>
          <button
            type="submit"
            disabled={isEnrollDisabled}
            onClick={() => handleEnrollSubmission()}
            className={isEnrollDisabled ? 'disabled' : ''}
          >
            Enroll
            <i className="icon right icon-Check" />
          </button>
        </ButtonsHolder>
      </NewUserHeader>
      <EnrolCoursesWrapper>
        <SearchWrapper>
          <h3>Courses</h3>
          <SearchField>
            <span className="search-icon icon-Search-2-Outline-Color" />
            <input
              type="text"
              value={params.searchText}
              className="form-control"
              onChange={(e) => handleParamChange('searchText', e.target.value)}
              placeholder="Search Courses"
            />
          </SearchField>
        </SearchWrapper>
        {
          !isLoading ? renderCourses() : <PaginationLoader />
        }
      </EnrolCoursesWrapper>
    </MainContent>
  );
};

function mapStateToProps(state, ownProps) {
  const { results } = state.USERSDATA;
  const { data } = state.COURSES;
  return {
    courses: data,
    user: results && results[`user${ownProps.match.params.userId}`],
  };
}

export default connect(mapStateToProps)(IndividualEnrolling);
