const breakpoints = {
  xxxs: '375',
  xxs: '475',
  xs: '576',
  sm: '768',
  md: '992',
  lg: '1025',
  xl: '1280',
  xxl: '1300',
};
export const DeviceUp = {
  xxxs: `(min-width: ${breakpoints.xxxs}px)`,
  xxs: `(min-width: ${breakpoints.xxs}px)`,
  xs: `(min-width: ${breakpoints.xs}px)`,
  sm: `(min-width: ${breakpoints.sm}px)`,
  md: `(min-width: ${breakpoints.md}px)`,
  lg: `(min-width: ${breakpoints.lg}px)`,
  xl: `(min-width: ${breakpoints.xl}px)`,
  xxl: `(min-width: ${breakpoints.xxl}px)`,
};
export const DeviceDown = {
  xxxs: `(max-width: ${breakpoints.xxxs - 1}px)`,
  xxs: `(max-width: ${breakpoints.xxs - 1}px)`,
  xs: `(max-width: ${breakpoints.xs - 1}px)`,
  sm: `(max-width: ${breakpoints.sm - 1}px)`,
  md: `(max-width: ${breakpoints.md - 1}px)`,
  lg: `(max-width: ${breakpoints.lg - 1}px)`,
  xl: `(max-width: ${breakpoints.xl - 1}px)`,
  xxl: `(max-width: ${breakpoints.xxl - 1}px)`,
};
