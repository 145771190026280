import React from 'react';
import VideoGuide from 'assets/images/vidoe-guide.png';
import Getstarted from 'assets/images/getstarted-ilmx.png';
import {
  Styled,
  MainHeading,
  StatsHolder,
  Block,
} from './styled';

const Started = () => (
  <Styled hidden>
    <>
      <MainHeading>Getting Started</MainHeading>
      <p>We’ll show you what’s important to get going! You can watch our video
        tutorial or download the guide as a PDF.
      </p>
      <StatsHolder>
        <Block>
          <div className="image-holder"><img src={VideoGuide} alt="Video" /></div>
          <div className="text-holder">
            <span className="title">Watch a video tutorial for our getting started guide</span>
            <a href="/">
              Play Now
              <i className="icon-Play-32 play" aria-hidden="true" />
            </a>
          </div>
        </Block>
        <Block>
          <div className="image-holder"><img src={Getstarted} alt="Get Started" /></div>
          <div className="text-holder">
            <span className="title">Download a PDF for our getting started guide</span>
            <a href="/">
              Download Now
              <i className="icon-Download-Outline-Color" aria-hidden="true" />
            </a>
          </div>
        </Block>
      </StatsHolder>
    </>
  </Styled>
);

export default Started;
