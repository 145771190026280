import React, { useState } from 'react';

const TableResultSelection = ({ pageSize, handleOnChange }) => {
  const [page, setPage] = useState(pageSize);

  const handleChange = (e) => {
    const pageNumber = Number(e.target.value);
    handleOnChange(pageNumber);
    setPage(pageNumber);
  };

  return (
    <div className="results-info">
      <span className="info">Results per page</span>
      <select
        value={page}
        onChange={handleChange}
      >
        <option value="10">10</option>
        <option value="20">20</option>
        <option value="50">50</option>
        <option value="100">100</option>
      </select>
    </div>
  );
};

export default TableResultSelection;
