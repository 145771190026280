import styled from 'styled-components';
import { theme } from 'assets/styles/theme';

const ToggleBoxStyled = styled.label`
  position: relative;
  display: block;
  width: 48px;
  height: 24px;

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${theme.bodyBg};
    transition: .4s;
    border-radius: 34px;
    border: 2px solid ${theme.gray};
    font-size: 0;

    &:before {
      position: absolute;
      content: "";
      height: 12px;
      width: 12px;
      left: 4px;
      bottom: 4px;
      top: auto;
      background-color: ${theme.gray};
      transition: .4s;
      border-radius: 50%;
    }

    svg {
      position: absolute;
      left: 3px;
      top: 5px;
      pointer-events: none;
      transition: .4s;
      opacity: 0;
    }
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked {

      ~ .slider {
        background-color: ${(props) => (props.disabled ? theme.gray : theme.primary)};
        border-color: ${(props) => (props.disabled ? theme.gray : theme.primary)};

        &:before {
          transform: translateX(26px);
          background-color: ${theme.bodyBg};
          border-color: ${theme.bodyBg};
        }

        svg {
          opacity: 1;
        }
      }
    }
  }
}
}
`;

export default ToggleBoxStyled;
