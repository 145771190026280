import React from 'react';
import BreadCrumbs from 'components/breadcrumbs';
import {
  MainContainer,
  ButtonsHolder,
  HeaderHolder,
  GroupNameLabel,
  GroupNameWrapper,
} from './styled';

const EditGroup = () => (
  <>
    <MainContainer>
      <h1>Groups</h1>
      <BreadCrumbs />
      <HeaderHolder>
        <h3>Edit Compliance Group</h3>
        <ButtonsHolder className="v2">
          <button type="submit" className="group">
            Cancel
            <span className="icon icon-Cancel right" />
          </button>
          <button type="submit">
            Next
            <span className="icon icon-Check right" />
          </button>
        </ButtonsHolder>
      </HeaderHolder>
      <GroupNameWrapper>
        <GroupNameLabel htmlFor="group">Group Name *</GroupNameLabel>
        <input type="text" id="group" placeholder="Compliance Group" />
      </GroupNameWrapper>
    </MainContainer>
  </>
);

export default EditGroup;
